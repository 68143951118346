/* eslint-disable camelcase */
export const FINANCIALS_RETRIEVAL_STATUS = {
  GENERATED: 'generated',
  STARTED: 'started',
  COMPLETED: 'completed',
}

export const APPLICATION_STATUS = {
  SettlementCompleted: 'settlement_completed',
  SettlementBooked: 'settlement_date_booked',
  ConditionallyApproved: 'conditionally_approved',
  UnconditionallyApproved: 'unconditionally_approved',
  AssessorApproved: 'assessor_approved',
  WithPortfolioManager: 'with_portfolio_manager',
  PortfolioManagerApproved: 'portfolio_manager_approved',
  ApplicationReceived: 'application_received',
  WithCreditAssessor: 'with_credit_assessor',
  Cancelled: 'cancelled',
  Declined: 'declined',
  Deferred: 'deferred',
  InProgress: 'in_progress',
}

export const APPLICATION_STATUS_DISPLAY_TEXT = {
  application_received: 'Application Received',
  with_credit_assessor: 'With Credit Assessor',
  assessor_approved: 'Assessor Approved',
  with_portfolio_manager: 'With Portfolio Manager',
  portfolio_manager_approved: 'Portfolio Manager Approved',
  conditionally_approved: 'Conditionally Approved',
  unconditionally_approved: 'Unconditionally Approved',
  settlement_date_booked: 'Settlement Date Booked',
  settlement_completed: 'Settlement Completed',
  cancelled: 'Cancelled',
  declined: 'Declined',
  deferred: 'Deferred',
  in_progress: 'In Progress',
}
