import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled, s } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { ContentCard } from 'features/loanAssessment/info/components'
import { NoteSection } from 'features/common/NoteSection'
import { LivingExpense } from './LivingExpense'

const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const CollapseButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)
const ViewDetailsButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)
const ExpenseSubtitle = styled.p(
  s('font-semi-bold mb-3 mt-5 text-black text-lg', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const HouseHoldSubtitle = styled.h3(
  s('m-0 text-grey-900 text-xl', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)

export const ViewExpenseDetails = ({ households = [] }) => {
  const { id } = useParams()
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      {isExpanded ? (
        <>
          {households?.map(({ livingExpenses, otherExpenses }, index) => {
            const isFirstHousehold = index === 0

            return (
              <>
                <HouseHoldSubtitle style={s(isFirstHousehold ? 'mt-6' : 'mt-8')}>
                  Household {index + 1}
                </HouseHoldSubtitle>

                <ExpenseSubtitle>Living Expenses</ExpenseSubtitle>

                {isNotNilOrEmpty(livingExpenses) ? (
                  <>
                    <Row gutter={[12, 16]}>
                      {livingExpenses.map((expense, index) => (
                        <Col key={index} span={8}>
                          <LivingExpense
                            description={expense?.description}
                            amount={expense?.amount}
                            frequency={expense?.frequency}
                            category={expense?.category}
                            repsonsible={expense?.percentResponsible}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                ) : (
                  <ContentCard style={s('mt-3')}>No Living Expenses</ContentCard>
                )}

                <ExpenseSubtitle>Other Commitments</ExpenseSubtitle>

                {isNotNilOrEmpty(otherExpenses) ? (
                  <>
                    <Row>
                      {otherExpenses.map((expense, index) => (
                        <Col key={index} span={8}>
                          <LivingExpense
                            description={expense?.description}
                            amount={expense?.amount}
                            frequency={expense?.frequency}
                            category={expense?.category}
                            repsonsible={expense?.percentResponsible}
                          />
                        </Col>
                      ))}
                    </Row>
                  </>
                ) : (
                  <ContentCard style={s('mt-3')}>No Other Commitments</ContentCard>
                )}
              </>
            )
          })}

          {isNilOrEmpty(households) && (
            <ContentCard style={s('mt-8')}>No data yet</ContentCard>
          )}

          <Row style={s('mt-8')}>
            <Col span={24}>
              <NoteSection
                id="note-section"
                loanApplicationId={id}
                source="loan-applications/financial/expenses"
              />
            </Col>
          </Row>

          <CollapseButton onClick={() => setIsExpanded(false)} style={s('mt-8')}>
            <Row style={s('justify-center')}>
              <div>Collapse</div>
              <ChevronUpIcon width={20} height={20} />
            </Row>
          </CollapseButton>
        </>
      ) : (
        <ViewDetailsButton onClick={() => setIsExpanded(true)}>
          <Row style={s('justify-center')}>
            <div>View Details</div>
            <ChevronDownIcon width={20} height={20} />
          </Row>
        </ViewDetailsButton>
      )}
    </>
  )
}
