import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { productService } from 'apiService'
import { normalizeError } from '@horizon/services'

export const fetchProducts = createAsyncThunk(
  'product/fetchProducts',
  async (payload, { rejectWithValue }) => {
    try {
      return await productService.fetchProducts({
        q: '',
        filters: '',
        start: 0,
        // FIXME: Might need a autocomplete endpoint when the product data set gets larger
        limit: 1000,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async (id, { rejectWithValue }) => {
    try {
      return await productService.fetchProduct(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const productsAdapter = createEntityAdapter()

const initialState = productsAdapter.getInitialState({
  entities: [],
  foobar: 'foobar',
})

const productsSlice = createSlice({
  name: 'product',
  initialState,
  extraReducers: {
    [fetchProducts.fulfilled]: (state, action) => {
      const { items: products } = action.payload
      productsAdapter.setAll(state, products)
    },
  },
})

const { reducer: productsReducer } = productsSlice
export { productsReducer }
