import React from 'react'
import { Col, Row } from '@horizon/styled'
import { s, styled } from '@horizon/styled/v2'
import { Applicant } from './Applicant'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { SectionTitle as SectionTitleBase } from '../components'

const { APPLICANTS } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const Applicants = ({ applicants = [], ...props }) => {
  return (
    <Container id={APPLICANTS} {...props}>
      <SectionTitle title="Applicant(s)" />

      <Row gutter={[16, 16]}>
        {applicants?.map((applicant, index) => (
          <Col key={index} span={12}>
            <Applicant applicant={applicant} index={index} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export { Applicants }
