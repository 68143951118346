import React, { useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import { Item } from '@react-stately/collections'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { LOAN_APPLICATION, USER_ROLES } from '@horizon/constants'
import { Menu, toast, Loading } from '@horizon/components'
import {
  selectApplicationById,
  updateLoanApplication,
  updateLoanApplicationStatus,
  selectIsFetchApplicationPending,
} from 'modules/application'
import { fetchLoanSplits, selectAllLoanSplits } from 'modules/loanSplit'
import { fetchUsers, selectAllUsers } from 'modules/user'

import { formatDateString } from 'utils/date'
import { formatCurrency, formatFullName } from 'utils/formatters'
import {
  calculateAverageLVR,
  getLoanStructureInformation,
  getPrimaryApplicantName,
  mapUsersToDropdownFormat,
} from './headerFormatters'
import { DataDisplay as DataDisplayBase } from './info/components'

const { APPLICATION_STATUS, APPLICATION_STATUS_DISPLAY_TEXT } = LOAN_APPLICATION
const { BANKING_PORTFOLIO_ADMIN, CREDIT_ASSESSOR, LEAD_ASSESSOR, SUPER_ADMIN } =
  USER_ROLES

const AssignmentText = styled.p(
  s('font-medium mx-4 my-0 text-base', { letterSpacing: '0.01em', lineHeight: 1.5 })
)
const AssignmentRow = styled.div(s('flex items-center justify-between mb-9'))
const DataDisplay = styled((props) => (
  <DataDisplayBase
    labelStyles={s('font-medium')}
    valueStyles={s('text-grey-900 text-lg')}
    {...props}
  />
))()
const OwnerText = styled.h3(s('my-0 text-2xl text-primary-hard font-medium'), {
  letterSpacing: '-0.01em',
  lineHeight: 1.2,
})
const Root = styled.div(
  s('pb-6 bg-secondary-flat-white', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    paddingLeft: 148,
    paddingRight: 148,
    paddingTop: 120,
  })
)

// eslint-disable-next-line complexity
const applicationStatusErrorHandler = (
  errorDescription,
  statusCode,
  errorMessagePrefix
) => {
  switch (errorDescription) {
    case 'Invalid settlement date':
      toast(`Invalid pre-settlement date`)
      break

    case `Status is already ${APPLICATION_STATUS.SettlementCompleted}`:
      toast(
        `Current status is already ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementCompleted]
        }`
      )
      break

    case `Status is already ${APPLICATION_STATUS.SettlementBooked}`:
      toast(
        `Current status is already ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementBooked]
        }`
      )
      break

    case `Previous status is not ${APPLICATION_STATUS.SettlementBooked}`:
      toast(
        `Please update status to ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementBooked]
        } before updating to ${
          APPLICATION_STATUS_DISPLAY_TEXT[APPLICATION_STATUS.SettlementCompleted]
        }`
      )
      break

    default:
      if (statusCode === 400) toast(`${errorDescription}`)
      else toast(`${errorMessagePrefix}Unexpected error`)
      break
  }
}

const filteredRoles = [
  BANKING_PORTFOLIO_ADMIN,
  CREDIT_ASSESSOR,
  LEAD_ASSESSOR,
  SUPER_ADMIN,
]

const formatSettlementDate = (date) => (isNotNilOrEmpty(date) ? new Date(date) : '-')

export const LoanAssignmentHeader = ({ ...props }) => {
  const dispatch = useDispatch()
  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const allocatedLoanStructures = useSelector(selectAllLoanSplits)

  const { id: loanApplicationId } = useParams()
  const application = useSelector(selectApplicationById(loanApplicationId)) ?? {}
  const loanSplits = useSelector(selectAllLoanSplits) ?? []
  const users = useSelector(selectAllUsers) ?? []
  const [applicationStatus, setApplicationStatus] = useState()
  const [assignedUser, setAssignedUser] = useState('')

  const assignedToId = application?.assignedTo?.id
  const customSecurityValuations = application?.customSecurityValuations
  const individualEntities = application?.legalEntities?.individuals
  const securities = application?.securities
  const settlementDate = formatSettlementDate(application?.expectedSettlementDate)
  const { loanStructure, totalLoanAmount, totalLoanDuration } =
    getLoanStructureInformation(loanSplits, application?.requestedLoans)
  const lvr = calculateAverageLVR(customSecurityValuations, totalLoanAmount, securities)
  const getCrr = () => {
    if (
      isNotNilOrEmpty(application?.creditRiskRating?.letter) &&
      isNotNilOrEmpty(application?.creditRiskRating?.number)
    ) {
      return `${application?.creditRiskRating?.letter}${application?.creditRiskRating?.number}`
    }
    return '-'
  }

  const USERS = mapUsersToDropdownFormat(users)

  const displayUserFullName = (newValue) => {
    if (isNilOrEmpty(newValue)) return null

    const { firstName, lastName } = users.find(({ id }) => id === newValue) || {}

    return formatFullName({ firstName, lastName })
  }

  const displayApplicationStatus = (newValue) => {
    if (isNilOrEmpty(newValue)) return null

    return APPLICATION_STATUS_DISPLAY_TEXT[newValue]
  }

  const getLoanStructure = (loanStructure, allocatedLoans) => {
    if (isNilOrEmpty(allocatedLoans)) return loanStructure

    if (allocatedLoans.length > 1) {
      return 'Split'
    }
    return 'Single'
  }

  const updateAssignedTo = async (newUser) => {
    const { id, firstName, lastName } = users.find(({ id }) => id === newUser)
    const payload = { assignedTo: { id, firstName, lastName } }

    const { error } = await dispatch(
      updateLoanApplication({ id: loanApplicationId, payload })
    )
    if (error) {
      toast('Application assignment failed')
      return
    }

    toast('Application assignment successful')
    setAssignedUser(newUser)
  }

  useEffect(() => {
    dispatch(fetchUsers({ searchParams: { filters: { role: filteredRoles } } }))
    dispatch(fetchLoanSplits(loanApplicationId))
  }, [dispatch, loanApplicationId])

  useEffect(() => {
    setAssignedUser(assignedToId)
  }, [assignedToId])

  useEffect(() => {
    setApplicationStatus(application?.status?.currentStatus)
  }, [application.status])

  if (isFetchingApplication) {
    return (
      <Root {...props}>
        <div style={s('py-16')}>
          <></>
        </div>
      </Root>
    )
  }

  return (
    <Root {...props}>
      <AssignmentRow>
        <OwnerText>
          {getPrimaryApplicantName(individualEntities, loanSplits)}&apos;s Application
        </OwnerText>
        <AssignmentText>currently</AssignmentText>
        <Menu
          aria-label="Application Status"
          label="Application Status"
          onAction={async (status) => {
            try {
              const resultAction = await dispatch(
                updateLoanApplicationStatus({ loanApplicationId, status })
              )
              unwrapResult(resultAction)

              toast('Application status updated successfully')
              setApplicationStatus(status)
            } catch (error) {
              const errorMessagePrefix = 'Update application status failed: '
              applicationStatusErrorHandler(
                error?.description,
                error?.meta?.statusCode,
                errorMessagePrefix
              )
            }
          }}
          displayTextSelector={displayApplicationStatus}
          placeholder="Application Status"
          selectedValue={applicationStatus}
          style={s('w-16')}
        >
          {Object.entries(APPLICATION_STATUS_DISPLAY_TEXT).map(([key, value]) => (
            <Item key={key}>{value}</Item>
          ))}
        </Menu>
        <AssignmentText>assigned to</AssignmentText>
        <Menu
          aria-label="Assigned to"
          displayTextSelector={displayUserFullName}
          label="Whom?"
          onAction={updateAssignedTo}
          placeholder="Whom?"
          selectedValue={assignedUser}
          style={s('w-16')}
        >
          {Object.entries(USERS).map(([key, value]) => (
            <Item key={key}>{value}</Item>
          ))}
        </Menu>
      </AssignmentRow>

      <Row justify="space-between">
        <Col span={4}>
          <DataDisplay
            label="Loan Structure"
            value={getLoanStructure(loanStructure, allocatedLoanStructures)}
          />
        </Col>

        <Col span={4}>
          <DataDisplay label="Loan Amount" value={formatCurrency(totalLoanAmount)} />
        </Col>

        <Col span={4}>
          <DataDisplay label="Loan Duration" value={`${totalLoanDuration} years`} />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Expected Settlement Date"
            value={formatDateString(settlementDate)}
          />
        </Col>

        <Col span={4}>
          <DataDisplay label="%LVR" value={lvr} />
        </Col>

        <Col span={4}>
          <DataDisplay label="CRR" value={getCrr()} />
        </Col>
      </Row>
    </Root>
  )
}
