/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { round } from 'mathjs'
import { any, isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import {
  REPAYMENT_TYPE_DISPLAY_TEXT,
  LOAN,
  PRODUCT_CONSTANTS,
} from '@horizon/constants'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { formatCurrency, capitaliseFirstLetter } from 'utils/formatters'
import * as calculationModule from 'modules/calculation'

const { LOAN_PURPOSE_DISPLAY_TEXT } = LOAN
const { OCCUPANCY_TYPE_DISPLAY_TEXT } = PRODUCT_CONSTANTS

const Label = styled.p(
  s('font-normal m-0 text-base text-grey-700', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Value = styled.p(
  s('font-normal m-0 text-base text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const buildLoanSplitType = (
  initialRepaymentType,
  initialRateType,
  ongoingRepaymentType,
  ongoingRateType
) => {
  const hasNilOrEmptyArguments = any(isNilOrEmpty, [
    initialRepaymentType,
    initialRateType,
    ongoingRepaymentType,
    ongoingRateType,
  ])

  if (hasNilOrEmptyArguments) return '-'

  const rateTypeWillChange = initialRateType !== ongoingRateType
  const initialRepaymentTypeDisplayText =
    REPAYMENT_TYPE_DISPLAY_TEXT[initialRepaymentType]
  const ongoingRepaymentTypeDisplayText =
    REPAYMENT_TYPE_DISPLAY_TEXT[ongoingRepaymentType]

  if (rateTypeWillChange)
    return `${capitaliseFirstLetter(
      initialRateType
    )} ${initialRepaymentTypeDisplayText} reverting to ${capitaliseFirstLetter(
      ongoingRateType
    )} ${ongoingRepaymentTypeDisplayText}`

  return `${capitaliseFirstLetter(initialRateType)} ${initialRepaymentTypeDisplayText}`
}

export const LoanAccountItem = ({ loanSplit = {}, ...otherProps }) => {
  const dispatch = useDispatch()
  const {
    product,
    loanAmount,
    loanTermInYears,
    repaymentFrequency,
    interestRate,
    loading,
    loanPurpose,
    occupancyType,
  } = loanSplit
  const {
    initialRepaymentType,
    initialRateType,
    ongoingRepaymentType,
    ongoingRateType,
  } = product || {}

  const [repaymentAmount, setRepaymentAmount] = useState(0)
  const [totalRepayment, setTotalRepayment] = useState(0)
  const [totalInterestRepayment, setTotalInterestRepayment] = useState(0)

  useEffect(() => {
    const calculateRepaymentAmount = async () => {
      const hasNilOrEmptyArguments = any(isNilOrEmpty, [
        product?.id,
        loanAmount,
        loanTermInYears,
        repaymentFrequency,
        loading,
      ])

      if (hasNilOrEmptyArguments) return

      const result = await dispatch(
        calculationModule.calculateRepayment({
          productId: product?.id,
          loanAmount,
          loanTermInYears,
          repaymentRecurrence: repaymentFrequency,
          loading,
        })
      )

      const { totalRepayment, periodicRepayment, totalInterestRepayment } =
        result?.payload || {}

      setRepaymentAmount(
        totalInterestRepayment ? round(periodicRepayment || 0, 2) : periodicRepayment
      )
      setTotalRepayment(
        totalInterestRepayment ? round(totalRepayment || 0, 2) : totalRepayment
      )
      setTotalInterestRepayment(
        totalInterestRepayment
          ? round(totalInterestRepayment || 0, 2)
          : totalInterestRepayment
      )
    }

    calculateRepaymentAmount()
  }, [dispatch, loading, loanAmount, loanTermInYears, product.id, repaymentFrequency])

  return (
    <div {...otherProps}>
      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label style={s('font-bold')}>Loan Account</Label>
        </Col>
        <Col span={14}>
          {/* TODO: Ensure the loan number is generated after went unconditional */}
          <Value style={s('font-bold')}>
            {loanSplit?.loanNumber || 'TBC when offer goes unconditional'}
          </Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Loan Purpose</Label>
        </Col>
        <Col span={14}>
          <Value>{LOAN_PURPOSE_DISPLAY_TEXT[loanPurpose] || '-'}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Occupancy Type</Label>
        </Col>
        <Col span={14}>
          <Value>{OCCUPANCY_TYPE_DISPLAY_TEXT[occupancyType] || '-'}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Loan Product</Label>
        </Col>
        <Col span={14}>
          <Value>{loanSplit?.product?.name || '-'}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Type</Label>
        </Col>
        <Col span={14}>
          <Value>
            {buildLoanSplitType(
              initialRepaymentType,
              initialRateType,
              ongoingRepaymentType,
              ongoingRateType
            )}
          </Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Loan Account Amount</Label>
        </Col>
        <Col span={14}>
          <Value>{formatCurrency(loanAmount)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Loan Account term (years)</Label>
        </Col>
        <Col span={14}>
          <Value>{loanTermInYears || '-'}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Payment Frequency</Label>
        </Col>
        <Col span={14}>
          <Value>
            {isNotNilOrEmpty(repaymentFrequency)
              ? capitaliseFirstLetter(repaymentFrequency)
              : '-'}
          </Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Account Interest Rate</Label>
        </Col>
        <Col span={14}>
          <Value>{`${interestRate ? round(interestRate, 2) : '-'}%`}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Regular Payment Amounts</Label>
        </Col>
        <Col span={14}>
          <Value>{formatCurrency(repaymentAmount, 2)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Total Amount Interest</Label>
        </Col>
        <Col span={14}>
          <Value>{formatCurrency(totalInterestRepayment, 2)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-4')}>
        <Col span={10}>
          <Label>Total Amount Payments</Label>
        </Col>
        <Col span={14}>
          <Value>{formatCurrency(totalRepayment, 2)}</Value>
        </Col>
      </Row>
    </div>
  )
}
