import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { SectionTitle } from '@horizon/components/src/v2'
import { Button, DateField, toast, Loading } from '@horizon/components'
import * as Validator from 'yup'
import { useParams } from 'react-router-dom'
import { NoteSection } from 'features/common/NoteSection'
import { Formik as FormProvider, Form } from 'formik'
import { ContentCard } from 'features/loanAssessment/info/components'
import { useDispatch, useSelector } from 'react-redux'
import { isNilOrEmpty } from '@solta/ramda-extra'
import {
  updateLoanApplication,
  selectIsFetchApplicationPending,
  fetchLoanApplication,
} from 'modules/application'
import { formatDateString, parseDate } from 'utils/date'

const CancelButton = styled(Button)(
  s('border-transparent font-semibold text-grey-600'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)

const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2 w-full', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const { string, object } = Validator
const validationSchema = object({
  postSettlementDate: string()
    .required('This is a required field')
    .test(
      'is-after-today',
      'The date of post settlement date can not be in the past',
      (value) => {
        const postSettlementDate = parseDate(value)
        const today = new Date()
        const differenceInTime = postSettlementDate - today
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24))

        return differenceInDays >= 0
      }
    ),
})

export const PostSettlementSection = ({ application }) => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const currentPostSettlementDate = application.postSettlement?.postSettlementDate
  const updateValues = async (postSettlementDate) => {
    const postDate = parseDate(postSettlementDate)
    const { error } = await dispatch(
      updateLoanApplication({
        id,
        payload: {
          postSettlement: {
            postSettlementDate: postDate,
          },
        },
      })
    )
    if (isNilOrEmpty(error)) {
      toast('Post Settlement Updated Successfully')
    }
    dispatch(fetchLoanApplication(id))
  }

  if (isFetchingApplication) {
    return (
      <ContentCard style={s('w-full mt-8')}>
        <Loading />
      </ContentCard>
    )
  }

  return (
    <div style={s('w-full')}>
      <FormProvider
        initialValues={{
          postSettlementDate: isNilOrEmpty(currentPostSettlementDate)
            ? currentPostSettlementDate
            : formatDateString(currentPostSettlementDate),
        }}
        onSubmit={(values) => updateValues(values.postSettlementDate)}
        validationSchema={validationSchema}
      >
        <Form>
          <div>
            <Row gutter={[0, 10]}>
              <SectionTitle title="Post Settlement" style={s('mt-9 mb-3 ml-2')} />
              <Col span={24}>
                <ContentCard style={s('mb-4')}>
                  <Row>
                    <Col span={12}>
                      <Label>Estimated Post Settlement Date</Label>
                      <DateField
                        disablePast={true}
                        name="postSettlementDate"
                        placeholder="DD/MM/YYYY"
                        containerStyle={(s('border-grey-200'), { marginTop: -3 })}
                      />
                    </Col>
                  </Row>
                  <div style={s('flex items-center justify-end mt-8')}>
                    <CancelButton type="reset" variant="text">
                      Cancel
                    </CancelButton>
                    <Button type="submit" style={s('ml-4')}>
                      Save
                    </Button>
                  </div>
                </ContentCard>
              </Col>
              <Col span={24}>
                <NoteSection
                  id="note-section"
                  loanApplicationId={id}
                  source="loan-applications/settlement/post-settlement"
                />
              </Col>
            </Row>
          </div>
        </Form>
      </FormProvider>
    </div>
  )
}
