import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import { buildEntityName } from 'utils/entity'

export const formatOwnershipString = (entities = [], ownerships = []) => {
  let ownershipString = ''
  ownerships.forEach((ownership) => {
    const isOwnerOfAsset = ownership.percent > 0
    if (isOwnerOfAsset) {
      const matchedEntity = entities.find((e) => e.id === ownership.legalEntityId)
      if (isNotNilOrEmpty(matchedEntity)) {
        const { personName, businessName, trustName, companyName } = matchedEntity

        ownershipString += `${buildEntityName(
          {
            firstName: personName?.firstName,
            lastName: personName?.surname,
          },
          trustName,
          businessName,
          companyName
        )}, `
      }
    }
  })
  if (isNilOrEmpty(ownershipString)) {
    ownershipString = '-'
  }

  return ownershipString.slice(0, -2)
}
