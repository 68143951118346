import React, { useEffect } from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { styled, s } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { ReactComponent as ChevronLeft } from '@horizon/components/src/assets/images/chevron-left.svg'
import { fetchProducts } from 'modules/product'
import { LoanSummaryChart } from './LoanSummaryChart'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/application'
import { fetchLoanSplits, selectAllLoanSplits } from 'modules/loanSplit'
import { Loading, Button } from '@horizon/components'
import { LoanBuilder } from './loanBuilder'
import {
  SectionTitle,
  SectionTitle as SectionTitleBase,
} from '@horizon/components/src/v2'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

export const Structure = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const application = useSelector(selectApplicationById(id)) || {}
  const allocatedLoanStructures = useSelector(selectAllLoanSplits)

  const { totalLoanAmount, securities = [] } = application

  const totalAllocatedLoanAmount = allocatedLoanStructures.reduce(
    (total, { loanAmount }) => total + loanAmount,
    0
  )

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
    dispatch(fetchProducts())
    dispatch(fetchLoanSplits(id))
  }, [dispatch, id])

  if (isFetchingApplication) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <div style={s('ml-8')}>
      <BackButton
        variant="text"
        style={s('mb-8 ml-24')}
        onClick={() => history.push(`/loan-applications/${id}/security`)}
      >
        <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>

      <Row style={s('px-24')}>
        <Col span={24}>
          <h1 style={s('font-semibold mb-10')} className="sohne">
            Loan Structure
          </h1>
        </Col>

        <SectionTitle title="Loan Summary" style={s('ml-2 mb-3')} />
        <LoanSummaryChart
          allocatedLoanStructures={allocatedLoanStructures}
          totalRequestedLoanAmount={totalLoanAmount}
          totalAllocatedLoanAmount={totalAllocatedLoanAmount}
        />

        <LoanBuilder
          loanApplication={application}
          allocatedLoanStructures={allocatedLoanStructures}
          totalRequestedLoanAmount={totalLoanAmount}
          totalAllocatedLoanAmount={totalAllocatedLoanAmount}
          isLoanBuilderReady={isLoanBuilderReady(securities)}
        />
      </Row>
    </div>
  )
}
function isLoanBuilderReady(securities = []) {
  return allSecuritiesAreLinked()

  function allSecuritiesAreLinked() {
    return (
      securities.filter((security) => isNilOrEmpty(security?.linkedDetails)).length ===
      0
    )
  }
}
