import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { VALOCITY_REPORT_SECTION_IDS } from './constants'
import { SideNavigationReportItem } from './SideNavigationReportItem'

const {
  GENERAL_DETAILS,
  PROPERTY_DETAILS,
  IVAL_ESTIMATE_RANGE,
  PROPERTY_FEATURES,
  RISK_FLAGS,
  RATING_VALUATION,
  LAST_KNOWN_SALE,
  COMPARABLE_PROPERTY_SALES,
} = VALOCITY_REPORT_SECTION_IDS

const Container = styled.div(
  s('w-full rounded-lg border-1 border-solid border-grey-200 bg-white py-3')
)
const Title = styled.div(
  s('w-full flex text-base font-semibold py-2 pr-3 mb-1 items-center', {
    color: '#141616',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    paddingLeft: 18,
  })
)

const steps = [
  { name: 'General Details', sectionId: GENERAL_DETAILS },
  { name: 'Property Details', sectionId: PROPERTY_DETAILS },
  { name: 'iVal Estimate Range', sectionId: IVAL_ESTIMATE_RANGE },
  { name: 'Property Features', sectionId: PROPERTY_FEATURES },
  { name: 'Risk Flags', sectionId: RISK_FLAGS },
  { name: 'Rating Valuations', sectionId: RATING_VALUATION },
  { name: 'Last Known Sale', sectionId: LAST_KNOWN_SALE },
  { name: 'Comparable Property Sales', sectionId: COMPARABLE_PROPERTY_SALES },
]

const SidebarNavigationReport = ({ activeSection, setActiveSection, ...props }) => {
  return (
    <Container {...props}>
      <Title>Report Content</Title>

      {steps.map(({ name, sectionId }, index, { length }) => {
        const lastStep = index === length - 1
        return (
          <SideNavigationReportItem
            key={index}
            isActive={activeSection === sectionId}
            link={`#${sectionId}`}
            name={name}
            onClick={() => {
              const anchor = document.getElementById(sectionId)
              const root = document.getElementById('router-root')

              root.scrollTo({
                top: anchor.offsetTop + 195,
                behavior: 'smooth',
              })
              setActiveSection(sectionId)
            }}
            style={s(lastStep ? 'mb-0' : '')}
          />
        )
      })}
    </Container>
  )
}

export { SidebarNavigationReport }
