import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import {
  capitaliseFirstLetter,
  formatDateString,
  buildDuration,
  formatFullName,
  buildAddress,
} from 'utils'

export const mapToAdviserNotes = (comments = []) => {
  const adviserNoteComment = comments.find(({ contextDescription }) =>
    contextDescription.includes('Advisor Notes - ')
  )
  const specificGoalsAndObjectives = comments.find(
    ({ contextDescription }) => contextDescription === 'Immediate goals & objectives'
  )

  if (isNilOrEmpty(adviserNoteComment && isNilOrEmpty(specificGoalsAndObjectives)))
    return [{ comment: 'No notes to display' }]

  const { comment: clientComment, contextDescription } = adviserNoteComment

  const adviserNote = {
    title: 'Additional Info / Notes to Lender',
    comment: contextDescription.substring(16),
  }

  const clientBackground = {
    title: 'Client Background / Character',
    comment: clientComment,
  }

  const specificGoals = {
    title: 'Specific Goals / Objectives',
    comment: specificGoalsAndObjectives?.comment,
  }

  return [adviserNote, clientBackground, specificGoals]
}

const formatAddresses = (addresses = []) =>
  addresses?.map(
    ({
      duration: { length, units },
      endDate,
      housingStatus,
      startDate,
      fullAddress,
      type = 'address',
    }) => {
      return {
        address: fullAddress || '-',
        duration: buildDuration(length, units),
        endDate: endDate === 'present' ? 'Present' : formatDateString(endDate),
        startDate: formatDateString(startDate),
        status: capitaliseFirstLetter(housingStatus),
        type,
      }
    }
  )

/** @param {Individual[]} individuals */
export const mapToApplicants = (individuals = []) =>
  individuals?.map(
    ({
      contact,
      dateOfBirth,
      entityId,
      isPrimaryApplicant,
      maritalStatus,
      personName,
      applicantRole,
      residencyStatus,
      ...others
    }) => {
      const {
        currentAddress = {},
        currentMailingAddress = {},
        email,
        phone,
        previousAddresses = [],
      } = contact

      const { firstName, nameTitle, surname: lastName } = personName

      const addresses = [currentAddress]

      if (previousAddresses.length !== 0)
        previousAddresses.forEach((address) => addresses.push(address))

      addresses.push({ type: 'mailingAddress', ...currentMailingAddress })

      return {
        ...others,
        addresses: formatAddresses(addresses),
        applicantRole,
        dob: formatDateString(dateOfBirth),
        email,
        entityId,
        fullName: formatFullName({ nameTitle, firstName, lastName }),
        isPrimaryApplicant,
        maritalStatus: capitaliseFirstLetter(maritalStatus),
        phone,
        residencyStatus,
      }
    }
  )

export const mapToBusinesses = (companies = []) =>
  companies?.map(
    ({
      applicantRole,
      businessStructure,
      contact: { address },
      NZBN,
      directors,
      fullName,
    }) => {
      const {
        city,
        countryISO,
        fullAddress,
        newZealandPostCode,
        standard: { streetName, streetNumber, streetType, unit },
        suburb,
      } = address

      const formattedAddress = buildAddress(
        { streetName, streetNumber, streetType },
        suburb,
        city,
        countryISO,
        unit,
        newZealandPostCode,
        fullAddress
      )

      return {
        address: formattedAddress,
        name: fullName,
        nzbn: NZBN,
        structure: businessStructure,
        type: applicantRole,
        directors,
      }
    }
  )

function mapBeneficiaries(trust, relatedPeople = []) {
  if (isNilOrEmpty(relatedPeople)) return []

  return trust.beneficiaries
    .map((beneficiary) =>
      relatedPeople.find((person) => person?.externalRef === beneficiary?.xBeneficiary)
    )
    .filter((beneficiary) => isNotNilOrEmpty(beneficiary))
}

export const mapToTrusts = (trusts = [], relatedPeople = []) => {
  if (isNilOrEmpty(trusts)) return []

  const mappedTrusts = trusts.map((trust) => ({
    ...trust,
    beneficiaries: mapBeneficiaries(trust, relatedPeople),
  }))

  return mappedTrusts
}
