import {
  ProductService,
  CreditReportService,
  LoanApplicationService,
  LendingEntityService,
  AffordabilityAssessmentService,
  SupportingDocumentService,
  PropertyService,
  FinancialService,
  NoteService,
  ActivityLogService,
  RepaymentService,
  LoanSplitService,
  NotificationService,
  MapService,
  UserService,
} from '@horizon/services'

const bankingApiUri = process.env.REACT_APP_BANKING_API_URI
const lendingApiUri = process.env.REACT_APP_LENDING_API_URI ?? ''
const mapServiceApiUri = process.env.REACT_APP_MAPBOX_API_URL
const profileApiUri = process.env.REACT_APP_PROFILE_API_URI

/**
 * @param {string} baseUri
 */
const getOptions = (baseUri) => ({ baseUri })

export const productService = new ProductService(getOptions(bankingApiUri))
export const repaymentService = new RepaymentService(getOptions(bankingApiUri))
export const creditReportService = new CreditReportService(getOptions(lendingApiUri))
export const affordabilityAssessmentService = new AffordabilityAssessmentService(
  getOptions(lendingApiUri)
)
export const loanApplicationService = new LoanApplicationService(
  getOptions(lendingApiUri)
)
export const lendingEntityService = new LendingEntityService(getOptions(lendingApiUri))
export const supportingDocumentService = new SupportingDocumentService(
  getOptions(lendingApiUri)
)
export const propertyService = new PropertyService(getOptions(lendingApiUri))
export const financialService = new FinancialService(getOptions(lendingApiUri))
export const noteService = new NoteService(getOptions(lendingApiUri))
export const activityLogService = new ActivityLogService(getOptions(lendingApiUri))
export const loanSplitService = new LoanSplitService(getOptions(lendingApiUri))
export const notificationService = new NotificationService(getOptions(lendingApiUri))
export const mapService = new MapService(getOptions(mapServiceApiUri))
export const userService = new UserService(getOptions(profileApiUri))
