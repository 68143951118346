import React from 'react'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import { formatCurrency, formatDateString } from 'utils'

const RatingValuation = ({ ratingValuation }) => {
  const {
    capitalValue,
    landValue,
    improvementValues,
    lastValued,
    valuationReference,
  } = ratingValuation

  return (
    <ContentCard>
      <Row>
        <Col span={8}>
          <DataDisplay
            label="Capital Value"
            value={formatCurrency(capitalValue)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Land Value"
            value={formatCurrency(landValue)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Value of Improvements"
            value={formatCurrency(improvementValues)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Valuation Date"
            value={formatDateString(lastValued, 'd MMM yyyy')}
            valueStyles={s('mb-8')}
          />
        </Col>

        <Col span={8}>
          <DataDisplay
            label="Valuation Reference"
            value={valuationReference}
            valueStyles={s('mb-8')}
          />
        </Col>
      </Row>
    </ContentCard>
  )
}

export { RatingValuation }
