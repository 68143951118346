import React from 'react'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import { DataDisplay, Divider } from 'features/loanAssessment/info/components'
import { ReactComponent as MapMarker } from '@horizon/components/src/assets/images/map-marker.svg'
import { formatCurrency, formatDateString } from 'utils'

const SaleInformation = ({ comparableSale = {} }) => {
  return (
    <Row style={s('mt-3')}>
      <Col span={1} style={s('mr-4')}>
        <div style={s('relative w-3 h-3')}>
          <MapMarker style={s('m-0 w-full h-full')} />
          <h2
            style={s('absolute m-0 text-sm', {
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            })}
          >
            {comparableSale?.address?.streetNumber}
          </h2>
        </div>
      </Col>
      <Col span={22}>
        <DataDisplay
          label="Address"
          value={comparableSale?.address?.full}
          valueStyles={{ style: s('text-xl') }}
        />
      </Col>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={11}>
        <img src={comparableSale?.imageUrl} style={s('rounded-lg w-24 h-16')} />
      </Col>

      <Col span={13}>
        <Row gutter={[0, 0]}>
          <Col span={12}>
            <DataDisplay
              label="Sale Price"
              value={formatCurrency(comparableSale?.price)}
              valueStyles={{ style: s('mb-6 text-xl font-bold') }}
            />
          </Col>

          <Col span={12}>
            <DataDisplay
              label="Sale Date"
              value={formatDateString(comparableSale?.date, 'd MMM yyyy')}
              valueStyles={{ style: s('mb-6 text-xl font-bold') }}
            />
          </Col>
          <Col span={12}>
            <DataDisplay
              label="Sale Method"
              value={comparableSale?.method || '-'}
              valueStyles={{ style: s('mb-6') }}
            />
          </Col>

          <Col span={12}>
            <DataDisplay
              label="Capital Value"
              value={formatCurrency(comparableSale?.capitalValue)}
              valueStyles={{ style: s('mb-6') }}
            />
          </Col>

          <Col span={12}>
            <DataDisplay
              label="Decade Constructed"
              value={comparableSale?.decadeConstructed}
              valueStyles={{ style: s('mb-6') }}
            />
          </Col>

          <Col span={12}>
            <DataDisplay
              label="Land Area"
              value={comparableSale?.landArea}
              valueStyles={{ style: s('mb-6') }}
            />
          </Col>

          <Col span={12}>
            <DataDisplay label="Floor Area" value={comparableSale?.floorArea} />
          </Col>

          <Col span={12}>
            <DataDisplay label="Bedrooms" value={comparableSale?.bedrooms} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { SaleInformation }
