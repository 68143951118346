import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@horizon/styled/v2'
import { FINANCIAL } from '@horizon/constants'
import { SectionTitle as SectionTitleBase } from '@horizon/components/src/v2'
import { Button, Loading } from '@horizon/components'
import { ReactComponent as ChevronLeft } from '@horizon/components/src/assets/images/chevron-left.svg'
import { fetchLoanApplication, selectApplicationById } from 'modules/application'
import {
  fetchLoanFinancials,
  selectAllLoanFinancials,
  selectHouseholds,
  selectIsFetchLoanFinancialsPending,
} from 'modules/financial'
import { formatBoolean, formatCurrency } from 'utils/formatters'
import { mapToAdverseChanges } from './mapToAdverseChanges'
import { ViewAssetDetail } from './ViewAssetDetail'
import { ViewLiabilityDetail } from './ViewLiabilityDetail'
import { ViewIncomeDetails } from './ViewIncomeDetails'
import { ViewExpenseDetails } from './ViewExpenseDetails'
import { AdverseChanges } from './AdverseChanges'
import { getFinancialSumForPAYE } from './getFinancialSumForPAYE'

const {
  TYPE: { INCOME, LIABILITY, NON_REAL_ESTATE_ASSET, REAL_ESTATE_ASSET },
} = FINANCIAL

const BackButton = styled(Button)(s('border-transparent p-0 flex flex-row'))

const BackButtonText = styled(SectionTitleBase)(s('text-sm'))
const Value = styled.div(s('mt-2 mb-2 text-primary w-full font-bold'), {
  fontSize: 31,
})

const checkIfChangesAreAnticipated = (adverseChanges = []) => {
  let isAnticipated = false

  adverseChanges.forEach(({ responsibleLending }) => {
    const { anticipatedChanges } = responsibleLending

    if (anticipatedChanges) isAnticipated = true
  })

  return isAnticipated
}

const convertToYearly = (amount, frequency) => {
  switch (frequency) {
    case 'Fortnightly':
      return amount * (365 / 14)
    case 'Monthly':
      return amount * 12
    case 'Weekly':
      return amount * (365 / 7)
    default:
      return amount
  }
}

// eslint-disable-next-line complexity
export const Financial = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const application = useSelector(selectApplicationById(id))

  const { legalEntities, relatedCompanies = [] } = application
  const individuals = legalEntities?.individuals ?? []

  const adverseChanges = mapToAdverseChanges(individuals)
  const changesAreAnticipated = formatBoolean(
    checkIfChangesAreAnticipated(adverseChanges)
  )

  const loanFinancials = useSelector(selectAllLoanFinancials) ?? []

  const households = useSelector(selectHouseholds) ?? []

  const isFetchingFinancials = useSelector(selectIsFetchLoanFinancialsPending)

  const employments =
    application?.legalEntities?.individuals.map((entity) => {
      const {
        personName: { firstName, surname, nameTitle },
      } = entity

      const entityEmployments = entity?.employments.map((emp) => {
        if (
          isNotNilOrEmpty(emp.selfEmployed) &&
          isNotNilOrEmpty(emp?.selfEmployed?.occupation)
        ) {
          return {
            employment: {
              type: 'Self Employed',
              taxable: true, // Default to true because we are explicitly using profit before tax, and frequency is not availble.
              status: emp.selfEmployed.status,
              startDate: emp.selfEmployed.startDate,
              endDate: emp.selfEmployed.endDate,
              basis: emp.selfEmployed.basis,
              occupation: emp.selfEmployed.occupation,
              duration: emp.selfEmployed.duration,
              // Missing Frequency
            },
            totalIncomeAmount: emp.selfEmployed.businessIncomeRecent.profitBeforeTax,
          }
        }

        return {
          employment: {
            basis: emp.PAYE.basis,
            duration: emp.PAYE.duration,
            employerRef: emp.PAYE.xEmployer,
            endDate: emp.PAYE.endDate,
            occupation: emp.PAYE.occupation,
            onProbation: emp.PAYE.onProbation,
            status: emp.PAYE.status,
            startDate: emp.PAYE.startDate,
            type: 'PAYE',
          },
          totalIncomeAmount: getFinancialSumForPAYE(emp.PAYE),
        }
      })

      return {
        firstName,
        lastName: surname,
        title: nameTitle,
        employments: entityEmployments,
      }
    }) ?? []

  let incomeFinancials = loanFinancials.find(
    ({ financialType }) => financialType === INCOME
  )

  /**
   * Disclaimer
   * Financial data, especially Income data is scattered accross multiple data collections because of the way LIXI standard is modelled.
   * This means that we need to fetch data from both these sources for this web page, however the same pieces of data is sometimes duplicated e.g. Self Employment data is replicated but has subsets of each in both sources.
   * Compromises were made to implement this way in the UI, should be refactored and pushed into the API later.
   * */
  incomeFinancials = incomeFinancials?.financials?.filter((f) => {
    const { description, amount } = f
    // Identify duplicated data between data from financials source and legal entity source
    const hasDuplicated = application?.legalEntities?.individuals.find((e) => {
      return e.employments.find(
        (emp) =>
          emp.selfEmployed.business.industry === description &&
          emp.selfEmployed.businessIncomeRecent.profitBeforeTax === amount
      )
    })

    return !hasDuplicated
  })

  incomeFinancials = { financialType: 'income', financials: incomeFinancials || [] }

  const liabilityFinancials = loanFinancials.find(
    ({ financialType }) => financialType === LIABILITY
  )
  const nonRealAssetFinancials = loanFinancials.find(
    ({ financialType }) => financialType === NON_REAL_ESTATE_ASSET
  )
  const realAssetFinancials = loanFinancials.find(
    ({ financialType }) => financialType === REAL_ESTATE_ASSET
  )

  let totalLiability = 0
  let totalEmployeeIncome = 0
  let totalOtherIncome = 0
  let nonRealAsset = 0
  let realAsset = 0
  let totalExpenseAmountOfAllHouseholds = 0

  if (isNotNilOrEmpty(nonRealAssetFinancials)) {
    nonRealAsset = nonRealAssetFinancials.financials
      .map((financial) => financial.estimatedValue.value)
      .reduce((prev, curr) => prev + curr, 0)
  }

  if (isNotNilOrEmpty(realAssetFinancials)) {
    realAsset = realAssetFinancials.financials
      .map((financial) => financial.estimatedValue.value)
      .reduce((prev, curr) => prev + curr, 0)
  }

  if (isNotNilOrEmpty(incomeFinancials)) {
    totalOtherIncome = incomeFinancials.financials
      .map((financial) => {
        const { amount, frequency } = financial

        return convertToYearly(amount, frequency)
      })
      .reduce((prev, curr) => prev + curr, 0)
  }

  if (isNotNilOrEmpty(liabilityFinancials)) {
    totalLiability = liabilityFinancials.financials
      .map((financial) => financial.creditLimit)
      .reduce((prev, curr) => prev + curr, 0)
  }

  employments.forEach((employment) => {
    let incomeAccumulator = 0
    employment.employments.forEach((emp) => {
      incomeAccumulator += emp.totalIncomeAmount || 0
    })
    totalEmployeeIncome += incomeAccumulator
  })

  households.forEach(({ livingExpenses, otherExpenses }) => {
    const totalLivingExpense = livingExpenses?.reduce(
      (totalIncome, { amount }) => totalIncome + amount || 0,
      0
    )
    const totalOtherExpense = otherExpenses?.reduce(
      (totalIncome, { amount }) => totalIncome + amount || 0,
      0
    )

    const householdExpense = totalLivingExpense + totalOtherExpense

    totalExpenseAmountOfAllHouseholds += householdExpense
  })

  const totalAsset = nonRealAsset + realAsset
  const totalIncomeAmount = totalOtherIncome + totalEmployeeIncome

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
    dispatch(fetchLoanFinancials(id))
  }, [dispatch, id])

  if (isFetchingFinancials) {
    return <Loading />
  }

  return (
    <div style={s('px-24')}>
      <BackButton
        variant="text"
        style={s('mb-8')}
        onClick={() => history.push(`/loan-applications/${id}/info`)}
      >
        <ChevronLeft />

        <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>

      <div>
        <div style={s('text-grey-700')}>Total Assets</div>

        <Value className="sohne">{formatCurrency(totalAsset)}</Value>

        <ViewAssetDetail
          realAssetData={realAssetFinancials}
          nonRealAssetData={nonRealAssetFinancials}
        />

        <div style={s('text-grey-700 mt-16')}>Total Liabilities</div>

        <Value className="sohne">{formatCurrency(totalLiability)}</Value>

        <ViewLiabilityDetail data={liabilityFinancials} />

        <div style={s('text-grey-700 mt-16')}>Total Income</div>

        <Value className="sohne">{formatCurrency(totalIncomeAmount)}</Value>

        <ViewIncomeDetails
          individuals={employments}
          otherIncomes={incomeFinancials?.financials}
          relatedCompanies={relatedCompanies}
        />

        <div style={s('text-grey-700 mt-16')}>Total Expenses</div>

        <Value className="sohne">
          {formatCurrency(totalExpenseAmountOfAllHouseholds)}
        </Value>

        <ViewExpenseDetails households={households} />

        <div style={s('text-grey-700 mt-16')}>Adverse Changes</div>

        <Value className="sohne">{changesAreAnticipated}</Value>

        <AdverseChanges individuals={adverseChanges} />
      </div>
    </div>
  )
}
