import { parseJSON, format, isAfter, isBefore } from 'date-fns'
import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'

const capitaliseFirstLetter = (string) => {
  try {
    return string.toLowerCase().charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  } catch (error) {
    return '-'
  }
}

const capitaliseFirstLetterOfAllWords = (sentence) => {
  try {
    return sentence
      .split(' ')
      .map((word) => capitaliseFirstLetter(word))
      .join(' ')
  } catch (error) {
    return '-'
  }
}

const formatCRRValue = (letter, number) => {
  if (isNilOrEmpty(letter) || isNilOrEmpty(number)) {
    return '-'
  }
  return `${letter} ${number}`
}
const formatDateString = (dateString, targetFormat = 'dd/MM/yyyy') => {
  try {
    return format(parseJSON(dateString), targetFormat)
  } catch (error) {
    return '-'
  }
}

const isBetween = (date, lowerBoundaryDate, upperBoundaryDate) => {
  try {
    const isAfterLowerBoundary = isAfter(date, lowerBoundaryDate)
    const isBeforeUpperBoundary = isBefore(date, upperBoundaryDate)

    return isAfterLowerBoundary && isBeforeUpperBoundary
  } catch (error) {
    return false
  }
}

const formatCurrency = (value, digits = 0, placeholder = '-') => {
  if (isNilOrEmpty(value) || typeof value !== 'number') return placeholder

  return new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: digits,
  }).format(value)
}

// eslint-disable-next-line complexity
const buildAddress = (
  streetDetails = {},
  suburb,
  city,
  countryISO,
  unit,
  postCode,
  fullAddress
) => {
  function getStreetAddress(unit, streetNumber, streetName, streetType) {
    let enrichedAddress = `${streetName}`
    if (streetNumber) enrichedAddress = `${streetNumber} ${enrichedAddress}`
    if (streetNumber && unit) enrichedAddress = `${unit}/${enrichedAddress}`
    if (streetType) enrichedAddress = `${enrichedAddress} ${streetType}`

    return capitaliseFirstLetterOfAllWords(enrichedAddress)
  }

  function concatOtherAddressFields(streetAddress, suburb, city, postCode, countryISO) {
    // The order of the fields determined how the address parts are organized
    let address = [streetAddress, suburb, city, postCode]
      .map((field) => field?.trim())
      .filter((field) => isNotNilOrEmpty(field))
      .map((field) => capitaliseFirstLetterOfAllWords(field))
      .join(', ')

    if (countryISO) address += `, ${countryISO?.toUpperCase()}`

    return address
  }

  try {
    if (isNotNilOrEmpty(fullAddress)) return fullAddress

    const { streetNumber, streetName, streetType } = streetDetails
    if (isNilOrEmpty(streetName))
      return concatOtherAddressFields('', suburb, city, postCode, countryISO) || '-'

    const streetAddress = getStreetAddress(unit, streetNumber, streetName, streetType)
    return (
      concatOtherAddressFields(streetAddress, suburb, city, postCode, countryISO) || '-'
    )
  } catch (error) {
    return '-'
  }
}

const buildDuration = (length, units) => {
  try {
    if (isNilOrEmpty(length) || isNilOrEmpty(units)) return '-'

    return `${length} ${units}`
  } catch (error) {
    return '-'
  }
}

// eslint-disable-next-line complexity
const formatFullName = (personName = {}, placeholder = '-') => {
  try {
    const { nameTitle, firstName, lastName } = personName
    if (isNilOrEmpty(firstName) && isNilOrEmpty(lastName)) return '-'
    if (isNilOrEmpty(firstName)) return '-'
    if (isNilOrEmpty(lastName)) return firstName

    return isNilOrEmpty(nameTitle)
      ? `${firstName} ${lastName}`
      : `${nameTitle} ${firstName} ${lastName}`
  } catch (error) {
    return placeholder
  }
}

const formatBoolean = (value) => {
  if (value) return 'Yes'
  return 'No'
}

const formatNull = (value) => {
  if (value) return value
  return '-'
}

const throwIfInvalidHashFormat = (hashFormat) => {
  const notConsistWithOnlyHashAndSpace = /(?!(#|\s))./g.test(hashFormat)

  if (notConsistWithOnlyHashAndSpace) {
    throw new Error(
      'Invalid hash format passed into insertSeparatorsToString: It should be a string consisted with hashes and spaces'
    )
  }
}

// eslint-disable-next-line complexity
const insertSeparatorsToString = (
  value,
  separator = ' ',
  hashFormat = '#### ####',
  placeholder = undefined
) => {
  try {
    if (isNilOrEmpty(value)) return placeholder

    throwIfInvalidHashFormat(hashFormat)

    let formattedString = ''
    const hashFormatArr = hashFormat.toString().split('')

    let inputValuePointer = 0
    const stopForLoopAtIdx = value.length
    for (
      let hashValuePointer = 0;
      hashValuePointer < hashFormatArr.length;
      hashValuePointer += 1
    ) {
      if (inputValuePointer === stopForLoopAtIdx) break

      if (hashFormatArr[hashValuePointer] === '#') {
        formattedString += value.charAt(inputValuePointer)
        inputValuePointer += 1
        // eslint-disable-next-line no-continue
        continue
      }

      formattedString += `${separator}${value.charAt(inputValuePointer)}`
      inputValuePointer += 1
      hashValuePointer += 1
    }

    return formattedString
  } catch (error) {
    return placeholder
  }
}

const formatPhoneNumber = (phoneNumber) =>
  isNotNilOrEmpty(insertSeparatorsToString(phoneNumber, ' ', `### ### ####`))
    ? insertSeparatorsToString(phoneNumber, ' ', `### ### ####`)
    : '-'

export {
  capitaliseFirstLetter,
  capitaliseFirstLetterOfAllWords,
  formatDateString,
  buildAddress,
  formatCurrency,
  buildDuration,
  formatFullName,
  formatBoolean,
  formatCRRValue,
  isBetween,
  formatNull,
  insertSeparatorsToString,
  formatPhoneNumber,
}
