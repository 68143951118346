import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3'
import { prop, isNil, isNilOrEmpty, defaultTo } from '@solta/ramda-extra'
import Cookies from 'js-cookie'
import moment from 'moment'
import axios from 'axios'

import { refreshAuthSession } from '../utils'
import { AUTH } from '@horizon/constants'

const { CREDENTIALS_COOKIE_KEY } = AUTH

const REGION = process.env.REACT_APP_AUTH_REGION
const AUTH_WEB_URI = process.env.REACT_APP_AUTH_WEB_URI

export class UploadService {
  constructor() {
    const credentials = JSON.parse(defaultTo(null, Cookies.get(CREDENTIALS_COOKIE_KEY)))

    this.client = new S3Client({ region: REGION, credentials })
  }

  async updateClientIfNeeded() {
    let credentials = JSON.parse(defaultTo(null, Cookies.get(CREDENTIALS_COOKIE_KEY)))
    const expiration = prop('expiration', credentials)

    if (isNil(expiration) || moment().isAfter(moment(expiration))) {
      await refreshAuthSession()

      credentials = JSON.parse(defaultTo(null, Cookies.get(CREDENTIALS_COOKIE_KEY)))

      this.client = new S3Client({ region: REGION, credentials })
    }

    const hasAuthSessionExpired = isNilOrEmpty(credentials)
    if (hasAuthSessionExpired) {
      window.location.replace(`${AUTH_WEB_URI}sign-in`)
      return Response.error()
    }

    return credentials
  }

  async upload(file) {
    const { fileDetails, presignedUrl } = file

    await axios.put(presignedUrl, fileDetails, {
      headers: {
        'Content-Type': fileDetails.type,
      },
    })
  }

  async delete(bucket, key) {
    const credentials = await this.updateClientIfNeeded()

    const prefix = prop('identityId', credentials)

    const params = {
      Bucket: bucket,
      Key: `${prefix}/${key}`,
    }

    await this.client.send(new DeleteObjectCommand(params))

    return key
  }
}
