import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { Row, Col } from '@horizon/styled'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { ContentCard } from 'features/loanAssessment/info/components'
import { LiabilitiesOverview } from './LiabilitiesOverview'
import { NoteSection } from 'features/common/NoteSection'
import { useParams } from 'react-router-dom'

export const ViewLiabilityDetail = ({ data }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
    cursor: 'pointer',
    path: { stroke: s('text-primry').color },
  })
  const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
    cursor: 'pointer',
    path: { stroke: s('text-primry').color },
  })
  const CollapseButton = styled.button(
    s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
    {
      cursor: 'pointer',
      backgroundColor: 'white',
      fontSize: 14,
      borderRadius: 6,
    }
  )
  const ViewDetailsButton = styled.button(
    s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
    {
      cursor: 'pointer',
      backgroundColor: 'white',
      fontSize: 14,
      borderRadius: 6,
    }
  )
  let liabilityArray = []
  if (isNotNilOrEmpty(data?.financials)) {
    liabilityArray = data?.financials
  }
  const { id } = useParams()

  return (
    <div>
      {isExpanded ? (
        <div>
          {isNotNilOrEmpty(data?.financials) ? (
            <div>
              <Row style={s('mt-8')}>
                {liabilityArray.map((liability) => (
                  <Col key={liability.id} span={8}>
                    <LiabilitiesOverview
                      clearingFromThisLoan={liability.clearingFromThisLoan}
                      clearingFromThisLoanAmount={liability.clearingFromThisLoanAmount}
                      creditLimit={liability.creditLimit}
                      entities={liability?.userPercentOwned.map(
                        ({
                          personName,
                          businessName,
                          trustName,
                          companyName,
                          _id,
                        }) => ({
                          personName,
                          businessName,
                          trustName,
                          companyName,
                          id: _id,
                        })
                      )}
                      financialInstitution={
                        liability.accountNumber.financialInstitution
                      }
                      hasArrears={liability.hasArrears}
                      interestRate={liability.annualInterestRate}
                      isRegular={liability.repayments[0].regular}
                      isSecurity={liability.securities[0].priority}
                      isTaxDeductable={liability.repayments[0].taxDeductible}
                      liabilityType={liability.domainType}
                      loanPaymentScheduleType={
                        liability.repayments[0].loanPaymentScheduleType
                      }
                      otherFIName={liability.accountNumber.otherFIName}
                      outstandingBalance={liability.outstandingBalance}
                      ownerships={liability?.percentOwned.owners}
                      paymentType={liability.repayments[0].paymentType}
                      repaymentAmount={liability.repayments[0].repaymentAmount}
                      repaymentFrequency={liability.repayments[0].repaymentFrequency}
                    />
                  </Col>
                ))}
              </Row>
              <Row style={s('mb-3 mt-4')} gutter={[0, 0]}>
                <Col span={24}>
                  <NoteSection
                    id="note-section"
                    loanApplicationId={id}
                    source="loan-applications/financial/liability"
                  />
                </Col>
              </Row>
              <CollapseButton onClick={() => setIsExpanded(false)}>
                <Row style={s('justify-center')}>
                  <div>Collapse</div>
                  <ChevronUpIcon width={20} height={20} />
                </Row>
              </CollapseButton>
            </div>
          ) : (
            <Row>
              <Col>
                <ContentCard style={s('mb-4')}>No data yet</ContentCard>
              </Col>
              <Col span={24}>
                <NoteSection
                  id="note-section"
                  loanApplicationId={id}
                  source="loan-applications/financial/liability"
                />
              </Col>
              <Col>
                <CollapseButton onClick={() => setIsExpanded(false)}>
                  <Row style={s('justify-center')}>
                    <div>Collapse</div>
                    <ChevronUpIcon width={20} height={20} />
                  </Row>
                </CollapseButton>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div>
          <ViewDetailsButton onClick={() => setIsExpanded(true)}>
            <Row style={s('justify-center')}>
              <div>View Details</div>
              <ChevronDownIcon width={20} height={20} />
            </Row>
          </ViewDetailsButton>
        </div>
      )}
    </div>
  )
}
