import React, { useCallback, useState } from 'react'
import {
  fetchLoanApplications,
  selectApplications,
  selectTotal,
} from 'modules/application'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { styled, s } from '@horizon/styled/v2'
import { Formik as FormProvider, Form } from 'formik'
import { SearchBar as SearchBarBase, DottedCircleLoader } from '@horizon/components'
import { Table } from '@horizon/components/src/v2'
import FiltersBar from './FiltersBar'
import { Notifications } from '../common/notifications'
import { Columns, Rows } from './TableFormat'
import { useTableFetching } from 'hooks'

const Root = styled.div(s('flex flex-column w-full mx-8'))
const Row = styled.div(s('flex flex-row pb-4 pt-4'))
const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const Title = styled.h1(
  s('font-bold m-0 mt-9 text-3xl', {
    lineHeight: 1.2,
  })
)

const SearchForm = styled(Form)(s('w-full'))

const SearchBar = styled(SearchBarBase)(s('bg-white rounded-lg'))
const TableContainer = styled.div(
  s('mt-0 h-full mb-1 bg-transparent rounded-t-lg rounded-b-lg', {
    overflow: 'hidden',
  })
)

export const ApplicationList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const totalItems = useSelector(selectTotal)
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)

  const applications = useSelector(selectApplications)

  const fetchApplications = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(fetchLoanApplications({ searchParams, pageIndex })),
    [dispatch]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(totalItems, fetchApplications)

  React.useEffect(() => {
    async function fetchLoanApplicationsOnMount() {
      await fetchApplications({
        searchParams: { limit: searchParams.limit },
        pageIndex: 0,
      })
      setHasFirstFetchFulfilled(true)
    }

    fetchLoanApplicationsOnMount()
  }, [dispatch, fetchApplications, searchParams.limit])

  const handleActionBtnClicked = useCallback(
    (id) => history.push(`/loan-applications/${id}/info`),
    [history]
  )

  const handleTextSearch = ({ searchTerm }) => {
    updateSearchProps({
      searchTerm,
    })
  }

  const data = Rows({ applications })
  const columns = Columns({ handleAction: handleActionBtnClicked })

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full')}>
        <DottedCircleLoader size="lg" />
      </div>
    )
  }

  return (
    <Row style={s('p-0 h-full flex-1')}>
      <Root>
        <Row style={s('items-center justify-between')}>
          <Title>Loan Applications</Title>

          <Notifications />
        </Row>

        <SearchAndFiltersContainer>
          <FormProvider initialValues={{ searchTerm: '' }} onSubmit={handleTextSearch}>
            {({ values, handleChange }) => {
              return (
                <SearchForm>
                  <SearchBar
                    placeholder="Search applicant name"
                    onInputChange={handleChange}
                    value={values.searchTerm}
                    name="searchTerm"
                    id="searchTerm"
                  />
                </SearchForm>
              )
            }}
          </FormProvider>

          <FiltersBar
            updateSearchProps={updateSearchProps}
            filters={searchParams.filters}
          />
        </SearchAndFiltersContainer>

        <TableContainer style={s('pb-6')}>
          <Table
            columns={columns}
            data={data}
            loading={isFetchingData}
            paginationProps={paginationProps}
            style={s('rounded-lg')}
          />
        </TableContainer>
      </Root>
    </Row>
  )
}
