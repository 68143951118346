import React, { useState } from 'react'
import { s, styled } from '@horizon/styled/v2'
import { ReactComponent as PaperClipIconBase } from '@horizon/components/src/assets/images/paper-clip.svg'
import { ReactComponent as TrashIconBase } from '@horizon/components/src/assets/images/trash.svg'
import { DeleteFile } from './DeleteFile'

const Container = styled.div(s('flex justify-between'))
const NameContainer = styled.div(s('flex items-center'))
const PaperClipIcon = styled(PaperClipIconBase)(s('h-1 mr-2 text-grey-600'))
const Text = styled.p(
  s('font-normal m-0 text-grey-800 text-sm', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const TrashIcon = styled(TrashIconBase)(s('h-1 text-grey-600', { cursor: 'pointer' }))

const File = ({ handleDelete, fileName, ...props }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const openModal = () => setIsModalOpened(true)

  return (
    <>
      <Container {...props}>
        <NameContainer>
          <PaperClipIcon />
          <Text>{fileName}</Text>
        </NameContainer>

        <TrashIcon onClick={openModal} />
      </Container>

      <DeleteFile
        closeModal={() => setIsModalOpened(false)}
        isOpen={isModalOpened}
        handleDelete={handleDelete}
        fileName={fileName}
      />
    </>
  )
}

export { File }
