/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { LOAN, PRODUCT_CONSTANTS } from '@horizon/constants'
import { ContentCard } from 'features/loanAssessment/info/components'
import { selectIsCreatingLoanSplit, selectIsUpdatingLoanSplit } from 'modules/loanSplit'
import { useFormikContext } from 'formik'
import {
  MenuField as MenuFieldBase,
  NumberField,
  Button,
  MultiSelectField,
  CheckboxField,
  Anchor,
} from '@horizon/components'
import { weeklyOptions, monthlyOptions, fortnightlyOptions } from './dropDownOptions'
import { Item } from '@react-stately/collections'
import { formatFullName, capitaliseFirstLetter } from 'utils/formatters'
import { calculateTotalInterestRate } from 'utils/calculation'
import { SecurityConfiguration } from './SecurityConfiguration'
import { useRepaymentCalculation } from './useRepaymentCalculation'

const { LOAN_PURPOSE_DISPLAY_TEXT } = LOAN
const { OCCUPANCY_TYPE_DISPLAY_TEXT } = PRODUCT_CONSTANTS

const rowStyles = s('mt-4')
const sectionStyles = s('mt-8')

const SubTitle = styled.h3(
  s('text-xs font-normal text-primary font-semibold mt-4 mb-0', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const Title = styled.span(
  s('text-grey-900 text-2xl font-extrabold mb-4 font-semibold inline-block mb-2')
)

const MenuField = styled(MenuFieldBase, {
  shouldForwardProp: (prop) => prop !== 'side',
})(s('w-full', { minHeight: 55, marginTop: -0.5 }), ({ side }) =>
  side === 'left' ? s('mx-2') : s('mr-2')
)

const DisplayTextBox = styled.div(
  s('rounded-lg border-1 border-solid border-grey-400 bg-grey-200'),
  s('flex items-center justify-between p-4 w-full', { height: 52 })
)

const mapToProductDropdownOptions = (products) =>
  products.map(({ name, id }) => ({
    key: id,
    label: name,
  }))

const mapToEntityDropdownOptions = (entities) =>
  entities.map(({ id, fullName }) => ({
    key: id,
    label: fullName,
  }))

const mapToIndividualBorrowerDropdownOptions = (borrowers = []) => {
  const dropdownOptions = []

  borrowers.forEach(({ id, fullName, legalEntityType }) => {
    if (legalEntityType === 'individual')
      dropdownOptions.push({
        key: id,
        label: fullName,
      })
  })

  return dropdownOptions
}

const mapToSecurityDropdownOptions = (securities) =>
  securities.map(({ id, address }) => ({
    key: id,
    label: address,
  }))

const mapToRepaymentFrequencyDropdownOptions = (
  repaymentFrequencyAllowedByProduct = [],
  permittedFrequencies = ['weekly', 'fortnightly', 'monthly']
) => {
  const dropdownOptions = []

  repaymentFrequencyAllowedByProduct.forEach((frequency) => {
    if (permittedFrequencies.includes(frequency))
      dropdownOptions.push({
        key: frequency,
        label: capitaliseFirstLetter(frequency),
      })
  })

  if (isNilOrEmpty(dropdownOptions))
    return [
      {
        key: 'monthly',
        label: 'Monthly',
      },
    ]

  return dropdownOptions
}

const dayOfWeekNumberToText = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}

const scrollToForm = (anchorRef) => {
  anchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
}

const LoanAllocationForm = ({
  idx,
  structureId,
  fieldPrefix,
  loanStructureNumber,
  canAllocateLoanStructure,
  isRecommendedStructure = false,
  activeProducts = [],
  allBorrowers = [],
  allGuarantors = [],
  allSecurities = [],
  handleCancelBtnClicked,
  allocateLoanStructure,
}) => {
  const dispatch = useDispatch()
  const anchorRef = useRef(null)
  const { values, setFieldValue } = useFormikContext()

  const isCreatingLoanSplit = useSelector((state) =>
    selectIsCreatingLoanSplit(state, structureId)
  )
  const isUpdatingLoanSplit = useSelector((state) =>
    selectIsUpdatingLoanSplit(state, structureId)
  )

  const [repaymentFrequency, setRepaymentFrequency] = useState('monthly')

  const loanStructureValues = values.loanStructures[idx]

  const selectedProduct = activeProducts.find(
    (product) => product.id === loanStructureValues?.product?.id
  )
  const repaymentFrequencyAllowedByProduct =
    selectedProduct?.fields?.availableRepaymentFrequencies || []

  const { calculateRepayment } = useRepaymentCalculation(fieldPrefix, idx)

  const productDropdownOptions = mapToProductDropdownOptions(activeProducts)
  const borrowerDropdownOptions = mapToEntityDropdownOptions(allBorrowers)
  const primaryApplicantDropdownOptions =
    mapToIndividualBorrowerDropdownOptions(allBorrowers)
  const guarantorDropdownOptions = mapToEntityDropdownOptions(allGuarantors)
  const securitiesDropdownOptions = mapToSecurityDropdownOptions(allSecurities)
  const repaymentFrequencyDropdownOptions = selectedProduct
    ? mapToRepaymentFrequencyDropdownOptions(repaymentFrequencyAllowedByProduct, [
        'weekly',
        'fortnightly',
        'monthly',
      ])
    : []

  const onFrequencySelectionChanged = (selectedFrequency) => {
    if (selectedFrequency === 'weekly') {
      setRepaymentFrequency('weekly')
    } else if (selectedFrequency === 'fortnightly') {
      setRepaymentFrequency('fortnightly')
    } else {
      setRepaymentFrequency('monthly')
    }

    setFieldValue(`${fieldPrefix}.repaymentFrequency`, selectedFrequency)
    calculateRepayment()
  }

  useEffect(() => {
    scrollToForm(anchorRef)
  }, [])

  useEffect(() => {
    calculateRepayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <ContentCard style={s('mb-8')}>
      <Anchor ref={anchorRef} />

      <div style={s('mb-8')}>
        <Title className="sohne">Loan Split {loanStructureNumber}</Title>
        {isRecommendedStructure && (
          <p style={s('text-grey-900 mt-1')}>This loan split recommend from MyCRM</p>
        )}
      </div>

      <Row>
        <Col span={24}>
          <SubTitle>PRODUCT DETAILS</SubTitle>
          <Row style={rowStyles}>
            <Col span={12}>
              <Label>Select a product</Label>
              <MenuField
                label="Select a product"
                aria-label="product name"
                name={`${fieldPrefix}.product`}
                errorMsgSelector={(error) => error?.id}
                valueSelector={(newKey) => {
                  const selectedProduct = activeProducts.find(
                    (prod) => prod.id === newKey
                  )

                  const {
                    id,
                    name,
                    fields: {
                      maxLoanSplits,
                      minTermInYears,
                      maxTermInYears,
                      minLoanAmount,
                      maxLoanAmount,
                    },
                  } = selectedProduct

                  return {
                    id,
                    maxLoanSplits,
                    minTermInYears,
                    maxTermInYears,
                    minLoanAmount,
                    maxLoanAmount,
                    name,
                  }
                }}
                displayTextSelector={(newValue) => newValue?.name || '-'}
                onSelected={(newKey, newValue) => {
                  const selectedProduct = activeProducts.find(
                    (prod) => prod.id === newKey
                  )

                  const {
                    fields: { ongoingRate },
                  } = selectedProduct

                  setFieldValue(`${fieldPrefix}.product`, newValue)
                  setFieldValue(`${fieldPrefix}.baseInterestRate`, ongoingRate)
                  setFieldValue(`${fieldPrefix}.repaymentFrequency`, undefined)
                  calculateRepayment()
                }}
              >
                {productDropdownOptions.map((option) => (
                  <Item key={option.key}>{option.label}</Item>
                ))}
              </MenuField>
            </Col>
          </Row>

          <Row style={sectionStyles}>
            <Col span={24}>
              <SubTitle>Loan Amount</SubTitle>
              <Row style={rowStyles}>
                <Col span={12}>
                  <Label>Loan Amount</Label>
                  <NumberField
                    name={`${fieldPrefix}.loanAmount`}
                    placeholder="$"
                    onChange={calculateRepayment}
                  />
                </Col>

                <Col span={12}>
                  <Label>Loan Term (years)</Label>
                  <NumberField
                    name={`${fieldPrefix}.loanTermInYears`}
                    aria-label="loan term"
                    onChange={calculateRepayment}
                    placeholder="30"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={sectionStyles}>
            <Col span={24}>
              <SubTitle>INTEREST RATE</SubTitle>

              <Row style={rowStyles}>
                <Col span={8}>
                  <Label>Base Interest(%)</Label>
                  <Row style={s('pl-2')}>
                    <NumberField
                      name={`${fieldPrefix}.baseInterestRate`}
                      placeholder="0.0"
                      containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                      isReadOnly
                    />

                    <span style={s('inline-block mt-3 pl-2')}> + </span>
                  </Row>
                </Col>

                <Col span={8}>
                  <Label>Loading(%)</Label>
                  <Row style={s('pl-2')}>
                    <NumberField
                      name={`${fieldPrefix}.loading`}
                      placeholder="0.0"
                      onChange={calculateRepayment}
                      containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                    />

                    <span style={s('inline-block mt-3 pl-2')}> = </span>
                  </Row>
                </Col>

                <Col span={8}>
                  <Label>Final Interest(%)</Label>
                  <DisplayTextBox>
                    <span>
                      {calculateTotalInterestRate(
                        loanStructureValues.baseInterestRate,
                        loanStructureValues.loading
                      )}
                    </span>
                  </DisplayTextBox>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={sectionStyles}>
            <Col span={24}>
              <SubTitle>PURPOSE</SubTitle>

              <Row style={rowStyles}>
                <Col span={12}>
                  <Label>Loan Purpose</Label>

                  <Row>
                    <MenuField
                      label="Select Loan Purpose"
                      aria-label="loan purpose"
                      side={'left'}
                      displayTextSelector={(newKey) =>
                        LOAN_PURPOSE_DISPLAY_TEXT?.[newKey]
                      }
                      name={`${fieldPrefix}.loanPurpose`}
                    >
                      {Object.entries(LOAN_PURPOSE_DISPLAY_TEXT).map(([key, value]) => (
                        <Item key={key}>{value}</Item>
                      ))}
                    </MenuField>
                  </Row>
                </Col>

                <Col span={12}>
                  <Label>Occupancy</Label>

                  <Row style={{ marginLeft: 1 }}>
                    <MenuField
                      label="Select Occupancy"
                      aria-label="occupancy type"
                      side={'right'}
                      displayTextSelector={(newKey) =>
                        OCCUPANCY_TYPE_DISPLAY_TEXT?.[newKey]
                      }
                      name={`${fieldPrefix}.occupancyType`}
                    >
                      {Object.entries(OCCUPANCY_TYPE_DISPLAY_TEXT).map(
                        ([key, value]) => (
                          <Item key={key}>{value}</Item>
                        )
                      )}
                    </MenuField>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={sectionStyles}>
            <Col span={24}>
              <SubTitle>REPAYMENT &amp; SETTLEMENT</SubTitle>

              <Row style={rowStyles}>
                <Col span={12}>
                  <Label>Repayment Amount</Label>
                  <NumberField
                    name={`${fieldPrefix}.repaymentAmount`}
                    placeholder="0.0"
                    containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                    isReadOnly
                  />
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col span={12}>
                  <Label>Frequency</Label>

                  <Row>
                    <MenuField
                      label="Select frequency"
                      aria-label="frequency"
                      onSelected={onFrequencySelectionChanged}
                      side={'left'}
                      name={`${fieldPrefix}.repaymentFrequency`}
                    >
                      {repaymentFrequencyDropdownOptions.map((option) => (
                        <Item key={option.key}>{option.label}</Item>
                      ))}
                    </MenuField>
                  </Row>
                </Col>
                {repaymentFrequency === 'monthly' && (
                  <Col span={12}>
                    <Label>Day Of The Month</Label>
                    <Row style={{ marginLeft: 1 }}>
                      <MenuField
                        label="Select a day of the month"
                        aria-label="frequencyDate"
                        name={`${fieldPrefix}.dayOfTheMonth`}
                        side={'right'}
                      >
                        {monthlyOptions.map((option) => (
                          <Item key={option.key}>{option.label}</Item>
                        ))}
                      </MenuField>
                    </Row>
                  </Col>
                )}
                {repaymentFrequency === 'fortnightly' && (
                  <Col span={12}>
                    <Label>Day Of The Fortnight</Label>
                    <Row style={{ marginLeft: 1 }}>
                      <MenuField
                        label="Select a day of the fortnight"
                        name={`${fieldPrefix}.dayOfTheFortnight`}
                        aria-label="frequencyDate"
                      >
                        {fortnightlyOptions.map((option) => (
                          <Item key={option.key}>{option.label}</Item>
                        ))}
                      </MenuField>
                    </Row>
                  </Col>
                )}
                {repaymentFrequency === 'weekly' && (
                  <Col span={12}>
                    <Label>Day Of The Week</Label>
                    <Row>
                      <MenuField
                        label="Select a day of the week"
                        name={`${fieldPrefix}.dayOfTheWeek`}
                        aria-label="Repayment frequency of the loan"
                        displayTextSelector={(dayOfWeekInNumber) => {
                          return dayOfWeekNumberToText[dayOfWeekInNumber]
                        }}
                        side={'left'}
                      >
                        {weeklyOptions.map((option) => (
                          <Item key={option.key}>{option.label}</Item>
                        ))}
                      </MenuField>
                    </Row>
                  </Col>
                )}
              </Row>

              <Row>
                <Col span={12} style={s('text-sm text-grey-900')}>
                  <span>Please select the product to see the frequency options</span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={sectionStyles}>
            <Col span={24}>
              <SubTitle>SECURITY</SubTitle>

              <Row style={rowStyles}>
                <Col span={12}>
                  <Label>Associated Entities</Label>

                  <MultiSelectField
                    items={borrowerDropdownOptions}
                    name={`${fieldPrefix}.associatedBorrowers`}
                    placeholder="Select associated entities"
                    onChange={(selectedKeys) => {
                      setFieldValue(
                        `${fieldPrefix}.associatedBorrowers`,
                        selectedKeys.map((selectedEntityId) =>
                          allBorrowers.find(
                            (borrower) => borrower.id === selectedEntityId
                          )
                        )
                      )
                    }}
                    displayTextSelector={(selectedKeys) => {
                      return selectedKeys.map((selectedEntityId) => {
                        const selectedBorrower = allBorrowers.find(
                          (borrower) => borrower.id === selectedEntityId
                        )

                        return selectedBorrower?.fullName
                      })
                    }}
                    valueSelector={(values) => values?.map((value) => value?.id)}
                    variant="pill"
                  />
                </Col>

                <Col span={12}>
                  <Label>Primary Applicant</Label>
                  <MenuField
                    label="People name"
                    aria-label="entity name"
                    name={`${fieldPrefix}.primaryApplicant`}
                    displayTextSelector={(selectedEntityId) => {
                      const { fullName } =
                        allBorrowers.find(
                          (borrower) => borrower.id === selectedEntityId
                        ) || {}
                      return fullName
                    }}
                  >
                    {primaryApplicantDropdownOptions.map((option) => (
                      <Item key={option.key}>{option.label}</Item>
                    ))}
                  </MenuField>
                </Col>
              </Row>

              <Row style={rowStyles}>
                <Col span={12}>
                  <Label>Security Properties</Label>
                  <MultiSelectField
                    items={securitiesDropdownOptions}
                    placeholder="Please security properties"
                    aria-label="security name"
                    variant="pill"
                    name={`${fieldPrefix}.securityProperties`}
                    onChange={(selectedKeys) => {
                      setFieldValue(
                        `${fieldPrefix}.securityProperties`,
                        selectedKeys.map((selectedSecurityId) => {
                          // TODO: Refactor the data flow as it is very confusing that we need to change this code to make
                          // security configuration data persist on this field change
                          const foundCachedProperty =
                            loanStructureValues.securityProperties.find(
                              (security) => security.id === selectedSecurityId
                            )

                          if (foundCachedProperty) return foundCachedProperty

                          return allSecurities.find(
                            (security) => security.id === selectedSecurityId
                          )
                        })
                      )
                    }}
                    displayTextSelector={(selectedKeys) => {
                      return selectedKeys.map((selectedSecurityId) => {
                        const selectedSecurity = allSecurities.find(
                          (security) => security.id === selectedSecurityId
                        )

                        return selectedSecurity?.address
                      })
                    }}
                    valueSelector={(values) => values?.map((value) => value?.id)}
                  />
                </Col>

                <Col span={12}>
                  <Label>Security Guarantors</Label>
                  <MultiSelectField
                    items={guarantorDropdownOptions}
                    aria-label="guarantors"
                    variant="pill"
                    placeholder="Please select guarantors"
                    name={`${fieldPrefix}.guarantors`}
                    onChange={(selectedKeys) => {
                      setFieldValue(
                        `${fieldPrefix}.guarantors`,
                        selectedKeys.map((selectedEntityId) =>
                          allGuarantors.find(
                            (guarantor) => guarantor.id === selectedEntityId
                          )
                        )
                      )
                    }}
                    displayTextSelector={(selectedKeys) => {
                      return selectedKeys.map((selectedEntityId) => {
                        const selectedGuarantor = allGuarantors.find(
                          (guarantor) => guarantor.id === selectedEntityId
                        )

                        return selectedGuarantor?.fullName
                      })
                    }}
                    valueSelector={(values) => values?.map((value) => value?.id)}
                  />
                </Col>
              </Row>
            </Col>

            <Row style={s('ml-0 w-full', rowStyles)}>
              <Col span={24} style={s('flex items-center')}>
                <CheckboxField name={`${fieldPrefix}.isCrossCollateral`} />
                <Label
                  style={s('my-0', { cursor: 'pointer' })}
                  onClick={() =>
                    setFieldValue(
                      `${fieldPrefix}.isCrossCollateral`,
                      !loanStructureValues.isCrossCollateral
                    )
                  }
                >
                  Cross collateral
                </Label>
              </Col>
            </Row>

            <Row style={s('w-full ml-1', rowStyles)}>
              <Col span={24}>
                <SecurityConfiguration idx={idx} fieldPrefix={fieldPrefix} />
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      <div style={s('flex items-center justify-end mt-8')}>
        <Button variant="outlined" onClick={handleCancelBtnClicked}>
          Cancel
        </Button>
        <Button
          type="button"
          style={s('ml-4')}
          loading={isCreatingLoanSplit || isUpdatingLoanSplit}
          onClick={async () => {
            const { hasError } = await allocateLoanStructure()

            if (hasError) scrollToForm(anchorRef)
          }}
          disabled={!canAllocateLoanStructure}
        >
          Finish Loan Split
        </Button>
      </div>
    </ContentCard>
  )
}

const { string, shape, arrayOf } = PropTypes

LoanAllocationForm.propTypes = {
  allBorrowers: arrayOf(
    shape({
      id: string.isRequired,
      fullName: string.isRequired,
      legalEntityType: string.isRequired,
    })
  ),
  allGuarantors: arrayOf(
    shape({
      id: string.isRequired,
      fullName: string.isRequired,
      legalEntityType: string.isRequired,
    })
  ),
  allSecurities: arrayOf(
    shape({
      id: string.isRequired,
      value: string.isRequired,
      address: string.isRequired,
    })
  ),
}

const memorizedForm = React.memo(LoanAllocationForm)

export { memorizedForm as LoanAllocationForm }
