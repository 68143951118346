import React from 'react'
import { Col, Row } from '@horizon/styled'
import { styled, s } from '@horizon/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
} from '../components'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { Item } from '@react-stately/collections'
import { MenuField, TextField, Button } from '@horizon/components'
import { fetchLoanApplication, updateLoanApplication } from 'modules/application'

import { Formik as FormProvider, Form } from 'formik'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const { CRR } = APPLICATION_INFO_SECTION_IDS
const Container = styled.div({ marginBottom: 52 })
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const ContentCard = styled(ContentCardBase)(s('mb-4'))
const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const CreditRiskRating = ({ crr }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleSubmit = async (crrValues) => {
    const payload = {
      creditRiskRating: {
        letter: crrValues.letter,
        number: crrValues.number,
        updatedAt: new Date(),
      },
    }

    await dispatch(
      updateLoanApplication({
        id,
        payload,
      })
    )
    dispatch(fetchLoanApplication(id))
  }

  const initialValues = {
    letter: isNilOrEmpty(crr) ? null : crr.letter,
    number: isNilOrEmpty(crr) ? null : crr.number,
    result: isNilOrEmpty(crr) ? null : `${crr.letter}${crr.number}`,
  }

  return (
    <Container id={CRR}>
      <SectionTitle title="Credit Risk Rating" />

      <ContentCard>
        <FormProvider initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue, ...props }) => (
            <Form onSubmit={props.handleSubmit}>
              <Row>
                <Col span={8}>
                  <Label>Letter</Label>
                  <MenuField label="Letter" aria-label="letter" name="letter">
                    <Item key="A">A</Item>
                    <Item key="B">B</Item>
                    <Item key="C">C</Item>
                    <Item key="D">D</Item>
                    <Item key="E">E</Item>
                    <Item key="X">X</Item>
                  </MenuField>
                </Col>

                <Col span={8}>
                  <Label>Number</Label>
                  <MenuField
                    label="Number"
                    aria-label="number"
                    name="number"
                    initialValues={initialValues.number}
                  >
                    <Item key={1}>1</Item>
                    <Item key={2}>2</Item>
                    <Item key={3}>3</Item>
                    <Item key={4}>4</Item>
                    <Item key={5}>5</Item>
                  </MenuField>
                </Col>

                <Col span={8}>
                  <Label>Result</Label>
                  <TextField isReadOnly={true} name="result" />
                </Col>

                <Col span={2}>
                  <Button
                    type="submit"
                    onClick={() =>
                      setFieldValue('result', `${values.letter}${values.number}`)
                    }
                    style={s('bg-primary')}
                  >
                    Save
                  </Button>
                </Col>
                <Col span={22}>
                  <Button variant="text" type="reset">
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </FormProvider>
      </ContentCard>
    </Container>
  )
}

export { CreditRiskRating }
