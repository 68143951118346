import React from 'react'
import { PersistentDrawer, PersistentNavItem } from 'features/common'
import { ReactComponent as AccountIcon } from '@horizon/components/src/assets/images/user-circle.svg'
import { s } from '@horizon/styled/v2'
import { ReactComponent as CopyIcon } from '@horizon/components/src/assets/images/copy.svg'
import { handleSignOut } from '@horizon/components'
import { ReactComponent as ExitIcon } from '@horizon/components/src/assets/images/exit.svg'
import { routes } from './routes'

export const PersistentNavigationDrawer = () => {
  return (
    <PersistentDrawer style={s('relative z-2')}>
      <PersistentNavItem
        tooltipLabel="Application List"
        route={routes.loanApp.root}
        Icon={CopyIcon}
      />

      <PersistentNavItem
        tooltipLabel="My Account"
        route={routes.account}
        Icon={AccountIcon}
      />

      <PersistentNavItem
        tooltipLabel="Log Out"
        onClick={handleSignOut}
        Icon={ExitIcon}
        style={s('w-3 h-3 rounded-lg mt-a mb-16')}
      />
    </PersistentDrawer>
  )
}
