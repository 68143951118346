/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { round } from 'mathjs'
import { noop, all, isNotNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row, Container } from '@horizon/styled'
import { Button } from '@horizon/components'
import { ContentCard } from 'features/loanAssessment/info/components'
import { calculateRepayment } from 'modules/calculation'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { ReactComponent as EditIcon } from '@horizon/components/src/assets/images/pencil.svg'
import { formatCurrency, capitaliseFirstLetter } from 'utils/formatters'

const Title = styled.p(s('text-grey-900 font-bold'))

const Description = styled.p(s('text-grey-900'))

const InfoBox = ({ label, text, ...otherProps }) => {
  return (
    <div style={s('bg-secondary rounded-md p-8')} {...otherProps}>
      <p style={s('m-0 mb-2 text-grey-600')}>{label}</p>
      <p style={s('m-0 text-2xl text-grey-900 font-bold')}>{text}</p>
    </div>
  )
}

const Label = styled.span(s('text-grey-600'))
const Value = styled.span(s('text-grey-900 font-bold'))

const ViewDetails = ({
  primaryApplicant,
  associatedBorrowers = [],
  guarantors = [],
}) => {
  const [isExpanded, setExpanded] = useState(false)

  const entities = [...associatedBorrowers, ...guarantors]

  return (
    <div style={s('bg-primary-flat-white px-6 py-5')}>
      {isExpanded ? (
        <Container fluid>
          <Row style={s('mb-5')} gutter={[0, 100]}>
            <Col style={s('mb-4')}>
              <Row style={s('mb-2')}>
                <Col>
                  <Label>Primary Applicant</Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Value>{primaryApplicant || '-'}</Value>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row style={s('mb-2')}>
                <Col>
                  <Label>Entities</Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Value>{entities.map((e) => e?.fullName).join(', ')}</Value>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="center">
            <Button
              onClick={() => setExpanded(false)}
              variant="text"
              EndIcon={<ChevronUp width={18} height={20} style={s('ml-1')} />}
            >
              Collapse
            </Button>
          </Row>
        </Container>
      ) : (
        <Container style={s('flex justify-center')}>
          <Button
            onClick={() => setExpanded(true)}
            variant="text"
            EndIcon={<ChevronDown width={18} height={20} style={s('ml-1')} />}
          >
            View Details
          </Button>
        </Container>
      )}
    </div>
  )
}

const recurrenceMap = {
  weekly: 'week',
  fortnightly: 'fortnight',
  monthly: 'month',
}

const getRepaymentDay = (
  repaymentFrequency,
  dayOfTheMonth,
  dayOfTheWeek,
  dayOfTheFortnight
) => {
  switch (repaymentFrequency) {
    case 'weekly':
      return dayOfTheWeek
    case 'fortnightly':
      return dayOfTheFortnight
    default:
      return dayOfTheMonth
  }
}

const getPrimaryApplicantFullName = (primaryApplicantId, allBorrowers = []) => {
  const matchedBorrower = allBorrowers.find(
    (borrower) => borrower.entityId === primaryApplicantId
  )

  return matchedBorrower?.fullName
}

export const AllocatedLoanStructureBox = ({
  product,
  loanTermInYears,
  loanAmount,
  repaymentFrequency,
  dayOfTheMonth,
  dayOfTheWeek,
  dayOfTheFortnight,
  loading,
  totalSecurities,
  totalInterestRate,
  primaryApplicant,
  associatedBorrowers,
  allBorrowers,
  guarantors,
  loanStructureNumber = 0,
  editAllocatedLoanStructure = noop,
  ...otherProps
}) => {
  const [periodicRepaymentAmount, setPeriodicRepaymentAmount] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    const getRepaymentAmount = async () => {
      const productId = product?.id

      if (
        all(isNotNilOrEmpty, [
          productId,
          loanAmount,
          loanTermInYears,
          repaymentFrequency,
          loading || 0,
        ])
      ) {
        const result = await dispatch(
          calculateRepayment({
            productId,
            loanAmount,
            loanTermInYears,
            repaymentRecurrence: repaymentFrequency,
            loading,
          })
        )

        const periodicRepayment = result.payload?.periodicRepayment

        setPeriodicRepaymentAmount(
          periodicRepayment ? round(periodicRepayment || 0, 2) : periodicRepayment
        )
      }
    }

    getRepaymentAmount()
  }, [dispatch, loading, loanAmount, loanTermInYears, product.id, repaymentFrequency])

  return (
    <ContentCard {...otherProps}>
      <Row>
        <Col span={24}>
          <Title>Loan Split {loanStructureNumber}</Title>
        </Col>

        <Col span={24}>
          <Description>
            Product: <strong>{product?.name || 'No product selected'}</strong> ,{' '}
            <strong>{loanTermInYears || '()'} years</strong> with{' '}
            <strong>
              {totalInterestRate ? round(totalInterestRate, 2) : '-'}% interest rate
            </strong>{' '}
            due on the{' '}
            <strong>
              {getRepaymentDay(
                repaymentFrequency,
                dayOfTheMonth,
                dayOfTheWeek,
                dayOfTheFortnight
              )}{' '}
              of each {recurrenceMap[repaymentFrequency]}
            </strong>{' '}
            for <strong>{associatedBorrowers.length || 0} borrower(s)</strong> and{' '}
            <strong>{guarantors.length || 0} guarantor(s)</strong>
          </Description>
        </Col>

        <Col span={24}>
          <ViewDetails
            primaryApplicant={getPrimaryApplicantFullName(
              primaryApplicant,
              allBorrowers
            )}
            associatedBorrowers={associatedBorrowers}
            guarantors={guarantors}
          />
        </Col>

        <Col span={24} style={s('my-6')}>
          <div style={s('border-solid border-0 border-t-2 border-grey-200')} />
        </Col>

        <Col span={24} style={s('mb-4')}>
          <Row>
            <Col span={6}>
              <InfoBox label={'Total Loan Amount'} text={formatCurrency(loanAmount)} />
            </Col>

            <Col span={6}>
              <InfoBox
                label={`${capitaliseFirstLetter(repaymentFrequency)} Repayment`}
                text={formatCurrency(periodicRepaymentAmount, 2)}
              />
            </Col>

            <Col span={6}>
              <InfoBox
                label={'Interest rate'}
                text={`${totalInterestRate ? round(totalInterestRate, 2) : '-'}%`}
              />
            </Col>

            <Col span={6}>
              <InfoBox label={'Total Securities'} text={totalSecurities || '-'} />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={s('flex justify-end')}>
          <Button
            type="button"
            variant="outlined"
            onClick={editAllocatedLoanStructure}
            StartIcon={<EditIcon style={s('mr-2')} />}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </ContentCard>
  )
}
