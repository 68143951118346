/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { ReactComponent as DownloadButtonIcon } from '@horizon/components/src/assets/images/download.svg'
import { ReactComponent as PaperClipIconBase } from '@horizon/components/src/assets/images/paper-clip.svg'

const Container = styled.div(s('flex justify-between'))
const DocumentInfoContainer = styled.div(s('flex'))
const DownloadButton = styled.button(
  s(
    'bg-white border-1 border-solid border-primary text-primary text-sm p-1 rounded-lg font-semibold w-2 h-2',
    {
      cursor: 'pointer',
      letterSpacing: '0.01em',
      lineHeight: 1.5,
    }
  )
)
const FileLabel = styled.p(
  s('m-0 text-grey-900 text-sm font-semibold mb-4', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const FileName = styled.p(
  s('m-0 text-grey-800 text-sm font-normal', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const PaperClipIcon = styled(PaperClipIconBase)(s('text-grey-700'))
const PaperClipIconContainer = styled.div(s('bg-grey-100 mr-4 rounded-lg w-2 h-2 p-1'))

// adds spaces before capital letters in a joint string
// also caters for acronymns
// e.g. SupportingDocument => Supporting Document
// e.g. LetterFromWINZ => Letter From WINZ
const insertSpaces = (classification) => {
  const stringWithSpaces = classification.replace(/([a-z])([A-Z])/g, '$1 $2')
  return stringWithSpaces.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
}

const SupportingDoc = ({ document = {}, ...props }) => {
  const { classification, fileKey, presignedUrl } = document

  return (
    <Container {...props}>
      <DocumentInfoContainer>
        <PaperClipIconContainer>
          <PaperClipIcon />
        </PaperClipIconContainer>

        <div>
          <FileLabel>{insertSpaces(classification) ?? '-'}</FileLabel>
          <FileName>{fileKey ?? '-'}</FileName>
        </div>
      </DocumentInfoContainer>

      <a href={presignedUrl} target="_blank">
        <DownloadButton>
          <DownloadButtonIcon />
        </DownloadButton>
      </a>
    </Container>
  )
}

export { SupportingDoc }
