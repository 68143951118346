import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@horizon/services'

import { lendingEntityService, loanApplicationService } from 'apiService'
import { thunkErrorProcessor } from '@horizon/error-standardizer'
import { mapLoanApplication, mapLegalEntity } from './loanApplicationMappers'

export const fetchLoanApplication = createAsyncThunk(
  'applications/getApplication',
  async (id, { rejectWithValue }) => {
    try {
      const loanApplication = await loanApplicationService.getApplication(id)

      return mapLoanApplication(loanApplication)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateLoanApplication = createAsyncThunk(
  'application/updateLoanApplication',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await loanApplicationService.updateLoanApplication(id, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

/**
 * @type {import('./types').FetchApplicationsThunk}
 */
export const fetchLoanApplications = createAsyncThunk(
  'applications/getLoanApplications',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } = searchParams
      return await loanApplicationService.getLoanApplications(
        {
          q,
          filters,
          limit,
          sorting,
          start: limit * pageIndex,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchLoanApplicationEntities = createAsyncThunk(
  'applications/fetchLoanApplicationEntities',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      const legalEntities = await lendingEntityService.listLegalEntities(
        loanApplicationId
      )

      return {
        loanApplicationId,
        legalEntities: {
          individuals: legalEntities
            .filter((e) => e.legalEntityType === 'individual')
            .map(mapLegalEntity),
          trusts: legalEntities
            .filter((e) => e.legalEntityType === 'trust')
            .map(mapLegalEntity),
          companies: legalEntities
            .filter((e) => e.legalEntityType === 'company')
            .map(mapLegalEntity),
        },
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateLoanApplicationStatus = createAsyncThunk(
  'application/updateLoanApplicationStatus',
  async ({ loanApplicationId, status }, { rejectWithValue }) => {
    try {
      return await loanApplicationService.updateStatus(loanApplicationId, status)
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

/**
 * @type {import('./types').ApplicationEntityAdapter}
 */
export const applicationAdapter = createEntityAdapter()

/**
 * @type {import('./types').ApplicationEntityState}
 */
const initialState = applicationAdapter.getInitialState()

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanApplication.fulfilled, (state, action) => {
        applicationAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchLoanApplicationEntities.fulfilled, (state, action) => {
        const { loanApplicationId, legalEntities = {} } = action.payload || {}

        applicationAdapter.updateOne(state, {
          id: loanApplicationId,
          changes: {
            legalEntities,
          },
        })
      })
      .addCase(fetchLoanApplications.fulfilled, (state, action) => {
        const { items: loanApplications, pagination } = action.payload
        applicationAdapter.setAll(state, loanApplications)
        state.total = pagination.total
      })
      .addCase(updateLoanApplicationStatus.fulfilled, (state, action) => {
        applicationAdapter.upsertOne(state, action.payload)
      })
  },
})

const { reducer: applicationReducer } = applicationSlice

export { applicationReducer }
