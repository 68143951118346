import React, { useState } from 'react'
import { s, styled } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { formatDateString } from 'utils/date'
import { useSelector } from 'react-redux'
import { selectProfileId } from '@horizon/redux.profile'
import { Dropdown, DropdownItem } from '@horizon/components'
import { EditDisplayNoteForm } from './EditDisplayNoteForm'
import { DeleteNoteModel } from './DeleteNoteModel'
import { ProfileIcon } from './ProfileIcon'

const NameContainer = styled.div(s('font-semibold p-0 my-3  '))
const DateContainer = styled.div(s('text-grey-600 p-0 my-3 ml-2'))
const ToggleButton = styled.div(
  s('text-2xl self-center border-transparent bg-transparent text-right')
)

export const DisplayedNote = ({ note }) => {
  const loggedInUserProfileId = useSelector(selectProfileId)

  const isSameProfile = (noteProfileId) => {
    return noteProfileId === loggedInUserProfileId
  }
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  const handleEditCancel = () => {
    setIsEditing(false)
  }

  return (
    <Row gutter={[0, 0]}>
      <Col span={1}>
        <div style={s('flex flex-row justify-end mt-4')}>
          <ProfileIcon fullName={note?.profileName} />
        </div>
      </Col>

      <Col span={19}>
        <div style={s('flex flex-column m-0 p-0')}>
          <div style={s('flex flex-row m-0 p-0')}>
            <NameContainer>{note?.profileName}</NameContainer>

            <DateContainer>{`· ${formatDateString(
              new Date(note?.createdAt),
              'h:mma dd/MM/yyy'
            )}`}</DateContainer>
          </div>
        </div>
      </Col>

      <Col span={4}>
        <div style={s('flex flex-row justify-end w-full')}>
          {isSameProfile(note?.profileId) && (
            <Dropdown
              style={{
                cursor: 'pointer',
              }}
              dropdownMenuProps={{
                style: {
                  top: 10,
                  right: 20,
                  zIndex: 3,
                  cursor: 'pointer',
                },
              }}
              Toggler={({ toggle }) => (
                <ToggleButton onClick={toggle}>...</ToggleButton>
              )}
            >
              <DropdownItem text={'Edit'} onClick={() => setIsEditing(true)} />

              <DropdownItem
                text={'Delete'}
                onClick={() => setIsDeleteModalOpened(true)}
              />
            </Dropdown>
          )}
        </div>
      </Col>
      <Col span={1} />

      <Col span={23}>
        <div style={s('mt-0')}>
          {isEditing ? (
            <EditDisplayNoteForm note={note} handleEditCancel={handleEditCancel} />
          ) : (
            <div style={s('pt-0 mb-5 h-auto', { whiteSpace: 'pre-line' })}>
              {note?.content || ''}
            </div>
          )}
        </div>
      </Col>

      <DeleteNoteModel
        isOpen={isDeleteModalOpened}
        setIsOpen={setIsDeleteModalOpened}
        note={note}
      />
    </Row>
  )
}
