import React, { useState } from 'react'
import { isEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import {
  ContentCard as ContentCardBase,
  Divider as DividerBase,
  SectionTitle as SectionTitleBase,
} from '../components'
import { SupportingDoc } from './SupportingDoc'
import { UploadSupportingDoc } from './UploadSupportingDoc'

const { SUPPORTING_DOCS } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const Divider = styled(DividerBase)(s('my-8'))
const FileSection = styled.div()
const LabelHeader = styled.div(s('flex justify-between mb-8'))
const SectionLabel = styled.p(
  s('m-0 mb-8 text-grey-900 text-sm font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const UploadButton = styled.button(
  s(
    'bg-white border-1 border-solid border-primary text-primary text-sm px-4 py-2 rounded-lg font-semibold',
    {
      cursor: 'pointer',
      letterSpacing: '0.01em',
      lineHeight: 1.5,
    }
  )
)

const SupportingDocs = ({ documents = [], ...props }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const openModal = () => setIsModalOpened(true)
  const closeModal = () => setIsModalOpened(false)

  const illionDocuments = documents.filter(({ source }) => source === 'illion')
  const internalDocuments = documents.filter(
    ({ references, source }) => isEmpty(references) && source === 'internal'
  )
  const myCrmDocuments = documents.filter(({ source }) => source === 'mycrm')

  return (
    <>
      <Container id={SUPPORTING_DOCS} {...props}>
        <SectionTitle title="Supporting Docs" />

        <ContentCard>
          <FileSection>
            <LabelHeader>
              <SectionLabel style={s('mb-0')}>Internal Files</SectionLabel>
              <UploadButton onClick={openModal}>Upload</UploadButton>
            </LabelHeader>

            <Row gutter={[24, 96]}>
              {internalDocuments.map((document, index) => (
                <Col key={index} span={12}>
                  <SupportingDoc document={document} />
                </Col>
              ))}
            </Row>
          </FileSection>

          <Divider />
          <FileSection>
            <SectionLabel>MyCRM Files</SectionLabel>

            <Row gutter={[24, 96]}>
              {myCrmDocuments.map((document, index) => (
                <Col key={index} span={12}>
                  <SupportingDoc document={document} />
                </Col>
              ))}
            </Row>
          </FileSection>

          {isNotNilOrEmpty(illionDocuments) && (
            <div>
              <Divider />
              <FileSection>
                <SectionLabel>illion Files</SectionLabel>

                <Row gutter={[24, 96]}>
                  {illionDocuments.map((document, index) => (
                    <Col key={index} span={12}>
                      <SupportingDoc document={document} />
                    </Col>
                  ))}
                </Row>
              </FileSection>
            </div>
          )}
        </ContentCard>
      </Container>

      <UploadSupportingDoc closeModal={closeModal} isOpen={isModalOpened} />
    </>
  )
}

export { SupportingDocs }
