import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { s, styled } from '@horizon/styled/v2'
import { ApplicationList } from 'features/loanApplication'
import { routes } from './routes'
import { LoanAssessmentRouter } from './LoanAssessmentRouter'

const Root = styled.div(s('flex flex-column h-full bg-primary-flat-white flex-1'))

export const LoanAppRouter = () => {
  return (
    <Root>
      <Switch>
        <Route exact path={routes.loanApp.root} component={ApplicationList} />
        <Route path={routes.loanApp.assessment.root} component={LoanAssessmentRouter} />
        <Redirect to={routes.loanApp.root} />
      </Switch>
    </Root>
  )
}
