import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { prop } from '@solta/ramda-extra'
import { PieChart as PieChartBase, DottedCircleLoader } from '@horizon/components'
import { ChartTooltip } from './ChartToolTip'

const Container = styled.div(
  s('flex justify-between items-center w-1/3 bg-white rounded-lg  pt-5', {
    height: 292,
  })
)
const Title = styled.h2(s('m-0 text-grey-800 text-xl font-medium'))
const CategoryList = styled.ul(
  s('m-0 p-0 w-1/3', {
    listStyle: 'none',
    transform: 'translate(1rem, 3rem)',
  }),
  prop('categoryListStyle')
)
const CategoryItem = styled.li(
  s('inline-block items-center', {
    minWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
)
const Color = styled.div(s('inline-block rounded-sm mr-1'), ({ bgColor }) => ({
  background: bgColor,
  height: 12,
  width: 12,
  transform: 'translateY(2px)',
}))
const CategoryText = styled.span(s('text-grey-600 text-xs'))
const Column = styled.div(s('flex flex-column justify-center items-center'))

const PieChart = ({
  chartData = [{ label: 'No Allocation', frequency: 0, color: 'rgba(228,230,230,1)' }],
  title,
  isLoading,
  categoryListStyle,
  ...otherProps
}) => {
  const pieChartSizeInPixels = 230

  if (isLoading)
    return (
      <Container style={s('flex items-center justify-center')}>
        <DottedCircleLoader size="md" />
      </Container>
    )

  return (
    <Container {...otherProps}>
      <Column>
        <Title style={s('ml-6')}>{title}</Title>
        <PieChartBase
          width={pieChartSizeInPixels}
          height={pieChartSizeInPixels}
          data={chartData}
          showPieLabel={false}
          pieStyle={{ cursor: 'pointer' }}
          tooltipContainerStyle={s('p-0')}
          tooltipOffsetY={16}
          tooltipOffsetX={-18}
          TooltipChildren={ChartTooltip}
        />
      </Column>

      <Column style={s('h-full justify-start')}>
        <CategoryList categoryListStyle={categoryListStyle}>
          {chartData.map(({ label, frequency, color }) => (
            <CategoryItem key={`${label}-${frequency}-${color}`} style={s('mb-1')}>
              <Color bgColor={color} />
              <CategoryText>{`${label} ${frequency}%`}</CategoryText>
            </CategoryItem>
          ))}
        </CategoryList>
      </Column>
    </Container>
  )
}

export { PieChart }
