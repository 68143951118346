import React from 'react'
import { Col, Row } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import { ContentCard, DataDisplay } from '../info/components'
import { formatDateString } from 'utils/formatters'

export const AdverseChange = ({ change = {}, ...props }) => {
  const {
    impactEndDate,
    impactStartDate,
    mitigant: { factor, notes = '-' },
    natureOfChange,
  } = change

  return (
    <ContentCard {...props}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <DataDisplay label="Nature of Change" value={natureOfChange} />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={s('mt-4')}>
        <Col span={12}>
          <DataDisplay
            label="Impact Start Date"
            value={formatDateString(impactStartDate)}
          />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Impact End Date"
            value={formatDateString(impactEndDate)}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={s('mt-4')}>
        <Col span={12}>
          <DataDisplay label="Mitigant" value={factor} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Additional Notes" value={notes} />
        </Col>
      </Row>
    </ContentCard>
  )
}
