import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { formatCurrency } from 'utils/formatters'
import { SecurityCard } from './SecurityCard'
import { styled, s } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { Loading, Button } from '@horizon/components'
import { isNotNilOrEmpty, isNilOrEmpty } from '@solta/ramda-extra'
import { NoteSection } from '../../common/NoteSection'
import { LinkValocityModal } from './LinkValocityModal'
import { TEST_IDS } from '@horizon/constants'

import {
  DataDisplay,
  SectionTitle,
  SectionTitle as SectionTitleBase,
} from '@horizon/components/src/v2'

import { ReactComponent as ChevronLeft } from '@horizon/components/src/assets/images/chevron-left.svg'

import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/application'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

const getCustomEstimateValue = (customSecurityValuations = [], securityId) => {
  const desiredValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )
  return desiredValuation?.value
}
const mapSecurityProperties = (securities = [], customSecurityValuations = []) => {
  return securities.map((security) => {
    const { id, linkedDetails = {} } = security
    const customEstimateValue = getCustomEstimateValue(customSecurityValuations, id)

    return {
      id,
      value: isNotNilOrEmpty(customEstimateValue)
        ? customEstimateValue
        : linkedDetails?.valuation?.estimatedValue,
    }
  })
}
const getTotalEstimatedSecurityValue = (securities) => {
  return securities
    ?.map((security) =>
      isNilOrEmpty(security?.linkedDetails)
        ? security?.estimatedValue?.value
        : security?.linkedDetails?.valuation?.estimatedValue
    )
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
}

const getTotalFinalAssetValue = (securities) => {
  return securities
    ?.map((security) => security?.value)
    .reduce((previousValue, currentValue) => previousValue + (currentValue || 0), 0)
}

export const Security = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [modalSecurity, setModalSecurity] = useState()

  const application = useSelector(selectApplicationById(id))

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const { securities = [], customSecurityValuations = [] } = application || []

  const mappedAllSecurityProperties = mapSecurityProperties(
    securities,
    customSecurityValuations
  )

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  if (isFetchingApplication) return <Loading />

  return (
    <div style={s('ml-8')}>
      <BackButton
        variant="text"
        style={s('mb-8 ml-24')}
        onClick={() => history.push(`/loan-applications/${id}/financial`)}
      >
        <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>
      <Row style={s('px-24')} gutter={[0, 10]}>
        <Col span={24}>
          <h1 style={s('font-semibold mb-10')} className="sohne">
            Securities Info
          </h1>
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Securities"
            value={securities?.length}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_SECURITIES}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Properties"
            value={securities?.length}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_PROPERTIES}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Guarantors"
            value="-"
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_GUARNATORS}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Declared Security Value"
            value={formatCurrency(getTotalEstimatedSecurityValue(securities))}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_DECLARED}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Assessed Security Value"
            value={formatCurrency(getTotalFinalAssetValue(mappedAllSecurityProperties))}
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
            testId={TEST_IDS.TOTAL_ASSESSED}
          />
        </Col>

        <Col span={4}>
          <DataDisplay
            label="Total Guarantor Value"
            value="-"
            valueStyle={s('mt-1 text-primary font-semibold text-xl')}
          />
        </Col>

        <Col span={24}>
          <SectionTitle title="Property" style={s('mt-9 mb-3')} />
          {securities?.map((security, index) => (
            <SecurityCard
              security={security}
              loanApplicationId={id}
              customSecurityValuations={application?.customSecurityValuations}
              legalEntities={application?.legalEntities}
              key={security?.id}
              setIsModalOpened={setIsModalOpened}
              setModalSecurity={setModalSecurity}
              index={index}
            />
          ))}
        </Col>
        <Col span={24}>
          <NoteSection
            id="note-section"
            loanApplicationId={id}
            source="loan-applications/security"
          />
        </Col>
      </Row>

      <LinkValocityModal
        isOpen={isModalOpened}
        setIsOpen={setIsModalOpened}
        security={modalSecurity}
      />
    </div>
  )
}
