import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class FinancialService extends BaseService {
  readByLoanId(loanApplicationId) {
    const queryParams = QueryBuilder()
      .setParam('loanApplicationId', loanApplicationId)
      .build()

    return this.client.get(`financials?${queryParams}`).json()
  }

  updateFinance(id, payload) {
    const config = {
      json: payload,
    }

    return this.client.put(`financials/${id}`, config).json()
  }
}
