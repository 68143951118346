import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { Modal } from '@horizon/components'
import { ReactComponent as XIconBase } from '@horizon/components/src/assets/images/x.svg'
import neoLogo from '@horizon/components/src/assets/images/neo-logo.png'
import { formatDateString } from 'utils/date'
import {
  capitaliseFirstLetter,
  buildAddress,
  capitaliseFirstLetterOfAllWords,
} from 'utils/formatters'
import { buildEntityName } from 'utils/entity'
import { CreditCheckDataDisplay as CreditCheckDataDisplayBase } from './CreditCheckDataDisplay'
import { mapSummaryItem } from './mappers'

const Container = styled.div(
  s('bg-white h-full p-4', {
    height: '100vh',
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    width: '100vw',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  })
)
const CreditCheckDataDisplay = styled(CreditCheckDataDisplayBase)(s('mb-2'))
const Header = styled.div(s('mb-8 pin-t-0 sticky'))
const Label = styled.p(
  s('font-normal m-0 text-base text-grey-700', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const NeoLogo = styled.img(s('w-6'))
const SectionSubtitle = styled.p(
  s('font-semibold mb-2 mt-4 text-xs text-primary', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)
const SectionTitle = styled.p(
  s('font-semibold mb-4 mt-8 text-lg text-primary', {
    letterSpacing: '-0.01em',
    lineHeight: 1.5,
  })
)
const Title = styled.h1(
  s('mb-2 mt-0 text-primary text-3xl', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)
const XIcon = styled(XIconBase)(s('text-primary', { cursor: 'pointer' }))

export const CreditCheckFullDetails = ({
  closeModal,
  creditReport = {},
  isOpen,
  ...props
}) => {
  const {
    creditEnquiries,
    creditScore: { relativeRisk, relativeRiskIndex, riskOdds, scorecardName },
    negativeBlock,
    profile: { addresses, dob, firstName, gender, identityVerification, lastName },
    summary,
  } = creditReport

  const currentAddress = addresses.find(({ type }) => type === 'current')

  const enquiryDate = formatDateString(new Date(creditEnquiries[0]?.createDate))

  const fullName = capitaliseFirstLetterOfAllWords(
    buildEntityName({ firstName, lastName })
  )

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Container {...props}>
        <Header>
          <Row>
            <Col span={2}>
              <XIcon onClick={closeModal} />
            </Col>
            <Col span={2}>
              <NeoLogo src={neoLogo} />
            </Col>
          </Row>
        </Header>

        <Row justify="center">
          <Col span={12}>
            <Title>Credit Check - Equifax Results</Title>
            <Label>Date: {enquiryDate}</Label>

            <SectionTitle style={{ marginTop: 52 }}>General Details</SectionTitle>

            <CreditCheckDataDisplay label="Full Name" value={fullName} />
            <CreditCheckDataDisplay
              label="Gender"
              value={capitaliseFirstLetter(gender)}
            />
            <CreditCheckDataDisplay
              label="Date of Birth"
              value={formatDateString(dob)}
            />
            <CreditCheckDataDisplay
              label="Current Address"
              value={buildAddress(
                {
                  streetName: currentAddress?.streetName,
                  streetNumber: currentAddress?.streetNumber,
                },
                currentAddress?.suburb,
                currentAddress?.city
              )}
            />
            <CreditCheckDataDisplay
              label="Identity Verification"
              value={identityVerification === 'Y' ? 'Yes' : 'No'}
            />

            <SectionTitle>Credit Score</SectionTitle>

            <CreditCheckDataDisplay label="Score Card Name" value={scorecardName} />
            <CreditCheckDataDisplay
              label="Credit Score"
              value={riskOdds}
              valueStyles={s('font-semibold')}
            />
            <CreditCheckDataDisplay label="Relative Risk" value={relativeRisk} />
            <CreditCheckDataDisplay
              label="Relative Risk Index"
              value={relativeRiskIndex}
            />

            <SectionTitle>Credit Enquiries</SectionTitle>

            {creditEnquiries.map((enquiry, index) => {
              const {
                createDate,
                enquiryAmount,
                creditEnquirer,
                firstGivenName,
                clientReference,
              } = enquiry

              return (
                <>
                  <SectionSubtitle>REQUEST {index + 1}</SectionSubtitle>

                  <CreditCheckDataDisplay
                    label="Date"
                    value={formatDateString(new Date(createDate))}
                  />
                  <CreditCheckDataDisplay
                    label="Enquiry Amount"
                    value={`$${enquiryAmount}`}
                  />
                  <CreditCheckDataDisplay
                    label="Credit Enquirer"
                    value={capitaliseFirstLetterOfAllWords(creditEnquirer)}
                  />
                  <CreditCheckDataDisplay
                    label="First Name"
                    value={capitaliseFirstLetterOfAllWords(firstGivenName)}
                  />
                  <CreditCheckDataDisplay
                    label="Client Reference"
                    value={clientReference}
                  />
                </>
              )
            })}

            <SectionTitle>All Addresses</SectionTitle>

            {addresses.map((address, index) => (
              <>
                <SectionSubtitle>ADDRESS {index + 1}</SectionSubtitle>

                <CreditCheckDataDisplay
                  label="State"
                  value={capitaliseFirstLetter(address.type)}
                />
                <CreditCheckDataDisplay
                  label="Full Address"
                  value={buildAddress(
                    {
                      streetName: address?.streetName,
                      streetNumber: address?.streetNumber,
                    },
                    address?.suburb,
                    address?.city
                  )}
                />
              </>
            ))}

            <SectionTitle>Summary Data</SectionTitle>

            {summary.map((item, index) => {
              const { name, type, value } = mapSummaryItem(item)

              return (
                <CreditCheckDataDisplay
                  key={index}
                  label={name}
                  value={`${value} ${type}`}
                />
              )
            })}

            <SectionTitle>Negative Block</SectionTitle>

            {negativeBlock.map((item, index) => {
              const { name, type, value } = mapSummaryItem(item)

              return (
                <CreditCheckDataDisplay
                  key={index}
                  label={name}
                  value={`${value} ${type}`}
                />
              )
            })}
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
