import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { AddressInfo } from './AddressInfo'
import { isNilOrEmpty } from '@solta/ramda-extra'

const Container = styled.div()
const Title = styled.div(
  s('mb-6 text-lg font-semibold', {
    color: '#141616',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const Addresses = ({ addresses = [], applicantIndex, ...props }) => {
  const previousAddresses = addresses.filter((address) => {
    return address.endDate !== 'Present'
  })
  const currentAddresses = addresses.filter((address) => {
    return address.endDate === 'Present'
  })
  if (isNilOrEmpty(previousAddresses)) {
    return (
      <Container {...props}>
        <Title>Addresses</Title>
        {currentAddresses?.map(
          (
            { address, duration, endDate, startDate, status, type },
            index,
            { length }
          ) => {
            const lastAddress = index === length - 1

            if (address === '-') return null
            return (
              <AddressInfo
                key={index}
                address={address}
                duration={duration}
                endDate={endDate}
                index={index}
                startDate={startDate}
                status={status}
                style={s(lastAddress ? '' : 'mb-6')}
                type={type}
                applicantIndex={applicantIndex}
              />
            )
          }
        )}
      </Container>
    )
  }
  return (
    <Container {...props}>
      <Title>Addresses</Title>
      {currentAddresses?.map(
        (
          { address, duration, endDate, startDate, status, type },
          index,
          { length }
        ) => {
          const lastAddress = index === length - 1

          if (address === '-') return null
          return (
            <AddressInfo
              key={index}
              address={address}
              duration={duration}
              endDate={endDate}
              index={index}
              startDate={startDate}
              status={status}
              style={s(lastAddress ? '' : 'mb-6')}
              type={type}
            />
          )
        }
      )}
      <Title>Previous Addresses</Title>
      {previousAddresses?.map(
        (
          { address, duration, endDate, startDate, status, type },
          index,
          { length }
        ) => {
          const lastAddress = index === length - 1

          if (address === '-') return null
          return (
            <AddressInfo
              key={index}
              address={address}
              duration={duration}
              endDate={endDate}
              index={index}
              startDate={startDate}
              status={status}
              style={s(lastAddress ? '' : 'mb-6')}
              type={type}
            />
          )
        }
      )}
    </Container>
  )
}

export { Addresses }
