import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { formatOwnershipString } from './formatOwnershipString'
import { formatBoolean, formatCurrency, buildAddress } from 'utils/formatters'

const InfoSegment = styled.div(s('flex flex-column mb-4 mt-4'), {
  borderBottom: '1px solid #E4E6E6',
})

export const RealAssetOverview = ({
  address = '-',
  entities = [],
  ownerships = [],
  domainType = '-',
  estimatedValue = '-',
  estimatedBasis = '-',
  toBeSold = '-',
  toBeUsedAsSecurity = '-',
  transactionType = '-',
  ...props
}) => {
  return (
    <ContentCard style={{ minHeight: 432 }} {...props}>
      <Row gutter={[20, 16]}>
        <Col span={24}>
          <DataDisplay label="Asset Type" value={domainType} />
        </Col>

        <Col span={24}>
          <DataDisplay
            label="Address"
            value={buildAddress(
              {
                streetName: address?.standard?.streetName,
                streetNumber: address?.standard?.streetNumber,
                streetType: address?.standard?.streetType,
              },
              address?.suburb,
              address?.city,
              address?.countryISO,
              address?.standard?.unit,
              address?.newZealandPostCode
            )}
          />
        </Col>
      </Row>

      <InfoSegment />

      <Row gutter={[20, 16]}>
        <Col span={12}>
          <DataDisplay label="EST Value" value={formatCurrency(estimatedValue)} />
        </Col>

        <Col span={12}>
          <DataDisplay label="EST Basis" value={estimatedBasis} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Used As Security"
            value={formatBoolean(toBeUsedAsSecurity)}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="To Be Sold" value={formatBoolean(toBeSold)} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Ownership"
            value={formatOwnershipString(entities, ownerships)}
          />
        </Col>
        <Col span={12}>
          <DataDisplay label="Transaction Type" value={transactionType} />
        </Col>
      </Row>
    </ContentCard>
  )
}
