import { createSelector } from '@reduxjs/toolkit'
import { prop } from '@solta/ramda-extra'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { financialAdapter } from './module'

export const {
  selectIsPending: selectIsFetchLoanFinancialsPending,
  selectHasFulfilled: selectHasFetchLoanFinancialsFulfilled,
  selectError: selectFetchLoanFinancialsError,
} = createAsyncThunkSelectors('financials/fetchLoanFinancials')

export const {
  selectAll: selectAllLoanFinancials,
  selectById,
} = financialAdapter.getSelectors(
  /**
   * @param {import('../../store').RootState} s
   */
  (s) => s.financial
)

export const selectIndividualEntityEmployments = createSelector(
  prop('financial'),
  prop('individualEntityEmployments')
)
export const selectHouseholds = createSelector(prop('financial'), prop('households'))
