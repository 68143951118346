import React, { useState } from 'react'
import { styled, s } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateLoanApplication,
  selectIsFetchApplicationPending,
  fetchLoanApplication,
  selectIsUpdatingApplication,
} from 'modules/application'
import { isNilOrEmpty, isNotNilOrEmpty } from '@solta/ramda-extra'
import { Formik as FormProvider, Form } from 'formik'
import { ContentCard, SectionTitle } from '../info/components'
import * as Validator from 'yup'
import {
  MultiSelectField,
  DateField,
  toast,
  Button,
  Loading,
} from '@horizon/components'
import { useParams } from 'react-router-dom'
import { formatDateString, parseDate } from 'utils/date'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { LoanPreview } from './LoanPreview'

const CancelButton = styled.button(
  s('border-transparent font-semibold text-grey-600 py-2 px-4 mr-2 text-sm'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)
const SaveButton = styled(Button)(s('bg-primary text-white'))

const TogglePreviewButton = styled.button(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center p-1 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  )
)
const ChevronDownIcon = styled(ChevronDown)(
  s('ml-1', {
    cursor: 'pointer',
    path: { stroke: '#490B2D'.color },
  })
)
const ChevronUpIcon = styled(ChevronUp)(
  s('ml-1', {
    cursor: 'pointer',
    path: { stroke: '#490B2D'.color },
  })
)

const { array, string, object } = Validator
const validationSchema = object({
  conditionsPrecedent: string().optional(),
  expiredDate: string().required('This is a required field'),
  netLoanAdvance: string().optional(),
  specialConditions: string().optional(),
  types: array().of(string()).min(1, 'This is a required field'),
})

const offerConditionTypes = [
  'Building Report',
  'Confirmation of Gift',
  'Confirmation of Income',
  'Confirmation of Boarder Income',
  'Confirmation of Other Debts',
  'Signed Sale & Purchase Agreement',
  'IRD/IR3/IR4 Statements',
  'Rental Appraisal/Assessment',
  'Signed Rental Agreement',
  'Suitable confirmation of deposit',
  'Registered valuation',
  'Quotation',
  'Financials',
  'Other',
]

// eslint-disable-next-line complexity
const ConditionalOfferForm = ({ loanApplication, loanSplits = [], products = [] }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const isUpdatingApplication = useSelector(selectIsUpdatingApplication)

  const { offers = {} } = loanApplication

  const [isShowingPreview, setIsShowingPreview] = useState(false)

  const currentValue = offers?.conditional

  const handleSubmit = async (
    types,
    expiredDateString,
    conditionsPrecedent,
    netLoanAdvance,
    specialConditions
  ) => {
    const expiredDate = parseDate(expiredDateString)

    const { error } = await dispatch(
      updateLoanApplication({
        id,
        payload: {
          offers: {
            conditional: {
              types,
              expiredDate,
              conditions: {
                conditionPrecedent: conditionsPrecedent,
                specialCondition: specialConditions,
                netLoanAdvanceToSolicitor: netLoanAdvance,
              },
            },
          },
        },
      })
    )

    if (isNilOrEmpty(error)) {
      toast('Offer Updated Successfully')
      setIsShowingPreview(false)
      dispatch(fetchLoanApplication(id))
    }
  }

  if (isFetchingApplication) {
    return (
      <ContentCard style={s('px-8')}>
        <Loading />
      </ContentCard>
    )
  }
  return (
    <FormProvider
      enableReinitialize
      initialValues={{
        expiredDate: isNilOrEmpty(currentValue?.expiredDate)
          ? undefined
          : formatDateString(currentValue?.expiredDate),
        conditionsPrecedent: offers?.conditional?.conditions?.conditionPrecedent || '',
        netLoanAdvance:
          offers?.conditional?.conditions?.netLoanAdvanceToSolicitor || '',
        specialConditions: offers?.conditional?.conditions?.specialCondition || '',
        types: currentValue?.types || [],
      }}
      onSubmit={(values) => {
        handleSubmit(
          values.types,
          values.expiredDate,
          values.conditionsPrecedent,
          values.netLoanAdvance,
          values.specialConditions
        )
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <ContentCard>
          <Row>
            <Col span={12}>
              <MultiSelectField
                items={offerConditionTypes.map((option) => ({
                  key: option,
                  label: option,
                }))}
                name="types"
                defaultValue={isNotNilOrEmpty(currentValue) && currentValue?.types}
                placeholder="Select Condition Type"
                label="Condition Type"
                variant="pill"
              />
            </Col>
            <Col span={12}>
              <DateField
                name="expiredDate"
                label="Condition Expiration"
                disablePast={true}
                defaultValue={currentValue?.expiredDate || new Date().toISOString()}
              />
            </Col>
            <Col span={24}>
              <div style={s('flex flex-row mt-8')}>
                {isShowingPreview ? (
                  <TogglePreviewButton
                    type="button"
                    style={s('border-transparent')}
                    onClick={() => setIsShowingPreview(!isShowingPreview)}
                  >
                    <ChevronUpIcon
                      width={20}
                      height={20}
                      style={{ marginLeft: -1, marginBottom: -5 }}
                    />
                  </TogglePreviewButton>
                ) : (
                  <TogglePreviewButton
                    onClick={() => setIsShowingPreview(!isShowingPreview)}
                    type="button"
                  >
                    <ChevronDownIcon
                      width={20}
                      height={20}
                      style={{ marginLeft: -1, marginBottom: -5 }}
                    />
                  </TogglePreviewButton>
                )}
                <SectionTitle title="Preview" style={s('mt-1 ml-2')} />
              </div>
            </Col>
            {isShowingPreview && (
              <LoanPreview
                application={loanApplication}
                loanSplits={loanSplits}
                products={products}
              />
            )}
            <Col span={24}>
              <div style={s('flex flex-row justify-end mt-8')}>
                <CancelButton type="reset">Cancel</CancelButton>
                <SaveButton type="submit" loading={isUpdatingApplication}>
                  Save
                </SaveButton>
              </div>
            </Col>
          </Row>
        </ContentCard>
      </Form>
    </FormProvider>
  )
}
export { ConditionalOfferForm }
