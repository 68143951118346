import React from 'react'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import { formatCurrency } from 'utils'
import { isNilOrEmpty } from '@solta/ramda-extra'

const formatShortDate = (dateString) => {
  const givenDate = new Date(dateString)
  return givenDate.toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

const LastKNownSale = ({ lastKNownSale }) => {
  const { lastKnownSaleDate, lastKnownSalePrice, lastKnownSaleMethod } = lastKNownSale

  return (
    <ContentCard>
      <Row>
        <Col span={8}>
          <DataDisplay
            label="Sale Date"
            value={formatShortDate(lastKnownSaleDate)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Sale Price"
            value={formatCurrency(lastKnownSalePrice)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Sale Method"
            value={isNilOrEmpty(lastKnownSaleMethod) ? lastKnownSaleMethod : '-'}
            valueStyles={s('mb-8')}
          />
        </Col>
      </Row>
    </ContentCard>
  )
}

export { LastKNownSale }
