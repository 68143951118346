import React, { useState } from 'react'
import { styled, s } from '@horizon/styled/v2'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch } from 'react-redux'
import { updateLoanApplication } from 'modules/application'
import { NumberField as NumberFieldBase } from '@horizon/components'
import { Formik as FormProvider, Form } from 'formik'
import { ReactComponent as TickBase } from '@horizon/components/src/assets/images/complete-tick.svg'

const NumberField = styled(NumberFieldBase)(s('w-12 bg-white ml-0'))
const Tick = styled(TickBase)(
  { transform: 'translateX(-150%) translateY(75%)' },
  ({ isSubmitted }) => !isSubmitted && s('hidden')
)

const FinalAssetInput = ({
  defaultValue,
  currentValue = {},
  loanApplicationId,
  loanApplication,
  financialId,
}) => {
  const dispatch = useDispatch()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const initialValues = {
    customSecurityValue: isNilOrEmpty(currentValue) ? defaultValue : currentValue,
  }
  const updateValue = (newValueString) => {
    const newValue = parseInt(newValueString, 10)
    const retrievedValuationList = loanApplication?.customSecurityValuations
    const valuationList = [...retrievedValuationList]

    const previousValuationIndex = valuationList.findIndex(
      (item) => item.financialId === financialId
    )

    if (previousValuationIndex === -1) {
      valuationList.push({ financialId, value: newValue })
    } else {
      valuationList[previousValuationIndex] = { financialId, value: newValue }
    }

    setIsFormSubmitted(true)
    dispatch(
      updateLoanApplication({
        id: loanApplicationId,
        payload: { customSecurityValuations: valuationList },
      })
    )
  }

  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={(values) => updateValue(values.customSecurityValue)}
    >
      <Form>
        <div style={s('flex flex-row')}>
          <NumberField
            name="customSecurityValue"
            prefix="$"
            defaultValue={isNilOrEmpty(currentValue) ? defaultValue : currentValue}
            onChange={() => setIsFormSubmitted(false)}
          />
          <Tick isSubmitted={isFormSubmitted} />
        </div>

        <input type="submit" hidden />
      </Form>
    </FormProvider>
  )
}
export { FinalAssetInput }
