const APPLICATION_INFO_SECTION_IDS = {
  XAI_CONNECTIONS: 'xai-connections',
  LOAN_INFO: 'loan-info',
  APPLICANTS: 'applicants',
  TRUSTS: 'trusts',
  BUSINESSES: 'businesses',
  CRR: 'crr',
  HOUSEHOLD_INFO: 'household-info',
  MYCRM_ADVISER_NOTES: 'mycrm-adviser-notes',
  GUARANTEES: 'guarantees',
  SUPPORTING_DOCS: 'supporting-docs',
  BROKER_INFO: 'broker-info',
  REFERENCE_DETAILS: 'reference-details',
  ACTIVITY_LOG: 'activity-log',
}
const VALOCITY_REPORT_SECTION_IDS = {
  GENERAL_DETAILS: 'general-details',
  PROPERTY_DETAILS: 'property-details',
  IVAL_ESTIMATE_RANGE: 'ival-estimate-range',
  PROPERTY_FEATURES: 'property-features',
  RISK_FLAGS: 'risk-flags',
  RATING_VALUATION: 'rating-valuation',
  LAST_KNOWN_SALE: 'last-known-sale',
  COMPARABLE_PROPERTY_SALES: 'comparable-property-sales',
}

export { VALOCITY_REPORT_SECTION_IDS }

export { APPLICATION_INFO_SECTION_IDS }
