import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { repaymentService } from 'apiService'
import { normalizeError } from '@horizon/services'

export const calculateRepayment = createAsyncThunk(
  'calculation/calculateRepayment',
  async (
    { productId, loanAmount, loanTermInYears, repaymentRecurrence, loading },
    { rejectWithValue }
  ) => {
    try {
      return await repaymentService.calculateRepayment(
        productId,
        loanAmount,
        loanTermInYears * 12,
        repaymentRecurrence,
        loading * 100
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

const calculationSlice = createSlice({
  name: 'calculation',
  initialState: {
    totalRepayment: undefined,
    periodicRepayment: undefined,
    totalInterestRepayment: undefined,
    totalPrincipleRepayment: undefined,
  },
  extraReducers: {
    [calculateRepayment.fulfilled]: (state, action) => {
      const {
        totalRepayment,
        periodicRepayment,
        totalInterestRepayment,
        totalPrincipleRepayment,
      } = action.payload

      state.totalRepayment = totalRepayment
      state.periodicRepayment = periodicRepayment
      state.totalInterestRepayment = totalInterestRepayment
      state.totalPrincipleRepayment = totalPrincipleRepayment
    },
  },
})

const { reducer: calculationReducer } = calculationSlice
export { calculationReducer }
