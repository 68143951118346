import React from 'react'
import { s, styled } from '@horizon/styled/v2'

const Title = styled.div(
  s('text-xl font-medium text-primary-hard', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)

const SectionTitle = ({ title, ...props }) => {
  return <Title {...props}>{title}</Title>
}

export { SectionTitle }
