import React from 'react'
import { Col, Row } from '@horizon/styled'
import { formatCurrency } from 'utils/formatters'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { buildEntityName } from 'utils/entity'

const mapPercentageResponsibleData = (owners = []) => {
  const percentageResponsible = owners
    .map(
      ({ legalEntity }) =>
        ` ${buildEntityName({
          firstName: legalEntity?.firstName,
          lastName: legalEntity?.lastName,
        })}`
    )
    .join(', ')

  return percentageResponsible
}

export const LivingExpense = ({
  amount = '-',
  category = '-',
  description = '-',
  frequency = '-',
  repsonsible = {},
  ...props
}) => {
  const { owners } = repsonsible

  const percentResponsible = mapPercentageResponsibleData(owners)

  return (
    <ContentCard style={{ minHeight: 336 }} {...props}>
      <Row gutter={[20, 16]}>
        <Col span={12}>
          <DataDisplay label="Description" value={description} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Category" value={category} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Amount" value={formatCurrency(amount)} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Frequency" value={frequency} />
        </Col>

        <Col span={24}>
          <DataDisplay label="Ownership" value={percentResponsible} />
        </Col>
      </Row>
    </ContentCard>
  )
}
