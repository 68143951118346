/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import {
  getSecurityDetails,
  selectSearchedProperty,
  selectHasFetchingPropertyDetailsFulfilled,
} from 'modules/property'
import { isNotNilOrEmpty, noop } from '@solta/ramda-extra'

import { useDispatch, useSelector } from 'react-redux'

import { updateFinance } from 'modules/financial'
import { useParams } from 'react-router-dom'
import { fetchLoanApplication } from 'modules/application'

export const useManualLinkProperty = (security, closeModal = noop) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [currentSearchedProperty, setCurrentSearchedProperty] = useState()

  const isGetSecurityDetailsFulfilled = useSelector((state) =>
    selectHasFetchingPropertyDetailsFulfilled(state, security?.id)
  )
  const propertyDetails = useSelector(
    selectSearchedProperty(currentSearchedProperty?.propertyId)
  )

  const onAddressSelectionChange = useCallback((selectedResult) => {
    if (isNotNilOrEmpty(selectedResult)) setCurrentSearchedProperty(selectedResult)
    else setCurrentSearchedProperty(undefined)
  }, [])

  const onPropertyLink = useCallback(async () => {
    dispatch(
      getSecurityDetails({
        requestId: security?.id,
        propertyId: currentSearchedProperty?.propertyId,
      })
    )
  }, [currentSearchedProperty?.propertyId, dispatch, security?.id])

  useEffect(() => {
    const updateFinanceOnSecurityDetailsFetched = async () => {
      if (isNotNilOrEmpty(propertyDetails) && isGetSecurityDetailsFulfilled) {
        // await here to make sure close modal only when this updateFinance action finished
        await dispatch(
          updateFinance({
            id: security?.id,
            payload: propertyDetails,
          })
        )
        dispatch(fetchLoanApplication(id))

        closeModal()
      }
    }

    updateFinanceOnSecurityDetailsFetched()
  }, [
    closeModal,
    dispatch,
    isGetSecurityDetailsFulfilled,
    propertyDetails,
    security?.id,
  ])

  return { onAddressSelectionChange, onPropertyLink, currentSearchedProperty }
}
