import React from 'react'
import { s, styled } from '@horizon/styled/v2'

const Container = styled.div(
  s('rounded-lg bg-white p-8', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  })
)

const ContentCard = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

export { ContentCard }
