import React, { useState, useEffect } from 'react'
import { ContentCard } from '@horizon/components/src/v2'
import { Loading } from '@horizon/components'
import { styled, s } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { useDispatch, useSelector } from 'react-redux'

import { DisplayedNote } from './DisplayedNote'

import {
  fetchNotes,
  selectAllNotes,
  selectIsFetchingNotes,
  createNote,
} from 'modules/note'
import { selectProfile } from '@horizon/redux.profile'
import { ProfileIcon } from './ProfileIcon'
import { buildEntityName } from 'utils/entity'

const Input = styled.textarea(
  s('h-full w-full border-0 bg-transparent py-3 pl-3', { resize: 'none' }),
  s('text-grey-800 text-base font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
    '#search-clear': s('text-red-200'),

    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  ({ isDisabled }) => isDisabled && s('text-grey-400')
)

const InputContainer = styled.div(s('rounded-lg bg-grey-100 border-1 border-grey-200'))
const CancelButton = styled.button(
  s('border-transparent bg-transparent text-primary px-5 py-2 font-semibold', {
    cursor: 'pointer',
  })
)

const AddButton = styled.button(
  s(
    'bg-primary text-white px-5 py-2 font-semibold border-transparent rounded-md cursor-pointer',
    {
      cursor: 'pointer',
    }
  )
)

const NoteSection = ({ loanApplicationId, source }) => {
  const dispatch = useDispatch()
  const [isInputChanged, setIsInputChanged] = useState(false)
  const [noteInput, setNoteInput] = useState('')

  const allNotes = useSelector(selectAllNotes)
  const profile = useSelector(selectProfile)

  const sectionNotes = allNotes.filter((note) => {
    return note.source === source
  })

  const isFetchingNotes = useSelector(selectIsFetchingNotes)

  function postNote() {
    dispatch(
      createNote({
        loanApplicationId,
        source,
        content: noteInput,
      })
    )
    setNoteInput('')
    setIsInputChanged(false)
  }

  function resetInput() {
    setNoteInput('')
    setIsInputChanged(false)
  }

  useEffect(() => {
    dispatch(fetchNotes(loanApplicationId))
  }, [dispatch, loanApplicationId])

  if (isFetchingNotes)
    return (
      <ContentCard>
        <p style={s('text-lg font-semibold')}>Leave a Note</p>
        <div style={s('my-3')}>
          <Loading />
        </div>
      </ContentCard>
    )

  return (
    <ContentCard>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <p style={s('text-lg font-semibold')}>Leave a Note</p>
        </Col>

        <Col span={24}>
          {sectionNotes.map((note) => (
            <DisplayedNote key={note.id} note={note} />
          ))}
        </Col>

        <Col span={1}>
          <div style={s('flex flex-row justify-end')}>
            <ProfileIcon
              fullName={buildEntityName({
                firstName: profile?.firstName,
                lastName: profile?.lastName,
              })}
            />
          </div>
        </Col>

        <Col span={23}>
          <InputContainer>
            <Input
              id="note-input"
              placeholder="Leave a note..."
              onChange={(e) => {
                setIsInputChanged(true)
                setNoteInput(e.target.value)
              }}
              value={noteInput}
            />
            {isInputChanged && (
              <div style={s('flex flex-row justify-end mr-3 pb-2')}>
                <CancelButton onClick={resetInput}>Cancel</CancelButton>

                <AddButton
                  onClick={() => postNote(loanApplicationId, source, noteInput)}
                >
                  Add Note
                </AddButton>
              </div>
            )}
          </InputContainer>
        </Col>
      </Row>
    </ContentCard>
  )
}

export { NoteSection }
