/* eslint-disable complexity */
import React from 'react'
import { isNilOrEmpty, propEq } from '@solta/ramda-extra'
import { Button as ButtonBase } from '@horizon/components'
import { styled, s } from '@horizon/styled/v2'
import { ReactComponent as TickIcon } from '@horizon/components/src/assets/images/tick.svg'

import { useIllionDataRetrieval } from './useIllionDataRetrieval'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { HelperText, SectionTitle } from '../components'
import { ActionPanel } from './ActionPanel'
import { useXaiLinking } from './useXaiLinking'

const { XAI_CONNECTIONS } = APPLICATION_INFO_SECTION_IDS

const Prompt = styled.p(s('text-grey-700 text-sm mb-1'))

const XaiConnectionBtn = styled(ButtonBase)(
  ({ isXaiActivated }) =>
    isXaiActivated && {
      ':hover:not([disabled])': s('bg-secondary', { boxShadow: 'none' }),
    }
)

const renderActivateBtnText = (
  isActivatingXai,
  isXaiActivated,
  reactivationRequired
) => {
  if (isActivatingXai) return 'Activating Xai'
  if (isXaiActivated) return 'Activated'
  if (reactivationRequired) return 'Reactivate Xai'

  return 'Activate Xai'
}

const enrichIndividualBorrowers = (individualBorrowers = [], loanApplicationId) => {
  function findAssessmentByApplicationId(assessments = [], loanApplicationId) {
    return assessments.find(propEq('loanApplicationId', loanApplicationId))
  }

  return individualBorrowers.map((borrower) => ({
    ...borrower,
    affordabilityAssessment: findAssessmentByApplicationId(
      borrower?.affordability?.assessments,
      loanApplicationId
    ),
  }))
}

const assessmentReferenceToBorrowerGroup = (borrowerCompletedAssessment = []) => {
  const groups = {}

  for (const borrower of borrowerCompletedAssessment) {
    const { assessmentReference } = borrower.affordabilityAssessment || {}

    const targetGroupDoesNotExist = isNilOrEmpty(groups[assessmentReference])
    if (targetGroupDoesNotExist) groups[assessmentReference] = []

    groups[assessmentReference].push(borrower)
  }

  return groups
}

export const XaiConnections = ({
  individualBorrowers = [],
  isAllCreditReportsPassed = false,
  loanApplicationId,
}) => {
  const enrichedIndividualBorrowers = enrichIndividualBorrowers(
    individualBorrowers,
    loanApplicationId
  )

  const {
    activateXai,
    updateXai,
    isActivatingXai,
    isXaiActivated,
    isReactivationRequired,
    isUpdatingXaiApplication,
    assessmentStatusToBorrowers: { borrowersCompletedAssessment },
  } = useXaiLinking(
    isAllCreditReportsPassed,
    loanApplicationId,
    enrichedIndividualBorrowers
  )

  const {
    sendIllionLink,
    entitiesLinkingIllion,
    entityIdToRetrievalStatus,
  } = useIllionDataRetrieval(loanApplicationId, borrowersCompletedAssessment)

  const handleXaiBtnClicked = async (reactivationRequired = false) => {
    if (reactivationRequired) {
      activateXai(loanApplicationId)
    }
  }

  return (
    <div id={XAI_CONNECTIONS} style={{ marginBottom: 52 }}>
      <SectionTitle title="Xai Connections" style={s('mb-4')} />

      <HelperText>
        You can activate the Xai connections once CCR Credit Checks are complete.
      </HelperText>

      {isActivatingXai && <Prompt>Note: Xai activation is in progress</Prompt>}
      {isReactivationRequired && !isActivatingXai && (
        <Prompt style={s('text-accent-red')}>Note: Xai activation failed</Prompt>
      )}

      <XaiConnectionBtn
        disabled={!isAllCreditReportsPassed}
        loading={isActivatingXai}
        StartIcon={isXaiActivated && <TickIcon style={s('mr-1')} />}
        onClick={() => handleXaiBtnClicked(isReactivationRequired)}
        isXaiActivated={isXaiActivated}
        style={s('mb-4', {
          width: isActivatingXai ? 140 : 'initial',
        })}
      >
        {renderActivateBtnText(isActivatingXai, isXaiActivated, isReactivationRequired)}
      </XaiConnectionBtn>

      {isAllCreditReportsPassed &&
        isXaiActivated &&
        Object.values(
          assessmentReferenceToBorrowerGroup(borrowersCompletedAssessment)
        )?.map((borrowersGroup = []) => {
          return (
            <ActionPanel
              borrowersGroup={borrowersGroup}
              updateXai={updateXai}
              sendIllionLink={sendIllionLink}
              entityIdToIllionRetrievalStatus={entityIdToRetrievalStatus}
              entitiesLinkingIllion={entitiesLinkingIllion}
              isUpdatingXai={isUpdatingXaiApplication}
              key={borrowersGroup[0].entityId}
              style={s('mb-6')}
            />
          )
        })}
    </div>
  )
}
