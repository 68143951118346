import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { round } from 'mathjs'
import { any, isNilOrEmpty } from '@solta/ramda-extra'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { LoanSplitDisplayItem } from './LoanSplitDisplayItem'
import { formatCurrency, capitaliseFirstLetter } from 'utils'
import * as repaymentModule from 'modules/calculation'

const Container = styled.div(s('bg-white p-6 rounded-lg w-full'))
const LoanSplitHeading = styled.h4(
  s('mb-3 text-base text-grey-900', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)

export const LoanSplitDisplay = ({ loanSplit = {}, loanSplitNumber, ...props }) => {
  const dispatch = useDispatch()
  const [periodicRepaymentAmount, setPeriodicRepaymentAmount] = useState()

  const {
    product = {},
    loanAmount,
    interestRate,
    loanTermInYears,
    repaymentFrequency,
    securities = [],
    loading,
  } = loanSplit

  useEffect(() => {
    const calculateRepaymentAmount = async () => {
      const hasNilOrEmptyArguments = any(isNilOrEmpty, [
        product?.id,
        loanAmount,
        loanTermInYears,
        repaymentFrequency,
        loading,
      ])

      if (hasNilOrEmptyArguments) return

      const result = await dispatch(
        repaymentModule.calculateRepayment({
          productId: product?.id,
          loanAmount,
          loanTermInYears,
          repaymentRecurrence: repaymentFrequency,
          loading,
        })
      )

      const periodicRepayment = result.payload?.periodicRepayment

      setPeriodicRepaymentAmount(
        periodicRepayment ? round(periodicRepayment || 0, 2) : periodicRepayment
      )
    }

    calculateRepaymentAmount()
  }, [dispatch, loading, loanAmount, loanTermInYears, product.id, repaymentFrequency])

  return (
    <Container {...props}>
      <>
        <LoanSplitHeading>Loan Split {loanSplitNumber}</LoanSplitHeading>

        <Row>
          <Col span={6}>
            <LoanSplitDisplayItem
              label="Total Loan Amount"
              value={formatCurrency(loanAmount)}
            />
          </Col>

          <Col span={6}>
            <LoanSplitDisplayItem
              label={`${capitaliseFirstLetter(repaymentFrequency)} Repayment`}
              value={formatCurrency(periodicRepaymentAmount, 2)}
            />
          </Col>

          <Col span={6}>
            <LoanSplitDisplayItem
              label="Interest Rate"
              value={`${interestRate ? round(interestRate, 2) : '-'}%`}
            />
          </Col>

          <Col span={6}>
            <LoanSplitDisplayItem label="Total Securities" value={securities.length} />
          </Col>
        </Row>
      </>
    </Container>
  )
}
