import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDistanceToNowStrict } from 'date-fns'
import { s, styled } from '@horizon/styled/v2'
import { ReactComponent as X } from '@horizon/components/src/assets/images/x.svg'
import { DottedCircleLoader } from '@horizon/components'
import {
  selectIsUpdateNotificationAsSeenPending,
  updateNotificationAsSeen,
} from 'modules/notification'

const Container = styled.div(s('flex items-center justify-center'))
const Root = styled.div(
  s('flex justify-between p-5 w-full', { ':hover': s('bg-grey-100') })
)
const Text = styled.p(
  s('font-normal m-0 text-base text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const XIcon = styled(X)(
  s('text-grey-700 w-1', { cursor: 'pointer' }),
  ({ isVisible }) => !isVisible && s('text-transparent')
)

export const NotificationItem = ({ notification = {}, profileId, ...props }) => {
  const dispatch = useDispatch()
  const [isHovered, setIsHovered] = useState(false)

  const { date = '-', description = '-', id: notificationId } = notification

  const isUpdateNotificationAsSeenPending = useSelector((state) =>
    selectIsUpdateNotificationAsSeenPending(state, notificationId)
  )

  const formattedDate = formatDistanceToNowStrict(new Date(date))

  const markAsSeen = () =>
    dispatch(
      updateNotificationAsSeen({ notificationId, profileId, requestId: notificationId })
    )

  return (
    <Root
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <div>
        <Text>{description}</Text>

        <Text style={s('text-grey-600 text-sm')}>{formattedDate} ago</Text>
      </div>

      <Container>
        {isUpdateNotificationAsSeenPending ? (
          <DottedCircleLoader size="sm" />
        ) : (
          <XIcon onClick={markAsSeen} isVisible={isHovered} />
        )}
      </Container>
    </Root>
  )
}
