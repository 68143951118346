import BaseService from '../baseService'

export class AffordabilityAssessmentService extends BaseService {
  conductAssessment(loanApplicationId) {
    const config = { json: { loanApplicationId }, timeout: false }

    return this.client.post('affordability-assessments', config).json()
  }

  updateAssessment(loanApplicationId, assessmentReference) {
    const config = {
      json: { loanApplicationId, assessmentReference },
      timeout: false,
    }
    return this.client.put('affordability-assessments', config).json()
  }

  retrieveFinancialsData(loanApplicationId, legalEntityId) {
    const config = {
      json: { loanApplicationId, legalEntityId },
    }

    return this.client
      .post(`affordability-assessments/request-financials-data`, config)
      .json()
  }

  async exportUMIReport(assessmentReference) {
    const buffer = await this.client
      .get(`affordability-assessments/request-UMI-Export-data/${assessmentReference}`)
      .arrayBuffer()
    convertAndSave('UMI Report', buffer)
  }
}

function convertAndSave(reportName, contentInByte) {
  const blob = new Blob([contentInByte], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  const fileName = reportName
  link.download = fileName
  link.click()
}
