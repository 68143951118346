import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { Modal } from '@horizon/components'
import { ReactComponent as XIconBase } from '@horizon/components/src/assets/images/x.svg'

const ButtonContainer = styled.div(s('flex justify-end'))
const Container = styled.div(
  s('bg-white p-6', {
    borderRadius: 12,
    boxShadow: ' 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    minWidth: 560,
  })
)
const Header = styled.div(s('flex items-center justify-between mb-8'))
const Heading = styled.h3(
  s('font-normal m-0 text-grey-900 text-xl', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)
const Text = styled.p(
  s('font-normal m-0 mb-8 text-grey-900 text-base', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)
const BoldText = styled.span(s('font-medium'))
const DeleteButton = styled.button(
  s('bg-secondary border-0 font-semibold px-5 py-3 text-base', {
    borderRadius: 6,
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    marginLeft: 28,
  })
)
const TextButton = styled.button(
  s('bg-transparent border-0 font-semibold text-primary text-base', {
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const XIcon = styled(XIconBase)(s('text-grey-700', { cursor: 'pointer' }))

const DeleteFile = ({ closeModal, isOpen, handleDelete, fileName, ...props }) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Container {...props}>
        <Header>
          <Heading>Delete Uploaded File</Heading>
          <XIcon onClick={closeModal} />
        </Header>

        <Text>
          Are you sure you want to delete <BoldText>&quot;{fileName}&quot;</BoldText>?
        </Text>

        <ButtonContainer>
          <TextButton onClick={closeModal}>No, keep it</TextButton>
          <DeleteButton onClick={() => handleDelete(closeModal)}>
            Yes, delete it
          </DeleteButton>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export { DeleteFile }
