/* eslint-disable complexity */
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ContentCard as ContentCardBase,
  DataDisplay,
  Divider,
} from '@horizon/components/src/v2'

import { styled, s } from '@horizon/styled/v2'
import { Row, Col } from '@horizon/styled'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as ChevronRightBase } from '@horizon/components/src/assets/images/chevron-right.svg'
import { ReactComponent as ChainLinkBase } from '@horizon/components/src/assets/images/chain-link.svg'
import { formatCurrency, capitaliseFirstLetterOfAllWords } from 'utils'
import { routes } from 'navigation/routes'
import { SecurityOwnerSegment } from './SecurityOwnerSegment'
import { TEST_IDS } from '@horizon/constants'

const {
  loanApp: {
    assessment: {
      security: { details },
    },
  },
} = routes

const ContentCard = styled(ContentCardBase)(s('mb-5 px-5'))

const ViewPropertyButton = styled.button(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center py-2 px-4 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  )
)

const LinkPropertyButton = styled.button(
  s(
    'bg-primary border-1 border-transparent rounded-lg text-white font-semibold self-end text-center py-2 px-4 mr-2 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  ),
  ({ isLinked }) => isLinked && s('hidden')
)

const PropertyNotLinkedPill = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-accent-red text-xs mb-2',
    { width: 145 }
  )
)
const PropertyLinkedPill = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-secondary text-xs text-white mb-2',
    { width: 115 }
  )
)

const getRentalIncome = (rentalIncomeArray) => {
  if (rentalIncomeArray?.length === 0) return null
  const sumOfAllIncomes = rentalIncomeArray
    .map((rentalIncome) => rentalIncome?.rentalAmount)
    .reduce((previousAmount, currentAmount) => previousAmount + currentAmount, 0)
  return sumOfAllIncomes
}

const ChevronRight = styled(ChevronRightBase)(
  s('w-1 p-0', { marginTop: 2, height: 16 })
)

const ChainLink = styled(ChainLinkBase)(s('mr-2', { marginTop: 2 }))

const getLinkedStatus = (linkedDetails) => {
  if (isNilOrEmpty(linkedDetails) || isNilOrEmpty(linkedDetails?.address)) {
    return false
  }
  return true
}

export const SecurityCard = ({
  security,
  loanApplicationId,
  customSecurityValuations,
  legalEntities,
  setIsModalOpened,
  setModalSecurity,
  index,
}) => {
  const history = useHistory()

  const {
    id: securityId,
    estimatedValue,
    propertyType,
    primaryUsage,
    isSecurity,
    occupancy,
    primaryPurpose,
    holding,
    rentalIncome,
    linkedDetails,
    percentOwned,
    finalAssetValue,
  } = security || {}

  const largerThanZeroPercentOwned = percentOwned?.owners.filter(
    (owner) => owner.percent > 0
  )
  const customSecurityValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )
  const goToSecurityDetails = () => {
    history.push({
      pathname: details
        .replace(':id', loanApplicationId)
        .replace(':securityId', securityId),
      state: {
        fullAddress: security?.address?.fullAddress,
        linkedDetails,
        security,
      },
    })
  }

  const isLinked = getLinkedStatus(linkedDetails)

  const handleOnLinkBtnClicked = (security) => {
    setIsModalOpened(true)
    setModalSecurity(security)
  }

  return (
    <ContentCard>
      <Row>
        <Col span={17}>
          <DataDisplay
            label="Address"
            testId={`${TEST_IDS.PROPERTY_ADDRESS}${index}`}
            value={
              isLinked
                ? capitaliseFirstLetterOfAllWords(linkedDetails?.address)
                : security?.address?.fullAddress
            }
            valueStyle={s('text-xl')}
          />
        </Col>

        <Col span={7}>
          <div style={s('flex flex-row justify-end')}>
            <LinkPropertyButton
              onClick={() => handleOnLinkBtnClicked(security)}
              isLinked={isLinked}
            >
              <div style={s('flex flex-row')}>
                <ChainLink />
                Link Property
              </div>
            </LinkPropertyButton>

            <ViewPropertyButton onClick={goToSecurityDetails}>
              <div style={s('flex flex-row')}>
                View details
                <ChevronRight />
              </div>
            </ViewPropertyButton>
          </div>
        </Col>

        <Col span={24}>
          {isLinked ? (
            <PropertyLinkedPill data-test-id={`${TEST_IDS.VELOCITY_LINK}${index}`}>
              SECURITY LINKED
            </PropertyLinkedPill>
          ) : (
            <PropertyNotLinkedPill
              data-test-id={`${TEST_IDS.VELOCITY_NOT_LINK}${index}`}
            >
              SECURITY NOT LINKED
            </PropertyNotLinkedPill>
          )}
        </Col>

        <Col span={6}>
          <DataDisplay
            label="MyCRM Estimate"
            testId={`${TEST_IDS.MYCRM_ESTIMATE}${index}`}
            value={formatCurrency(estimatedValue?.value)}
            valueStyle={s('mt-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            testId={`${TEST_IDS.PROPERTY_TYPE}${index}`}
            label="Property Type"
            value={propertyType?.propertyTypeName}
            valueStyle={s('mt-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            label="Property Usage"
            testId={`${TEST_IDS.PROPERTY_USAGE}${index}`}
            value={primaryUsage}
            valueStyle={s('mt-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            label="Used as Security"
            testId={`${TEST_IDS.USED_AS_SECURITY}${index}`}
            value={isSecurity ? 'Yes' : 'No'}
            valueStyle={s('mt-1 ml-1')}
            labelStyle={s('ml-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            label="Monthly Rental Income"
            testId={`${TEST_IDS.RENTAL_INCOME}${index}`}
            value={formatCurrency(getRentalIncome(rentalIncome))}
            valueStyle={s('mt-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            label="Occupancy"
            testId={`${TEST_IDS.PROPERTY_OCCUPANCY}${index}`}
            value={occupancy}
            valueStyle={s('mt-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            label="Primary Purpose"
            testId={`${TEST_IDS.PRIMARY_PURPOSE}${index}`}
            value={primaryPurpose?.propertyTypeName}
            valueStyle={s('mt-1')}
          />
        </Col>

        <Col span={6}>
          <DataDisplay
            label="Holding"
            testId={`${TEST_IDS.PROPERTY_HOLDING}${index}`}
            value={holding}
            valueStyle={s('mt-1 ml-1')}
            labelStyle={s('ml-1')}
          />
        </Col>
        <Col span={24}>
          <div style={s('mt-3')}>
            <Divider />
          </div>
        </Col>

        <Col span={24}>
          <h4 style={s('mt-1 mb-2')}>Owners</h4>
        </Col>

        {largerThanZeroPercentOwned.map((owner, index) => (
          <Col span={6} key={index}>
            <SecurityOwnerSegment owner={owner} legalEntities={legalEntities} />
          </Col>
        ))}

        <Col span={24}>
          <div style={s('mt-3')}>
            <Divider />
          </div>
        </Col>

        <Col span={24}>
          <h4 style={s('mt-1 mb-2')}>Results</h4>
        </Col>

        <Col span={24}>
          {isLinked ? (
            <Row gutter={[0, 0]}>
              <Col span={4}>
                <DataDisplay
                  testId={`${TEST_IDS.FINAL_ASSET_VALUE}${index}`}
                  label="Final Asset Value"
                  value={formatCurrency(finalAssetValue)}
                />
              </Col>

              <Col span={4}>
                <DataDisplay
                  label="iVal Results"
                  testId={`${TEST_IDS.IVAL_RESULT}${index}`}
                  value={formatCurrency(linkedDetails?.valuation?.estimatedValue)}
                />
              </Col>

              <Col span={4}>
                <DataDisplay
                  label="Full Market Results"
                  value={formatCurrency(customSecurityValuation?.value)}
                />
              </Col>
            </Row>
          ) : (
            <div style={s('flex flex-column w-full justify-center')}>
              <div style={s('text-center text-sm font-semibold mb-2')}>
                No Results Yet
              </div>
              <div style={s('text-center text-sm')}>
                Please connect to Valocity to get iVal Results
              </div>
            </div>
          )}
        </Col>
      </Row>
    </ContentCard>
  )
}
