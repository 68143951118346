import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { differenceInMinutes } from 'date-fns'

import { s, styled } from '@horizon/styled/v2'
import { Button, toast } from '@horizon/components'

import { useInterval } from 'hooks/useInterval'
import { exportUMIReport } from 'modules/affordabilityAssessment'

const SectionTitle = styled.p(s('text-primary text-xs font-semibold'))

function hasTimePassedLimit(financialsUpdatedAt) {
  const limitInMins = 5

  return differenceInMinutes(new Date(), new Date(financialsUpdatedAt)) >= limitInMins
}

export const UMIExportButton = ({
  isUpdatedXai = false,
  assessmentReference,
  financialsUpdatedAt,
}) => {
  const dispatch = useDispatch()
  const [hasUpdatedTimePassedLimit, setHasUpdatedTimePassedLimit] = useState()

  const exportUmiReport = async () => {
    try {
      const result = await dispatch(
        exportUMIReport({ referenceId: assessmentReference })
      )
      unwrapResult(result)
    } catch (error) {
      switch (true) {
        case error?.meta?.statusCode === 422:
          toast(error?.description)
          break
        default:
          toast('Failed to export report, please try again later')
      }
    }
  }

  useEffect(() => {
    setHasUpdatedTimePassedLimit(hasTimePassedLimit(financialsUpdatedAt))
  }, [financialsUpdatedAt])

  useInterval(
    () => {
      if (hasTimePassedLimit(financialsUpdatedAt)) setHasUpdatedTimePassedLimit(true)
    },
    isUpdatedXai && !hasUpdatedTimePassedLimit,
    1000
  )
  return (
    <>
      {isUpdatedXai && hasUpdatedTimePassedLimit ? (
        <div>
          <SectionTitle>UMI REPORT</SectionTitle>
          <Button
            style={s('mr-4 min-w-6')}
            variant="outlined"
            onClick={() => exportUmiReport()}
          >
            Export Report
          </Button>
        </div>
      ) : (
        <div>
          <SectionTitle style={s('mb-0')}>UMI REPORT</SectionTitle>
          <div style={s('text-xs text-red mb-1')}>
            UMI report will be ready after Xai has been updated for at least 5 minutes.
          </div>
          <Button style={s('mr-4 min-w-6')} variant="outlined" disabled>
            Export Report
          </Button>
        </div>
      )}
    </>
  )
}
