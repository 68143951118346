import { buildEntityName } from 'utils/entity'

const mapSecurity = (security, loanApplication) => {
  const { estimatedValue, linkedDetails, id: securityId } = security
  const { customSecurityValuations } = loanApplication

  const customSecurityValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )

  const customEstimatedValue = customSecurityValuation?.value
  const ivalEstimatedValue = linkedDetails?.valuation?.estimatedValue
  const myCrmEstimatedValue = estimatedValue?.value

  return {
    ...security,
    finalAssetValue:
      customEstimatedValue || ivalEstimatedValue || myCrmEstimatedValue || undefined,
  }
}

export const mapLegalEntity = (legalEntity) => {
  return {
    ...legalEntity,
    fullName: buildEntityName(
      {
        firstName: legalEntity?.personName?.firstName,
        lastName: legalEntity?.personName?.surname,
      },
      legalEntity?.trustName,
      legalEntity?.businessName,
      legalEntity?.companyName
    ),
  }
}

const mapLoanApplication = (loanApplication) => {
  return {
    ...loanApplication,
    securities: loanApplication?.securities?.map((s) =>
      mapSecurity(s, loanApplication)
    ),
    legalEntities: {
      individuals: loanApplication?.legalEntities?.individuals?.map(mapLegalEntity),
      trusts: loanApplication?.legalEntities?.trusts?.map(mapLegalEntity),
      companies: loanApplication?.legalEntities?.companies?.map(mapLegalEntity),
    },
  }
}

export { mapLoanApplication }
