import React from 'react'
import PropTypes from 'prop-types'

import { useTable } from 'react-table'
import { TableBase } from './TableBase'
import { Pagination } from './Pagination'
import { styled, s } from '@horizon/styled/v2'
import { LoaderWithLabel } from '../..'
import { mergeDeepRight, isNilOrEmpty } from '@solta/ramda-extra'

const Root = styled.div(s('flex flex-column flex-1 m-4 h-full'), {
  overflow: 'hidden',
})
function Table({
  columns,
  data,
  onClick,
  tBodyStyle,
  paginationProps,
  useDefaultPagination = true,
  testId,
  loading,
  tableHeight = 'calc(100vh - 183px)',
  containerStyle = {},
  tableHeaderRowStyles = {},
  loaderLabel = 'Fetching Applications',
  TableEmptyPlaceholder,
  placeholderText,
  ...props
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    }
  )

  const hasNoData = !loading && isNilOrEmpty(rows)

  return (
    <Root {...props}>
      {loading ? (
        <LoaderWithLabel
          label={loaderLabel}
          style={s('flex justify-center items-center', {
            height: tableHeight,
          })}
          labelStyle={s('text-base')}
        />
      ) : (
        <TableBase
          containerStyle={mergeDeepRight({ height: tableHeight }, containerStyle)}
          tBodyStyle={tBodyStyle}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          page={rows}
          onClick={onClick}
          testId={testId}
          tableHeaderRowStyles={tableHeaderRowStyles}
          hasNoData={hasNoData}
          TableEmptyPlaceholder={TableEmptyPlaceholder}
          placeholderText={placeholderText}
        />
      )}

      {paginationProps && useDefaultPagination && <Pagination {...paginationProps} />}
    </Root>
  )
}

Table.propTypes = {
  pageSize: PropTypes.number,
  fetchData: PropTypes.func,
  pageCount: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  useDefaultPagination: PropTypes.bool,
}

export default Table
