import React from 'react'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import { DataDisplay, ContentCard } from 'features/loanAssessment/info/components'
import { formatCurrency, formatDateString } from 'utils'

const GeneralDetails = ({ linkedDetails }) => {
  return (
    <ContentCard>
      <Row>
        <Col span={24}>
          <DataDisplay
            label="Address"
            value={linkedDetails?.address}
            valueStyles={{ style: s('text-xl') }}
          />
        </Col>

        <Col span={11}>
          <img src={linkedDetails?.imageSrc} style={s('rounded-lg w-full max-w-32')} />
        </Col>

        <Col span={13}>
          <Row gutter={[0, 0]}>
            <Col span={12}>
              <DataDisplay
                label={`iVal Estimate ${formatDateString(
                  linkedDetails?.valuation?.lastValued
                )}`}
                value={formatCurrency(linkedDetails?.valuation?.estimatedValue)}
                valueStyles={{ style: s('mb-6') }}
              />
            </Col>

            <Col span={12}>
              <DataDisplay
                label="Forecast Standard Deviation"
                value={linkedDetails?.forecastStandardDeviation}
              />
            </Col>

            <Col span={12}>
              <DataDisplay
                valueStyles={s('mb-6')}
                label="Value High Range"
                value={formatCurrency(linkedDetails?.valuation?.estimatedUpperRange)}
              />
            </Col>

            <Col span={12}>
              <DataDisplay
                label="Value Low Range"
                value={formatCurrency(linkedDetails?.valuation?.estimatedLowerRange)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ContentCard>
  )
}

export { GeneralDetails }
