/* eslint-disable complexity */
import React from 'react'
import { styled, s } from '@horizon/styled/v2'

export const ProfileIcon = ({ fullName }) => {
  const nameList = fullName.split(' ')

  const LetterContainer = styled.div(
    s(
      'rounded-3xl w-2 mr-4 h-2 font-medium text-center text-xl bg-gray-200 text-primary-hard'
    )
  )
  return <LetterContainer>{nameList?.[0]?.charAt(0)?.toUpperCase()}</LetterContainer>
}
