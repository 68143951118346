import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { differenceInYears } from 'date-fns'
import { Formik as FormProvider, Form } from 'formik'
import { styled, s } from '@horizon/styled/v2'
import * as Validator from 'yup'
import { DateField as DateFieldBase, SelectField } from '@horizon/components'
import { TextField, OutlinedBtn, Row, CardContainer } from './common'
import { updateProfile } from '@horizon/redux.profile'

import { GENDERS, TEST_IDS } from '@horizon/constants'

const GENDER_TO_DISPLAY = {
  [GENDERS.MALE]: 'Male',
  [GENDERS.FEMALE]: 'Female',
  [GENDERS.UNSPECIFIED]: 'Prefer not to say',
}

const DateField = styled(({ containerStyle, ...otherProps }) => (
  <DateFieldBase
    containerProps={{
      style: s('flex flex-column flex-1', containerStyle),
    }}
    {...otherProps}
  />
))()

const { object, string } = Validator
export const checkAreObjectsTheSame = (obj1, obj2) =>
  JSON.stringify(obj1) !== JSON.stringify(obj2)

const parseDateStringToJsDateFormat = (dateString, originalFormat = 'DD/MM/YYYY') =>
  moment(dateString, originalFormat).toDate()

const validationSchema = object({
  firstName: string()
    .required('First name is required')
    .max(50, 'First name exceeds the maximum length of 50 characters'),

  lastName: string()
    .required('Last name is required')
    .max(50, 'Last name exceeds the maximum length of 50 characters'),

  gender: string().oneOf(Object.values(GENDERS)).required('Gender is required'),
})

export const PersonalDetailsCard = ({
  firstName,
  lastName,
  dateOfBirth,
  gender,
  ...profile
}) => {
  const dispatch = useDispatch()
  console.log('DEBUG:::', dateOfBirth)

  const handleSubmit = (data) => {
    dispatch(updateProfile(data))
  }

  return (
    <FormProvider
      initialValues={{ firstName, lastName, dateOfBirth, gender }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values: formValues, resetForm }) => {
        const isFormEdited = checkAreObjectsTheSame(formValues, {
          firstName,
          lastName,
          dateOfBirth,
          gender,
        })

        return (
          <Form>
            <CardContainer {...profile}>
              <Row style={s('mb-6')}>
                <TextField
                  testId={TEST_IDS.ACCOUNT_FIRST_NAME_INPUT}
                  name="firstName"
                  label="First Name"
                  style={s('mr-2')}
                />

                <TextField name="lastName" label="Last Name" style={s('ml-2')} />
              </Row>

              <Row>
                {/* <DateField name="dateOfBirth" label="DOB" containerStyle={s('mr-2')} /> */}

                <SelectField
                  name="gender"
                  style={s('flex-1 ml-2')}
                  label="Gender"
                  options={Object.entries(GENDERS).map(([, value]) => ({
                    label: GENDER_TO_DISPLAY[value],
                    value,
                  }))}
                />
              </Row>

              {isFormEdited ? (
                <Row style={s('mt-6 justify-start')}>
                  <OutlinedBtn variant="contained" style={s('mr-4')}>
                    Save Changes
                  </OutlinedBtn>

                  <OutlinedBtn onClick={resetForm}>Cancel</OutlinedBtn>
                </Row>
              ) : (
                <OutlinedBtn type="button" style={s('mt-6')}>
                  Save Changes
                </OutlinedBtn>
              )}
            </CardContainer>
          </Form>
        )
      }}
    </FormProvider>
  )
}
