import React from 'react'
import { Button as ButtonBase, TextField as TextFieldBase } from '@horizon/components'
import { styled, s } from '@horizon/styled/v2'

export const CardContainer = styled.div(
  s('bg-white rounded-lg p-8', {
    width: 530,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);',
  })
)

export const Row = styled.div(s('flex justify-between'))

export const TextField = styled((props) => (
  <TextFieldBase containerStyle={s('flex-1')} {...props} />
))()

export const OutlinedBtn = styled(({ style, ...otherProps }) => (
  <ButtonBase
    type="submit"
    variant="outlined"
    style={s('border-grey-300 py-3', style)}
    {...otherProps}
  />
))()
