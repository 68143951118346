/* eslint-disable complexity */
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loading } from '@horizon/components'
import { Col, Row } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/application'
import { selectAllCreditReports, getCreditReport } from 'modules/creditReport'
import {
  listSupportingDocuments,
  selectAllSupportingDocuments,
} from 'modules/supportingDocument'
import {
  mapToAdviserNotes,
  mapToApplicants,
  mapToBusinesses,
  mapToTrusts,
} from './mappers'
import { Applicants } from './applicants'
import { InfoOverview } from './loanInfoOverview'
import { AdviserNotes } from './adviserNotes'
import { ReferenceDetails } from './referenceDetails'
import { HouseholdInfo } from './householdInfo'
import { Businesses } from './businesses'
import { BrokerInfo } from './brokerInfo'
import { XaiConnections } from './xaiConnections'
import { Trusts } from './trusts'
import { SupportingDocs } from './supportingDocs'
import { ActivityLog } from './activityLog'
import { CreditRiskRating } from './creditRiskRating'
import { APPLICATION_INFO_SECTION_IDS } from './constants'
import { SidebarNavigation } from '../sidebarNavigation'

const checkIsAllCreditReportPassed = (individualBorrowers = [], creditReports = []) => {
  let isAllCreditReportPassed = false

  if (isNilOrEmpty(individualBorrowers) || isNilOrEmpty(creditReports)) return false

  for (const borrower of individualBorrowers) {
    const hasPassedCreditCheck = creditReports.find(
      (creditReport) => creditReport.profile.entityId === borrower.entityId
    )

    if (!hasPassedCreditCheck) {
      isAllCreditReportPassed = false
      break
    }

    isAllCreditReportPassed = true
  }

  return isAllCreditReportPassed
}

// eslint-disable-next-line react/display-name
export const Info = ({ ...props }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [activeSection, setActiveSection] = useState('loan-info')

  const application = useSelector(selectApplicationById(id))

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)

  const supportingDocuments = useSelector(selectAllSupportingDocuments)

  const {
    brokerInfo = {},
    detailedComments = [],
    householdInfo,
    legalEntities = [],
    relatedPeople = [],
  } = application

  const { individuals = [], companies = [] } = legalEntities

  const individualBorrowers =
    individuals?.filter((i) => i.applicantRole === 'Borrower') || []

  const individualEntityIds = individuals?.map(({ entityId }) => entityId) ?? []

  const creditReports = useSelector(selectAllCreditReports)

  const loanInfo = {
    totalLoanAmount: application.totalLoanAmount,
    requestedLoans: application.requestedLoans,
    contributionFunds: application.contributionFunds,
    needsAnalysis: application.needsAnalysis,
    loanPurpose: application.loanPurpose,
    legalEntities: application.legalEntities,
  }

  const applicants = mapToApplicants(individuals)

  const adviserNotes = mapToAdviserNotes(detailedComments)

  const businesses = mapToBusinesses(companies)

  const trusts = mapToTrusts(application?.legalEntities?.trusts, relatedPeople)

  const referenceDetails = {
    brokerReference: application?.reference?.brokerReference,
    mycrmReference: application?.reference?.externalReference,
  }

  const creditRiskRating = application?.creditRiskRating

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
    dispatch(listSupportingDocuments(id))
  }, [dispatch, id])

  useEffect(() => {
    if (individualEntityIds.length > 0) {
      individualEntityIds.forEach((id) => dispatch(getCreditReport(id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, individualEntityIds.length])

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    const root = document.getElementById('router-root')
    if (root) {
      const updateCurrentNavigationItemToActive = (
        sectionId,
        topOffset,
        componentHeight
      ) => {
        const exitPoint = topOffset + componentHeight + 195
        const entryPoint = topOffset + 195
        const currentScroll = root.scrollTop
        if (currentScroll >= entryPoint && currentScroll < exitPoint) {
          setActiveSection(sectionId)
        }
      }

      const handler = () => {
        Object.values(APPLICATION_INFO_SECTION_IDS).forEach((sectionId) => {
          const element = document.getElementById(sectionId)

          if (element) {
            updateCurrentNavigationItemToActive(
              sectionId,
              element.offsetTop,
              element.clientHeight,
              'application-info'
            )
          }
        })
      }

      root.addEventListener('scroll', handler)

      return () => {
        root.removeEventListener('scroll', handler)
      }
    }
  }, [id])

  if (isFetchingApplication) {
    return (
      <div {...props}>
        <Loading />
      </div>
    )
  }

  return (
    <Row justify="center" style={s('h-full px-24', { paddingBottom: '17%' })}>
      <Col span={4} style={s('sticky px-14 ', { top: 148 })}>
        <SidebarNavigation
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </Col>
      <Col span={20}>
        <div {...props}>
          <InfoOverview loanInfo={loanInfo} />

          <Applicants applicants={applicants} />

          <Trusts trusts={trusts} />

          <Businesses businesses={businesses} />

          <XaiConnections
            individualBorrowers={individualBorrowers}
            isAllCreditReportsPassed={checkIsAllCreditReportPassed(
              individualBorrowers,
              creditReports
            )}
            loanApplicationId={id}
          />

          <CreditRiskRating crr={creditRiskRating} />

          <HouseholdInfo applicants={applicants} householdInfo={householdInfo} />

          <AdviserNotes adviserNotes={adviserNotes} />

          <SupportingDocs documents={supportingDocuments} />

          <BrokerInfo info={brokerInfo} />

          <ReferenceDetails referenceDetails={referenceDetails} />

          <ActivityLog loanApplicationId={id} />
        </div>
      </Col>
    </Row>
  )
}
