import React from 'react'
import { Col, Row } from '@horizon/styled'
import { styled, s } from '@horizon/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '../components'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { Loading } from '@horizon/components'
import { TEST_IDS } from '@horizon/constants'

const { HOUSEHOLD_INFO } = APPLICATION_INFO_SECTION_IDS
const Container = styled.div({ marginBottom: 52 })
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const ContentCard = styled(ContentCardBase)(s('mb-4'))
const Heading = styled.div(
  s('mb-2 text-sm text-primary font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const HouseholdInfo = ({ applicants, householdInfo, ...props }) => {
  if (!Array.isArray(householdInfo)) return <Loading />

  return (
    <Container id={HOUSEHOLD_INFO} {...props}>
      <SectionTitle title="Household Information" />

      <ContentCard>
        <Row gutter={[16, 16]}>
          {householdInfo?.map((householdDetail, index) => (
            <Col key={applicants[index].entityId} span={24}>
              <Heading>{applicants[index].fullName}</Heading>

              <Row justify="start">
                <Col span={12}>
                  <DataDisplay
                    label="No. of Dependants"
                    testId={`${TEST_IDS.HOUSEHOLD_DEP_NO}${index}`}
                    value={householdDetail?.numberOfDependants}
                  />
                </Col>

                <Col span={12}>
                  <DataDisplay
                    label="No. of Adults"
                    testId={`${TEST_IDS.HOUSEHOLD_ADULT_NO}${index}`}
                    value={householdDetail?.numberOfAdults}
                  />
                </Col>
              </Row>

              {isNotNilOrEmpty(householdDetail?.dependants) &&
                householdDetail?.dependants?.map((dependant, externalRef) => (
                  <div key={externalRef} style={s('mt-2')}>
                    <Row justify="start">
                      <Col span={6}>
                        <DataDisplay label="Name:" value={dependant?.name} />
                      </Col>

                      <Col span={6}>
                        <DataDisplay label="Age:" value={dependant?.age} />
                      </Col>
                    </Row>
                  </div>
                ))}
            </Col>
          ))}
        </Row>
      </ContentCard>
    </Container>
  )
}

export { HouseholdInfo }
