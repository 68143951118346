import BaseService from '../baseService'

import { isNil } from '@solta/ramda-extra'

export class NoteService extends BaseService {
  fetchNotes(loanApplicationId) {
    if (isNil(loanApplicationId))
      throw new Error('Please provide a valid loan application id')

    return this.client
      .get(`notes?filter[loanApplicationId]=${loanApplicationId}`)
      .json()
  }

  updateNote(id, note) {
    const config = {
      json: note,
    }

    return this.client.put(`notes/${id}`, config).json()
  }

  deleteNote(id) {
    return this.client.delete(`notes/${id}`).json()
  }

  createNote({ loanApplicationId, source, content }) {
    const config = {
      json: { loanApplicationId, source, content },
    }

    return this.client.post(`notes`, config).json()
  }
}
