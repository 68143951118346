/* eslint-disable default-case */
import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { Row, Col } from '@horizon/styled'
import { RealAssetOverview } from './RealAssetOverview'
import { NonRealAssetOverview } from './NonRealAssetOverview'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { ContentCard } from 'features/loanAssessment/info/components'
import { NoteSection } from 'features/common/NoteSection'
import { useParams } from 'react-router-dom'

export const ViewAssetDetail = ({ realAssetData, nonRealAssetData }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
    cursor: 'pointer',
    path: { stroke: s('text-primry').color },
  })
  const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
    cursor: 'pointer',
    path: { stroke: s('text-primry').color },
  })
  const CollapseButton = styled.button(
    s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
    {
      cursor: 'pointer',
      backgroundColor: 'white',
      fontSize: 14,
      borderRadius: 6,
    }
  )
  const ViewDetailsButton = styled.button(
    s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
    {
      cursor: 'pointer',
      backgroundColor: 'white',
      fontSize: 14,
      borderRadius: 6,
    }
  )
  let realAssetArray = []
  if (isNotNilOrEmpty(realAssetData?.financials)) {
    realAssetArray = realAssetData?.financials
  }

  let nonRealAssetArray = []
  if (isNotNilOrEmpty(nonRealAssetData?.financials)) {
    nonRealAssetArray = nonRealAssetData?.financials
  }

  const findAssetType = (val1, val2, val3, val4) => {
    if (isNotNilOrEmpty(val1)) return val1
    if (isNotNilOrEmpty(val2)) return val2
    if (isNotNilOrEmpty(val3)) return val3
    if (isNotNilOrEmpty(val4)) return val4
    return '-'
  }

  const Subtitle = styled.p(
    s('font-semi-bold mb-3 mt-5 text-black text-lg', {
      letterSpacing: '0.01em',
      lineHeight: 1.5,
    })
  )

  // eslint-disable-next-line complexity
  const findAssetDescriptionField = (financial) => {
    let description
    let domainType
    switch (financial.domainType) {
      case 'Motor Vehicle and Transport':
        description = financial.motorVehicle.description
        domainType = financial.motorVehicle.domainType
        break
      case 'Financial Asset':
        description = financial.financialAsset.description
        domainType = financial.financialAsset.domainType
        break
      case 'Business':
        description = financial.business.mainBusinessActivity
        domainType = 'Business'
        break
      case 'Tools of Trade':
        description = financial.toolsOfTrade.description
        domainType = financial.toolsOfTrade.domainType
        break
      default:
        description = financial.otherAsset.description
        domainType = financial.otherAsset.domainType
    }
    return { description, domainType }
  }

  const { id } = useParams()
  return (
    <div>
      {isExpanded ? (
        <div>
          {isNotNilOrEmpty(realAssetData?.financials) ? (
            <div>
              <Subtitle>Real Estate Assets</Subtitle>
              <Row>
                {realAssetArray.map((realAssetFinancial) => {
                  return realAssetFinancial?.transaction === 'Purchasing' ? (
                    <></>
                  ) : (
                    <Col key={realAssetFinancial.id} span={8}>
                      <RealAssetOverview
                        address={realAssetFinancial?.address}
                        domainType={findAssetType(
                          realAssetFinancial?.commercial?.domainType,
                          realAssetFinancial?.industrial?.domainType,
                          realAssetFinancial?.residential?.domainType,
                          realAssetFinancial?.rural?.domainType
                        )}
                        ownerships={realAssetFinancial?.percentOwned.owners}
                        entities={realAssetFinancial?.userPercentOwned.map(
                          ({
                            personName,
                            businessName,
                            trustName,
                            companyName,
                            _id,
                          }) => ({
                            personName,
                            businessName,
                            trustName,
                            companyName,
                            id: _id,
                          })
                        )}
                        estimatedValue={realAssetFinancial?.estimatedValue.value}
                        estimatedBasis={
                          realAssetFinancial?.estimatedValue.estimateBasis
                        }
                        toBeSold={realAssetFinancial?.toBeSold}
                        toBeUsedAsSecurity={realAssetFinancial?.toBeUsedAsSecurity}
                        transactionType={realAssetFinancial?.transaction}
                      />
                    </Col>
                  )
                })}
              </Row>

              <Subtitle>Non Real Estate Assets</Subtitle>

              <Row>
                {nonRealAssetArray.map((nonRealAssetfinancial) => (
                  <Col key={nonRealAssetfinancial.id} span={8}>
                    <NonRealAssetOverview
                      key={nonRealAssetfinancial.id}
                      domainType={
                        findAssetDescriptionField(nonRealAssetfinancial).domainType
                      }
                      description={
                        findAssetDescriptionField(nonRealAssetfinancial).description
                      }
                      ownerships={nonRealAssetfinancial?.percentOwned.owners}
                      entities={nonRealAssetfinancial?.userPercentOwned.map(
                        ({
                          personName,
                          businessName,
                          trustName,
                          companyName,
                          _id,
                        }) => ({
                          personName,
                          businessName,
                          trustName,
                          companyName,
                          id: _id,
                        })
                      )}
                      estimatedValue={nonRealAssetfinancial?.estimatedValue.value}
                      estimatedBasis={
                        nonRealAssetfinancial?.estimatedValue.estimateBasis
                      }
                      toBeSold={nonRealAssetfinancial?.toBeSold}
                      toBeUsedAsSecurity={nonRealAssetfinancial?.toBeUsedAsSecurity}
                    />
                  </Col>
                ))}
              </Row>

              <Row style={s('mb-3 mt-4')} gutter={[0, 0]}>
                <Col span={24}>
                  <NoteSection
                    id="note-section"
                    loanApplicationId={id}
                    source="loan-applications/financial/assets"
                  />
                </Col>
              </Row>

              <CollapseButton onClick={() => setIsExpanded(false)}>
                <Row style={s('justify-center')}>
                  <div>Collapse</div>
                  <ChevronUpIcon width={20} height={20} />
                </Row>
              </CollapseButton>
            </div>
          ) : (
            <Row>
              <Col>
                <ContentCard style={s('mb-4')}>No data yet</ContentCard>
              </Col>
              <Col span={24}>
                <NoteSection
                  id="note-section"
                  loanApplicationId={id}
                  source="loan-applications/financial/assets"
                />
              </Col>
              <Col>
                <CollapseButton onClick={() => setIsExpanded(false)}>
                  <Row style={s('justify-center')}>
                    <div>Collapse</div>
                    <ChevronUpIcon width={20} height={20} />
                  </Row>
                </CollapseButton>
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <div>
          <ViewDetailsButton onClick={() => setIsExpanded(true)}>
            <Row style={s('justify-center')}>
              <div>View Details</div>

              <ChevronDownIcon width={20} height={20} />
            </Row>
          </ViewDetailsButton>
        </div>
      )}
    </div>
  )
}
