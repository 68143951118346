import React from 'react'
import { s, styled } from '@horizon/styled/v2'

const Title = styled.h3(
  s('font-medium m-0 text-primary-hard text-xl', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)

const SectionTitle = ({ title, ...props }) => {
  return <Title {...props}>{title}</Title>
}

export { SectionTitle }
