import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { ContentCard, DataDisplay, SectionTitle } from '../components'
import { formatBoolean, formatCurrency, formatNull } from 'utils/formatters'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'

import { TEST_IDS } from '@horizon/constants'

const { LOAN_INFO } = APPLICATION_INFO_SECTION_IDS

const InfoSegmentTitle = styled.h4(s('text-Base mb-3'))
const InfoSegment = styled.div(s('flex flex-column'), {
  borderBottom: '1px solid #E4E6E6',
})

const InfoRow = styled.div(s('flex flex-row mb-6'))
const SubTitleAndValueContainer = styled.div(s('flex flex-column mr-4', { width: 204 }))
const SubSection = styled.div(s('text-sm font-normal text-primary font-semibold mb-3'))
const SubTitle = styled.div(s('text-sm font-normal'), { color: '#515856' })
const Value = styled.div(s('font-semibold mt-1'))

const TotalLoanAmount = styled.div(s('text-xl font-bold'), { color: '#490B2D' })

const RequestTitle = styled.div(s('text-xs font-medium mb-2 mt-4'), {
  color: '#490B2D',
})

const FundTitle = styled.div(s('text-xs mb-2'), { color: '#490B2D' })

const AnalysisExpansionButton = styled.button(
  s('bg-white text-base border-0 m-10 font-semibold cursor-pointer'),
  {
    color: '#490B2D',
    hover: { cursor: 'cursor-pointer' },
  }
)

const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
  cursor: 'pointer',
  path: { stroke: s('text-primary').color },
})
const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primary').color },
})

export const InfoOverview = ({ loanInfo = {} }) => {
  const {
    totalLoanAmount,
    requestedLoans,
    contributionFunds,
    needsAnalysis,
    loanPurpose,
  } = loanInfo
  const [isExpanded, setIsExpanded] = React.useState(false)
  const toggleExpand = () => {
    const newValue = !isExpanded
    setIsExpanded(newValue)
  }

  return (
    <div id={LOAN_INFO} style={{ marginBottom: 52 }}>
      <SectionTitle title="Loan Info" style={s('mb-4')} />
      <ContentCard>
        <InfoSegment>
          <InfoSegmentTitle style={s('mt-0')}>Loan Requested</InfoSegmentTitle>
          <Row>
            <Col span={24}>
              <SubTitleAndValueContainer>
                <SubTitle>Total Loan Requested</SubTitle>
                <TotalLoanAmount data-test-id={TEST_IDS.TOTAL_LOAN_REQUESTED}>
                  {formatCurrency(totalLoanAmount)}
                </TotalLoanAmount>
              </SubTitleAndValueContainer>
            </Col>
          </Row>

          {requestedLoans?.map(
            ({ amountRequested, borrowers, statementCycle, term }, index) => (
              <>
                <RequestTitle>REQUEST {index + 1}</RequestTitle>
                <Row>
                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <DataDisplay
                        label="Loan amount"
                        testId={`${TEST_IDS.LOAN_AMOUNT}${index}`}
                        value={formatCurrency(amountRequested)}
                      />
                    </SubTitleAndValueContainer>
                  </Col>

                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      {borrowers?.owners.length === 1 ? (
                        <DataDisplay
                          testId={`${TEST_IDS.LOAN_STRUCTURE}${index}`}
                          label="Loan Structure"
                          value="Single"
                        />
                      ) : (
                        <DataDisplay label="Loan Structure" value="Split" />
                      )}
                    </SubTitleAndValueContainer>
                  </Col>

                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <DataDisplay
                        label="Term Type Cycle"
                        testId={`${TEST_IDS.LOAN_TERM_CYCLE}${index}`}
                        value={`${term?.paymentType}/${statementCycle}`}
                      />
                    </SubTitleAndValueContainer>
                  </Col>

                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <DataDisplay
                        label="Total Term Type"
                        testId={`${TEST_IDS.LOAN_TERM_TYPE}${index}`}
                        value={term?.totalTermType}
                      />
                    </SubTitleAndValueContainer>
                  </Col>
                </Row>

                <Row style={s('mb-6')}>
                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <DataDisplay
                        label="Total Duration"
                        testId={`${TEST_IDS.LOAN_DURATION}${index}`}
                        value={`${
                          term.totalTermDuration
                        } ${term.totalTermUnits.toLowerCase()}`}
                      />
                    </SubTitleAndValueContainer>
                  </Col>

                  {term?.paymentType === 'Interest Only' && (
                    <Col span={6}>
                      <SubTitleAndValueContainer>
                        <DataDisplay
                          label="IO Duration"
                          value={
                            term.paymentTypeDuration && term.paymentTypeUnits
                              ? `${
                                  term.paymentTypeDuration
                                } ${term.paymentTypeUnits.toLowerCase()}`
                              : '-'
                          }
                        />
                      </SubTitleAndValueContainer>
                    </Col>
                  )}

                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <DataDisplay
                        label="Proportions"
                        testId={`${TEST_IDS.LOAN_PROPORTIONS}${index}`}
                        value={borrowers?.proportions}
                      />
                    </SubTitleAndValueContainer>
                  </Col>

                  <Col span={6}>
                    <SubTitleAndValueContainer>
                      <DataDisplay
                        label="Purpose"
                        testId={`${TEST_IDS.LOAN_PURPOSE}${index}`}
                        value={loanPurpose?.primaryPurpose}
                      />
                    </SubTitleAndValueContainer>
                  </Col>
                </Row>

                <Row key={index} gutter={[24, 0]}>
                  {borrowers?.owners.map(({ name, percent }, index) => (
                    <Col span={6} key={index}>
                      <SubTitleAndValueContainer>
                        <DataDisplay
                          testId={`${TEST_IDS.LOAN_OWNERSHIP}${index}`}
                          label={`${name}'s Ownership`}
                          value={`${percent}%`}
                        />
                      </SubTitleAndValueContainer>
                    </Col>
                  ))}
                </Row>
              </>
            )
          )}
        </InfoSegment>

        <InfoSegment>
          <InfoSegmentTitle>Contribution Funds</InfoSegmentTitle>
          <InfoRow>
            <SubTitleAndValueContainer>
              <SubTitle>Total Contribution Funds</SubTitle>
              <Value style={s('font-extrabold')}>
                {formatCurrency(contributionFunds?.totalAmount)}
              </Value>
            </SubTitleAndValueContainer>
          </InfoRow>

          {contributionFunds?.funds?.map(
            ({ domainType, amount, description }, index) => (
              <>
                <FundTitle>
                  FUND {index + 1} - {domainType}
                </FundTitle>
                <InfoRow>
                  <SubTitleAndValueContainer>
                    <DataDisplay label="Amount" value={formatCurrency(amount)} />
                  </SubTitleAndValueContainer>

                  <SubTitleAndValueContainer>
                    <DataDisplay label="Description" value={description} />
                  </SubTitleAndValueContainer>
                </InfoRow>
              </>
            )
          )}
        </InfoSegment>
        {!isExpanded ? (
          <InfoSegment style={s('border-transparent')}>
            <InfoSegmentTitle>Analysis</InfoSegmentTitle>
            <AnalysisExpansionButton onClick={toggleExpand}>
              <Row style={s('justify-center')}>
                <div>View Analysis</div>
                <ChevronDownIcon width={20} height={20} />
              </Row>
            </AnalysisExpansionButton>
          </InfoSegment>
        ) : (
          <InfoSegment style={s('border-transparent')}>
            <InfoSegmentTitle>Analysis</InfoSegmentTitle>
            <SubSection>Application Preferences</SubSection>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Conflict Exists"
                    value={formatBoolean(needsAnalysis?.preferences?.conflictExists)}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Interest Rate Type"
                    value={needsAnalysis?.preferences?.interestRateType}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Preferred Lenders"
                    value={formatBoolean(needsAnalysis?.preferences?.preferredLenders)}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Preferred Lenders Details"
                    value={needsAnalysis?.preferences?.preferredLendersDetails}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection>Funds Access Type Details</SubSection>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Offset Account"
                    value={formatNull(
                      needsAnalysis?.preferences?.fundsAccessTypeDetails?.offsetAccount
                        ?.reason?.description
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Importance"
                    value={
                      needsAnalysis?.preferences?.fundsAccessTypeDetails?.offsetAccount
                        ?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Risks Explained"
                    value={formatBoolean(
                      needsAnalysis?.preferences?.fundsAccessTypeDetails?.offsetAccount
                        ?.risksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection>Redraw</SubSection>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Importance"
                    value={
                      needsAnalysis?.preferences?.fundsAccessTypeDetails?.redraw
                        ?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Risks Explained"
                    value={formatBoolean(
                      needsAnalysis?.preferences?.fundsAccessTypeDetails?.redraw
                        ?.risksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection style={s('mt-2')}>
              Interest Rate Preferences: Fixed and Variable Rate
            </SubSection>
            <Row style={s('mb-2')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Importance"
                    value={
                      needsAnalysis?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Risks Explained"
                    value={formatBoolean(
                      needsAnalysis?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.risksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Fixed Period Duration"
                    value={formatNull(
                      needsAnalysis?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.fixedPeriodDuration?.length
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Reason"
                    value={formatNull(
                      needsAnalysis?.preferences?.interestRateTypeDetails
                        ?.fixedAndVariableRate?.reason?.description
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Fixed Rate Importance"
                    value={
                      needsAnalysis?.preferences?.interestRateTypeDetails?.fixedRate
                        ?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Variable Rate Importance"
                    value={
                      needsAnalysis?.preferences?.interestRateTypeDetails?.variableRate
                        ?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection style={s('mt-2')}>
              Repayment Type Details: Interst in Advance
            </SubSection>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Importance"
                    value={
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.interestInAdvance?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Interest Only Importanace"
                    value={
                      needsAnalysis?.preferences?.repaymentTypeDetails?.interestOnly
                        ?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Line of Credit"
                    value={
                      needsAnalysis?.preferences?.repaymentTypeDetails?.lineOfCredit
                        ?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Offset Risk Explained"
                    value={formatNull(
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.interestInAdvance?.offsetRisksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection style={s('mt-2')}>Principal and Interest</SubSection>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Importance"
                    value={
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.importance
                    }
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Frequency"
                    value={formatNull(
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.repaymentFrequency
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Risk Explained"
                    value={formatBoolean(
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.risksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Offset Risk Explained"
                    value={formatNull(
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.offsetRisksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection style={s('mt-2')}>
              Purpose of Application: Refinancing & Consolidation
            </SubSection>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Increase Interest Risk"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation
                        ?.explainedIncreaseInterestRisk
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Increase Loan Term Risk"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation
                        ?.explainedIncreaseLoanTermRisk
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Plan to Close/Reduce Credit card"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation
                        ?.planToCloseOrReduceCreditCard
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Offset Risk Explained"
                    value={formatNull(
                      needsAnalysis?.preferences?.repaymentTypeDetails
                        ?.principalAndInterest?.offsetRisksExplained
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <SubSection style={s('mt-2')}>Refinancing Reason</SubSection>
            <Row style={s('mb-2')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Close to End of Current Loan Term"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.closeToEndOfCurrentLoanTerm
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Debt Consolidation"
                    value={formatNull(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.closeToEndOfCurrentLoanTerm?.debtConsolidationDetails
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Dissatifsfaction with Current Lender"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.closeToEndOfCurrentLoanTerm?.dissatisfactionWithCurrentLender
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Greater Flexbilitiy"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.closeToEndOfCurrentLoanTermgreaterFlexibility
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <Row style={s('mb-4')}>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Increase Total Loan Amount"
                    value={formatBoolean(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.increaseTotalLoanAmount
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Lower Interest Rate"
                    value={formatNull(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.lowerInterestRateDetails
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Reduced Repayment"
                    value={formatNull(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.reducedRepaymentsDetails
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
              <Col span={6}>
                <SubTitleAndValueContainer>
                  <DataDisplay
                    label="Other"
                    value={formatNull(
                      needsAnalysis?.refinancingAndConsolidation?.refinancingReason
                        ?.otherDetails
                    )}
                  />
                </SubTitleAndValueContainer>
              </Col>
            </Row>
            <AnalysisExpansionButton onClick={toggleExpand}>
              <Row style={s('justify-center')}>
                <div>Collapse</div>
                <ChevronUpIcon width={20} height={20} />
              </Row>
            </AnalysisExpansionButton>
          </InfoSegment>
        )}
      </ContentCard>
    </div>
  )
}
