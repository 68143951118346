import React from 'react'
import { s } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { buildAddress, formatCurrency } from 'utils/formatters'
import {
  ContentCard,
  DataDisplay,
  Divider,
} from 'features/loanAssessment/info/components'

export const Employment = ({ income = {}, ...props }) => {
  const { employment, totalIncomeAmount } = income
  const {
    address,
    basis,
    businessName,
    duration: { length, units },
    endDate,
    occupation,
    onProbation,
    startDate,
    status,
    type,
  } = employment

  const { city, countryISO, streetName, streetNumber, suburb, unit } = address

  const formattedDuration = `${length} ${units}`
  const formattedIncomeAmount = `${formatCurrency(totalIncomeAmount)} Yearly (Gross)`

  return (
    <ContentCard style={{ minHeight: 440 }} {...props}>
      <Row gutter={[20, 16]}>
        <Col span={12}>
          <DataDisplay label="Status" value={status} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Employer" value={businessName} />
        </Col>

        <Col span={24}>
          <DataDisplay label="Income" value={formattedIncomeAmount} />
        </Col>

        <Col span={24}>
          <DataDisplay
            label="Employer Address"
            value={buildAddress(
              {
                streetNumber,
                streetName,
              },
              suburb,
              city,
              countryISO,
              unit
            )}
          />
        </Col>

        <Col span={24}>
          <Divider style={s('mt-2')} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Start Date" value={startDate} />
        </Col>

        <Col span={12}>
          <DataDisplay label="End Date" value={endDate || 'Present'} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Duration" value={formattedDuration} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Probation" value={onProbation ? 'Yes' : 'No'} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Basis" value={basis} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Type" value={type} />
        </Col>

        <Col span={24}>
          <DataDisplay label="Occupation" value={occupation} />
        </Col>
      </Row>
    </ContentCard>
  )
}
