import React from 'react'
import { s } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { formatOwnershipString } from './formatOwnershipString'
import { formatCurrency } from 'utils/formatters'
import {
  ContentCard,
  DataDisplay,
  Divider,
} from 'features/loanAssessment/info/components'

export const OtherIncome = ({
  amount = '-',
  isTaxable = '-',
  frequency = '-',
  ownerships = [],
  entities = [],
  verificationStatus = '-',
  type,
  ...props
}) => (
  <ContentCard style={{ minHeight: 360 }} {...props}>
    <Row gutter={[20, 16]}>
      <Col span={12}>
        <DataDisplay label="Type" value={type} />
      </Col>

      <Col span={24}>
        <Divider style={s('mt-2')} />
      </Col>

      <Col span={12}>
        <DataDisplay label="Amount" value={formatCurrency(amount)} />
      </Col>

      <Col span={12}>
        <DataDisplay label="Frequency" value={frequency} />
      </Col>

      <Col span={12}>
        <DataDisplay
          label="Ownership"
          value={formatOwnershipString(entities, ownerships)}
        />
      </Col>

      <Col span={12}>
        <DataDisplay label="Taxable" value={isTaxable ? 'Yes' : 'No'} />
      </Col>

      <Col span={12}>
        <DataDisplay label="Verification Status" value={verificationStatus} />
      </Col>
    </Row>
  </ContentCard>
)
