import React from 'react'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'

const PropertyDetails = ({ propertyDetails }) => {
  const {
    valocityId,
    propertyCatagory,
    territorialAuthority,
    titleNumbers,
    owners,
    legalDescription,
  } = propertyDetails

  return (
    <ContentCard>
      <Row>
        <Col span={8}>
          <DataDisplay label="Valocity Id" value={valocityId} valueStyles={s('mb-8')} />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Property Category"
            value={propertyCatagory}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Territorial Authority"
            value={territorialAuthority}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Title Number"
            value={titleNumbers}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay label="Owners" value={owners} valueStyles={s('mb-8')} />
        </Col>
        <Col span={24}>
          <DataDisplay
            label="Legal Description"
            value={legalDescription}
            valueStyles={s('mb-8')}
          />
        </Col>
      </Row>
    </ContentCard>
  )
}

export { PropertyDetails }
