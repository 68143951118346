/* eslint-disable complexity */
import React from 'react'
import { Col, Row } from '@horizon/styled'
import { s, styled } from '@horizon/styled/v2'
import { DataDisplay } from '../components'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { insertSeparatorsToString, formatFullName } from 'utils/formatters'

const Container = styled.div()
const Heading = styled.div(
  s('mb-2 text-xs text-primary font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

// eslint-disable-next-line complexity
const Trust = ({ trust, index, ...props }) => {
  const {
    trustName,
    contact,
    applicantRole,
    establishmentDate,
    beneficiaries,
    trustees,
  } = trust

  const beneficiaryNames = beneficiaries
    .map((beneficiary) => beneficiary?.personName?.knownAs || '-')
    .join(', ')

  return (
    <Container {...props}>
      <Heading>TRUST {index + 1}</Heading>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <DataDisplay label="Business Name" value={trustName} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Type" value={applicantRole} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Establishment Date" value={establishmentDate ?? '-'} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Beneficiaries" value={beneficiaryNames || '-'} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Business Address"
            value={contact.address.fullAddress || '-'}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="Email Address" value={contact.email || '-'} />
        </Col>
      </Row>
      {isNotNilOrEmpty(trustees) ? (
        <div style={s('mt-4')}>
          <Heading>TRUSTEES</Heading>
          {trustees.map((trustee) => (
            <Row style={s('mb-4')} key={trustee.entityId}>
              <Col span={12}>
                <DataDisplay
                  label="Full Name"
                  value={
                    formatFullName({
                      firstName: trustee?.firstName,
                      lastName: trustee?.surname,
                    }) || '-'
                  }
                />
              </Col>
              <Col span={12}>
                <DataDisplay
                  label="Phone Number"
                  value={
                    insertSeparatorsToString(trustee?.phone, ' ', `### ### ####`) ||
                    insertSeparatorsToString(
                      trustee?.phoneNumber,
                      ' ',
                      `## ## ### ####`
                    ) ||
                    '-'
                  }
                />
              </Col>

              <Col span={12}>
                <DataDisplay label="Email Address" value={trustee?.email || '-'} />
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <div />
      )}
    </Container>
  )
}

export { Trust }
