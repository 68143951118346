import React from 'react'
import { styled, s } from '@horizon/styled/v2'

const ChartBox = styled.div(
  s('text-grey-500 px-3 py-2 text-xs', {
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  })
)
const LabelBox = styled.div(s('mb-1'))
const Text = styled.div(s('font-semibold text-grey-800'))
const ChartTooltip = ({ tooltipData: { label, frequency } }) => (
  <ChartBox>
    <LabelBox>{label}</LabelBox>
    <Text>{frequency}</Text>
  </ChartBox>
)

export { ChartTooltip }
