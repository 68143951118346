import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { s, styled } from '@horizon/styled/v2'
import {
  selectAllCreditReports,
  selectIsGetCreditReportPending,
  selectIsStartCreditReportPending,
  startCreditCheck,
} from 'modules/creditReport'
import { ReactComponent as CheckBase } from '@horizon/components/src/assets/images/check.svg'
import { DottedCircleLoader } from '@horizon/components'
import { PersonalInfo } from './PersonalInfo'
import { Addresses } from './Addresses'
import { CreditCheckResult } from './CreditCheckResult'
import { ContentCard as ContentCardBase, Divider as DividerBase } from '../components'

const Check = styled(CheckBase)({ color: '#515856' })
const Checkbox = styled.div(s('mr-4 h-1 w-1 rounded-md bg-secondary'))
const Container = styled.div()
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const CreditCheckContainer = styled.div(s('flex items-center mb-4'))
const Divider = styled(DividerBase)(s('my-5'))
const RequestLink = styled.div(
  s('text-base font-semibold text-primary', {
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

// eslint-disable-next-line complexity
const Applicant = ({ applicant = {}, index, ...props }) => {
  const dispatch = useDispatch()
  const { id: loanApplicationId } = useParams()

  const { addresses, entityId } = applicant

  const creditReports = useSelector(selectAllCreditReports)
  const isGetCreditReportPending = useSelector((state) =>
    selectIsGetCreditReportPending(state, entityId)
  )
  const isStartCreditCheckPending = useSelector((state) =>
    selectIsStartCreditReportPending(state, entityId)
  )

  const applicantReport = creditReports.find(
    (report) => report?.profile?.entityId === entityId
  )

  const handleCreditCheck = ({ entityId, loanApplicationId, requestId }) =>
    dispatch(startCreditCheck({ entityId, loanApplicationId, requestId }))
  applicant.index = index
  return (
    <Container {...props}>
      <ContentCard>
        <PersonalInfo {...applicant} />

        <Divider />

        {!applicantReport && !isStartCreditCheckPending && (
          <RequestLink
            onClick={() =>
              handleCreditCheck({ entityId, loanApplicationId, requestId: entityId })
            }
          >
            Request Credit Check
          </RequestLink>
        )}

        {(isGetCreditReportPending || isStartCreditCheckPending) && (
          <DottedCircleLoader />
        )}

        {applicantReport && (
          <>
            <CreditCheckContainer>
              <Checkbox>
                <Check />
              </Checkbox>
              <RequestLink>Credit Check</RequestLink>
            </CreditCheckContainer>

            <CreditCheckResult creditReport={applicantReport} />
          </>
        )}

        <Divider />

        <Addresses addresses={addresses} applicantIndex={index} />
      </ContentCard>
    </Container>
  )
}

export { Applicant }
