import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@horizon/services'
import { thunkErrorProcessor } from '@horizon/error-standardizer'
import { affordabilityAssessmentService } from 'apiService'

export const conductAffordabilityAssessment = createAsyncThunk(
  'affordabilityAssessment/conductAffordabilityAssessment',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.conductAssessment(loanApplicationId)
      return null
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateAffordabilityAssessment = createAsyncThunk(
  'affordabilityAssessment/updateAffordabilityAssessment',
  async ({ loanApplicationId, assessmentReference }, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.updateAssessment(
        loanApplicationId,
        assessmentReference
      )

      return null
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const retrieveFinancialsData = createAsyncThunk(
  'affordabilityAssessment/retrieveFinancialsData',
  async ({ loanApplicationId, legalEntityId }, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.retrieveFinancialsData(
        loanApplicationId,
        legalEntityId
      )

      return null
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const exportUMIReport = createAsyncThunk(
  'affordabilityAssessment/exportUMIReport',
  async ({ referenceId }, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.exportUMIReport(referenceId)
      return null
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const creditReportAdapter = createEntityAdapter()

const initialState = creditReportAdapter.getInitialState()

const affordabilityAssessmentSlice = createSlice({
  name: 'affordabilityAssessment',
  initialState,
  reducers: {},
})

const { reducer: affordabilityAssessmentReducer } = affordabilityAssessmentSlice

export { affordabilityAssessmentReducer }
