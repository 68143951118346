import React from 'react'
import { DataDisplay } from '@horizon/components/src/v2'

export const SecurityOwnerSegment = ({ owner, legalEntities }) => {
  const getEntityName = (applicantId) => {
    const listOfEntities = [
      ...(legalEntities?.companies || []),
      ...(legalEntities?.individuals || []),
      ...(legalEntities?.trusts || []),
    ]

    const foundEntity = listOfEntities.find(
      (entity) => entity?.entityId === applicantId
    )

    return foundEntity?.fullName
  }

  return (
    <DataDisplay
      label={`${getEntityName(owner?.legalEntityId)}'s Ownership`}
      value={`${owner?.percent}%`}
    />
  )
}
