import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@horizon/services'
import { uploadFile } from '@horizon/redux.upload'

import { supportingDocumentService } from 'apiService'

export const listSupportingDocuments = createAsyncThunk(
  'supportingDocument/listSupportingDocuments',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      return await supportingDocumentService.list(loanApplicationId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createSupportingDocument = createAsyncThunk(
  'supportingDocument/createSupportingDocument',
  async (
    { classification, file, loanApplicationId, references, source },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { name: fileName, type: fileType } = file

      const result = await supportingDocumentService.create(
        classification,
        fileName,
        fileType,
        source,
        loanApplicationId,
        references
      )

      const fileToUpload = { presignedUrl: result.presignedUrl, fileDetails: file }

      await dispatch(uploadFile(fileToUpload))

      return {
        classification,
        file,
        loanApplicationId,
        presignedUrl: result.presignedUrl,
      }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const supportingDocumentAdapter = createEntityAdapter()

const initialState = supportingDocumentAdapter.getInitialState()

const supportingDocumentSlice = createSlice({
  name: 'supportingDocument',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listSupportingDocuments.fulfilled, (state, { payload }) =>
      supportingDocumentAdapter.setAll(state, payload)
    )
  },
})

const { reducer: supportingDocumentReducer } = supportingDocumentSlice

export { supportingDocumentReducer }
