import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { styled, s } from '@horizon/styled'

import { ReactComponent as DropdownIndicator } from '@horizon/components/src/assets/images/dropdownArrow.svg'

const { string, bool, object, array } = PropTypes

const selectStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      ...s('h-full', state.selectProps.width),
    }
  },

  control: (_, state) => {
    const basicStyle = s(
      'h-3 flex w-full show-box bg-white text-base pl-4 pr-5 rounded-lg',
      {
        borderColor: '#C9CECD',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      }
    )
    const disabledStyle = state.isDisabled
      ? s('bg-grey-200', {
          pointerEvents: 'none',
        })
      : {}

    return { ...basicStyle, ...disabledStyle }
  },

  option: (provided, state) => ({
    ...provided,
    ...s('bg-white text-xs', { '&:hover': s('bg-grey-light') }),
    ...s(state.isSelected ? 'text-black' : ''),
  }),

  menu: (provided) => ({
    ...provided,
    width: 'calc(100% + 24px)',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  }),

  valueContainer: (provided) => ({
    ...provided,
    ...s('p-0 pr-4 flex'),
  }),

  indicatorSeparator: () => s('hidden'),

  singleValue: (provided) => ({
    ...provided,
    ...s('text-base pr-4 flex', { lineHeight: 1.5, color: '#141616' }),
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      ...s('text-grey-500 text-base pr-4 flex', { lineHeight: 1.5 }),
    }
  },
}

const Root = styled.div(s('h-4 w-8'))

function DropdownFilter({
  name,
  type,
  placeholder,
  options,
  selected,
  isSearchable,
  disabled,
  containerProps,
  onSelectChange,
  width,
  ...otherProps
}) {
  return (
    <Root {...containerProps}>
      <Select
        placeholder={placeholder}
        value={selected}
        type={type}
        onChange={onSelectChange}
        options={options}
        menuPlacement="auto"
        styles={selectStyles}
        isDisabled={disabled}
        isSearchable={isSearchable}
        components={{
          DropdownIndicator,
        }}
        width={width}
        {...otherProps}
      />
    </Root>
  )
}

DropdownFilter.propTypes = {
  type: string,
  placeholder: string,
  options: array,
  isSearchable: bool,
  disabled: bool,
  containerProps: object,
}

DropdownFilter.defaultProps = {
  type: 'select',
  isSearchable: false,
}

export default DropdownFilter
