import React from 'react'
import PropTypes from 'prop-types'
import { includes, equals } from '@solta/ramda-extra'
import { useHistory, useLocation } from 'react-router-dom'
import { Tooltip } from './Tooltip'
import { styled, s } from '@horizon/styled/v2'

const Container = styled.div(s('relative'))
const IconButton = styled.div(
  s('flex items-center justify-center w-3 h-3 rounded-lg mb-3', {
    cursor: 'pointer',
  }),
  ({ isActive }) => isActive && s('bg-primary-soft')
)

export const NavItem = ({
  route,
  isExactRoute = false,
  tooltipLabel,
  Icon,
  ...otherProps
}) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [showTooltip, setShowTooltip] = React.useState(false)

  const checkIsActive = (currentPath) => {
    const comparatorFn = isExactRoute ? equals : includes

    return comparatorFn(currentPath, pathname)
  }
  const redirectTo = (toRoute) => history.push(toRoute)

  return (
    <Container {...otherProps}>
      <IconButton
        onClick={() => redirectTo(route)}
        isActive={checkIsActive(route)}
        onPointerEnter={() => setShowTooltip(true)}
        onPointerOut={() => setShowTooltip(false)}
      >
        <Icon
          stroke={s('text-grey-700').color}
          strokeWidth={2}
          width={24}
          height={24}
          style={s('text-grey-700', {
            pointerEvents: 'none',
          })}
        />
      </IconButton>

      <Tooltip isActive={showTooltip}>{tooltipLabel}</Tooltip>
    </Container>
  )
}

const { string, bool, object } = PropTypes

NavItem.propTypes = {
  route: string,
  isExactRoute: bool,
  tooltipLabel: string,
  Icon: object,
}
