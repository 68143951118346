import React from 'react'

import { styled, s } from '@horizon/styled/v2'

const Container = styled.tbody(s('flex flex-1 flex-column items-center justify-center'))
const Title = styled.tr(s('font-semibold text-sm my-4', { color: '#1E3C58' }))

function TableEmptyPlaceholder({ placeholderText = 'No data yet!' }) {
  return (
    <Container>
      <Title>
        <td>{placeholderText}</td>
      </Title>
    </Container>
  )
}

export default TableEmptyPlaceholder
