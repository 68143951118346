import React from 'react'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'

const PropertyFeatures = ({ propertyFeatures }) => {
  const {
    landArea,
    floorArea,
    landZone,
    landUse,
    numOfBedrooms,
    numOfBathrooms,
    improvements,
    yearBuilt,
    wallConstruction,
    roofConstruction,
  } = propertyFeatures

  return (
    <ContentCard>
      <Row>
        <Col span={8}>
          <DataDisplay label="Land Area" value={landArea} valueStyles={s('mb-8')} />
        </Col>
        <Col span={8}>
          <DataDisplay label="Floor Area" value={floorArea} valueStyles={s('mb-8')} />
        </Col>
        <Col span={8}>
          <DataDisplay label="Land Zone" value={landZone} valueStyles={s('mb-8')} />
        </Col>
        <Col span={8}>
          <DataDisplay label="Land Use" value={landUse} valueStyles={s('mb-8')} />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Number of Bedrooms"
            value={numOfBedrooms}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Number of Bathrooms"
            value={numOfBathrooms}
            valueStyles={s('mb-8')}
          />
        </Col>

        <Col span={8}>
          <DataDisplay
            label="Improvements"
            value={improvements}
            valueStyles={s('mb-8')}
          />
        </Col>

        <Col span={8}>
          <DataDisplay label="Year Built" value={yearBuilt} valueStyles={s('mb-8')} />
        </Col>

        <Col span={8}>
          <DataDisplay
            label="Wall Construction"
            value={wallConstruction}
            valueStyles={s('mb-8')}
          />
        </Col>

        <Col span={8}>
          <DataDisplay
            label="Roof Construction"
            value={roofConstruction}
            valueStyles={s('mb-8')}
          />
        </Col>
      </Row>
    </ContentCard>
  )
}

export { PropertyFeatures }
