import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { ReactComponent as Bell } from '@horizon/components/src/assets/images/bell.svg'

const BellIcon = styled(Bell)(s('h-2 text-primary w-2'))
const Button = styled.button(
  s(
    'bg-white border-1 border-primary border-solid flex items-center justify-center max-h-2 max-w-2 relative',
    { borderRadius: 6, cursor: 'pointer' }
  )
)
const NotificationBubble = styled.div(
  s('absolute bg-red flex items-center justify-center min-w-1 pin-bl-5 rounded-xl', {
    paddingLeft: 2,
    paddingRight: 2,
  })
)
const NotificationNumber = styled.p(s('m-0 font-normal text-white', { fontSize: 10 }))

export const NotificationsButton = ({ numberOfNotifications = 0, ...props }) => {
  const formattedNumberOfNotifications =
    numberOfNotifications > 99 ? '99+' : numberOfNotifications.toString()

  return (
    <Button {...props}>
      {numberOfNotifications > 0 && (
        <NotificationBubble>
          <NotificationNumber>{formattedNumberOfNotifications}</NotificationNumber>
        </NotificationBubble>
      )}

      <BellIcon />
    </Button>
  )
}
