import React from 'react'
import { styled, s } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { formatOwnershipString } from './formatOwnershipString'
import { formatBoolean, formatCurrency } from 'utils/formatters'

const InfoSegment = styled.div(s('flex flex-column my-4'), {
  borderBottom: '1px solid #E4E6E6',
})
const SubTitle = styled.div(s('font-semibold mb-4 text-primary text-sm'))

const formatFIName = (financialInstitution, otherFIName) =>
  financialInstitution === 'Other' ? otherFIName : financialInstitution

const formatLoanClearing = (clearingFromThisLoan, clearingFromThisLoanAmount) =>
  clearingFromThisLoan === 'Yes' ? formatCurrency(clearingFromThisLoanAmount) : 'No'

export const LiabilitiesOverview = ({
  clearingFromThisLoan = '-',
  clearingFromThisLoanAmount = '-',
  creditLimit = '-',
  entities = [],
  financialInstitution = '-',
  hasArrears = '-',
  interestRate = '-',
  isRegular = '-',
  isSecurity = '-',
  isTaxDeductable = '-',
  liabilityType = '-',
  loanPaymentScheduleType = '-',
  otherFIName = '-',
  outstandingBalance = '-',
  ownerships = [],
  paymentType = '-',
  repaymentAmount = '-',
  repaymentFrequency = '-',
  ...props
}) => {
  const isCreditCard = liabilityType === 'Credit Card'
  const formattedFIName = formatFIName(financialInstitution, otherFIName)
  const formattedLoanClearing = formatLoanClearing(
    clearingFromThisLoan,
    clearingFromThisLoanAmount
  )

  return (
    <ContentCard style={{ minHeight: 692 }} {...props}>
      <DataDisplay label="Type" value={liabilityType} />

      <InfoSegment />

      <Row gutter={[20, 16]}>
        <Col span={12}>
          <DataDisplay
            label="Ownership"
            value={formatOwnershipString(entities, ownerships)}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="Credit Limit" value={formatCurrency(creditLimit)} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Outstanding Balance"
            value={formatCurrency(outstandingBalance)}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="Interest Rate" value={`${interestRate}%`} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Financial Institution" value={formattedFIName} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Has Arrears" value={formatBoolean(hasArrears)} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Loan Clearing" value={formattedLoanClearing} />
        </Col>

        <Col span={12}>
          {!isCreditCard && <DataDisplay label="Security" value={isSecurity} />}
        </Col>
      </Row>

      <InfoSegment />

      <SubTitle>Repayment notes</SubTitle>

      {!isCreditCard && (
        <Row gutter={[20, 16]} style={s('mb-4')}>
          <Col span={12}>
            <DataDisplay label="Schedule type" value={loanPaymentScheduleType} />
          </Col>

          <Col span={12}>
            <DataDisplay label="Payment type" value={paymentType} />
          </Col>
        </Row>
      )}

      <Row gutter={[20, 16]}>
        <Col span={12}>
          <DataDisplay label="Amount" value={formatCurrency(repaymentAmount)} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Frequency" value={repaymentFrequency} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Tax deductable" value={formatBoolean(isTaxDeductable)} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Regular" value={formatBoolean(isRegular)} />
        </Col>
      </Row>
    </ContentCard>
  )
}
