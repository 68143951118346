import React from 'react'
import { Link } from 'react-router-dom'
import { styled, s } from '@horizon/styled'
import logo from '@horizon/components/src/assets/images/lms_logo_vertical.png'

const Container = styled.div(
  s('flex flex-column items-center h-full shadow-md bg-white z-1 pt-4'),
  {
    minWidth: 80,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
  }
)

const ImageContainer = styled(Link)(
  s('w-full mt-4 mb-5 no-decoration flex justify-center self-center')
)
const Img = styled.img(s('h-a self-start'), { width: 42 })
const HorizontalLine = styled.div(
  s('border-0 border-b-1 border-solid border-grey-200 w-3 mb-5')
)

export const PersistentDrawer = ({ homeRoute, children, ...otherProps }) => (
  <Container {...otherProps}>
    <ImageContainer to={homeRoute}>
      <Img src={logo} />
    </ImageContainer>

    <HorizontalLine />

    {children}
  </Container>
)
