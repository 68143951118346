import React, { useState } from 'react'

import { SwitcherButton } from './SwitcherButton'
import { ServicesPanel } from './ServicesPanel'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import getAuthorisedApps from './getAuthorisedApps'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { getUserOptionalRoles } from '@horizon/app-access-controller'

const AppSwitcher = ({ usersRoles }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  const onSwitcherButtonClick = () => setIsPanelOpen(true)
  const onServicesPanelClose = () => setIsPanelOpen(false)
  const containerRef = React.useRef()
  useOnClickOutside(containerRef, onServicesPanelClose)
  const authorisedApps = getAuthorisedApps(usersRoles)

  const userAssumableRoles = getUserOptionalRoles(usersRoles)

  const hasOneAssumableRole = userAssumableRoles.length === 1
  if (isNilOrEmpty(userAssumableRoles) || hasOneAssumableRole) return null

  return (
    <div ref={containerRef}>
      <SwitcherButton onClick={onSwitcherButtonClick} />
      <ServicesPanel
        isPanelOpen={isPanelOpen}
        onClose={onServicesPanelClose}
        authorisedApps={authorisedApps}
      />
    </div>
  )
}

export default AppSwitcher
