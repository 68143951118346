import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const {
  selectIsPending: selectIsConductingAssessment,
  selectHasFulfilled: selectIsConductAssessmentFulfilled,
} = createAsyncThunkSelectors('affordabilityAssessment/conductAffordabilityAssessment')

export const {
  selectIsPending: selectIsUpdatingAssessment,
  selectHasFulfilled: selectIsUpdateAssessmentFulfilled,
} = createAsyncThunkSelectors('affordabilityAssessment/updateAffordabilityAssessment')

export const {
  selectIsPending: selectIsRetrievingFinancialsData,
} = createAsyncThunkSelectors('affordabilityAssessment/retrieveFinancialsData')
