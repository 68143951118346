import { useEffect, useState, useCallback } from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch } from 'react-redux'
import { useInterval } from 'hooks/useInterval'
import { fetchLoanApplicationEntities } from 'modules/application'
import {
  conductAffordabilityAssessment,
  updateAffordabilityAssessment,
} from 'modules/affordabilityAssessment'
import { AFFORDABILITY_ASSESSMENT_CONSTANTS } from '@horizon/constants'

const { ASSESSMENT_STATUS } = AFFORDABILITY_ASSESSMENT_CONSTANTS

const XAI_POLLING_INTERVAL_MS = 10000

function useAutoLinkXai(
  dataIsReady,
  hasRetryRequiredOrIdleAssessments,
  isAllCreditReportsPassed,
  loanApplicationId,
  borrowersConductingAssessment = []
) {
  const dispatch = useDispatch()
  const [hasAttemptedAutoActivateXai, setHasAttemptedAutoActivateXai] = useState(false)
  const [isActivatingXai, setIsActivatingXai] = useState(false)

  const activateXai = useCallback(
    async (loanApplicationId) => {
      setIsActivatingXai(true)
      await dispatch(conductAffordabilityAssessment(loanApplicationId))
      await dispatch(fetchLoanApplicationEntities(loanApplicationId))
      setIsActivatingXai(false)
    },
    [dispatch]
  )

  useEffect(() => {
    function automaticallyActivateXai() {
      if (
        dataIsReady &&
        isAllCreditReportsPassed &&
        hasRetryRequiredOrIdleAssessments &&
        !hasAttemptedAutoActivateXai
      ) {
        activateXai(loanApplicationId)
        setHasAttemptedAutoActivateXai(true)
      }
    }

    automaticallyActivateXai()
  }, [
    activateXai,
    dataIsReady,
    hasAttemptedAutoActivateXai,
    hasRetryRequiredOrIdleAssessments,
    isAllCreditReportsPassed,
    loanApplicationId,
  ])

  // Pending indicates the assessment process is still in progress, and it will eventually change to either conducted or retry_required
  // Otherwise it is a system unexpected state
  // Keep polling until NO borrowers' assessment status is pending
  useInterval(
    () => {
      dispatch(fetchLoanApplicationEntities(loanApplicationId))
    },
    borrowersConductingAssessment.length > 0,
    XAI_POLLING_INTERVAL_MS
  )

  return { hasAttemptedAutoActivateXai, activateXai, isActivatingXai }
}

// eslint-disable-next-line complexity
const getAssessmentStatusToBorrowers = (individualBorrowers = []) => {
  const assessmentProcess = {
    completed: [],
    pending: [],
    retryRequired: [],
    financialsUpdated: [],
    idle: [],
  }

  for (const borrower of individualBorrowers) {
    const { status, conductedAt, financialsUpdatedAt } =
      borrower?.affordabilityAssessment || {}

    switch (true) {
      case isNotNilOrEmpty(conductedAt):
        assessmentProcess.completed.push(borrower)
        break

      case status === ASSESSMENT_STATUS.PENDING:
        assessmentProcess.pending.push(borrower)
        break

      case status === ASSESSMENT_STATUS.RETRY_REQUIRED:
        assessmentProcess.retryRequired.push(borrower)
        break

      case isNotNilOrEmpty(financialsUpdatedAt):
        assessmentProcess.financialsUpdated.push(borrower)
        break

      default:
        assessmentProcess.idle.push(borrower)
    }
  }

  return assessmentProcess
}

const hasApplicationLoaded = (individualBorrowers = []) =>
  individualBorrowers.length > 0

const checkIsXaiActivated = (individualBorrowers, borrowersCompletedAssessment) => {
  const hasAllBorrowersCompletedAssessment =
    individualBorrowers.length === borrowersCompletedAssessment.length

  return hasApplicationLoaded(individualBorrowers) && hasAllBorrowersCompletedAssessment
}

export function useXaiLinking(
  isAllCreditReportsPassed,
  loanApplicationId,
  individualBorrowers = []
) {
  const dispatch = useDispatch()
  const [isUpdatingXaiApplication, setUpdatingXaiApplication] = useState(false)

  const dataIsReady = hasApplicationLoaded(individualBorrowers)

  const {
    completed: borrowersCompletedAssessment,
    pending: borrowersConductingAssessment,
    retryRequired: borrowersRequiredRetryAssessment,
    idle: borrowersWithoutAssessment,
  } = getAssessmentStatusToBorrowers(individualBorrowers)

  const hasRetryRequiredOrIdleAssessments =
    borrowersRequiredRetryAssessment.length > 0 || borrowersWithoutAssessment.length > 0

  const {
    hasAttemptedAutoActivateXai,
    activateXai,
    isActivatingXai: isActivatingAutomatically,
  } = useAutoLinkXai(
    dataIsReady,
    hasRetryRequiredOrIdleAssessments,
    isAllCreditReportsPassed,
    loanApplicationId,
    borrowersConductingAssessment
  )

  const isActivatingXai =
    isActivatingAutomatically || borrowersConductingAssessment.length > 0

  const isXaiActivated = checkIsXaiActivated(
    individualBorrowers,
    borrowersCompletedAssessment
  )

  const isReactivationRequired =
    hasAttemptedAutoActivateXai && hasRetryRequiredOrIdleAssessments

  const updateXai = useCallback(
    async (assessmentReference) => {
      setUpdatingXaiApplication(true)
      await dispatch(
        updateAffordabilityAssessment({ loanApplicationId, assessmentReference })
      )
      await dispatch(fetchLoanApplicationEntities(loanApplicationId))
      setUpdatingXaiApplication(false)
    },
    [dispatch, loanApplicationId]
  )

  return {
    activateXai,
    updateXai,
    isActivatingXai,
    isXaiActivated,
    isReactivationRequired,
    isUpdatingXaiApplication,
    assessmentStatusToBorrowers: {
      borrowersCompletedAssessment,
      borrowersConductingAssessment,
      borrowersRequiredRetryAssessment,
      borrowersWithoutAssessment,
    },
  }
}
