import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Form, Formik } from 'formik'
import * as Validator from 'yup'
import { s, styled } from '@horizon/styled/v2'
import {
  createSupportingDocument,
  listSupportingDocuments,
  selectIsCreateSupportingDocumentPending,
} from 'modules/supportingDocument'
import { DottedCircleLoader, Modal, SelectField } from '@horizon/components'
import { ReactComponent as XIconBase } from '@horizon/components/src/assets/images/x.svg'
import { UploadInput } from './UploadInput'
import { File } from './File'
import { fileTypes } from './fileTypes'

const Container = styled.div(
  s('bg-white p-6', {
    borderRadius: 12,
    boxShadow: ' 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    minWidth: 560,
  })
)
const ContentContainer = styled.div(s('flex flex-column items-center justify-center'))
const Header = styled.div(s('flex items-center justify-between mb-6'))
const Heading = styled.h3(
  s('font-normal m-0 text-grey-900 text-xl', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)
const StatusText = styled.p(
  s('m-0 text-base text-grey-800', { letterSpacing: '0.01em', lineHeight: 1.5 })
)
const UploadButton = styled.button(
  s('bg-secondary border-0 font-semibold py-3 text-base w-full', {
    borderRadius: 6,
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const XIcon = styled(XIconBase)(s('text-grey-700', { cursor: 'pointer' }))

const { mixed, object, string } = Validator

const validationSchema = object({
  fileType: string().required('File Type is required'),
  file: mixed().required('File is required'),
})

const UploadSupportingDoc = ({ closeModal, isOpen, references = [], ...props }) => {
  const dispatch = useDispatch()

  const { id: loanApplicationId } = useParams()

  const isCreateDocumentPending = useSelector(selectIsCreateSupportingDocumentPending)

  const handleUploadToS3 = async (file, fileType) => {
    await dispatch(
      createSupportingDocument({
        classification: fileType,
        file,
        loanApplicationId,
        references,
        source: 'internal',
      })
    )
    dispatch(listSupportingDocuments(loanApplicationId))
    closeModal()
  }

  if (isCreateDocumentPending)
    return (
      <Modal isOpen={isOpen} onClose={closeModal}>
        <Container {...props}>
          <Header>
            <Heading>Upload in Progress</Heading>
            <XIcon onClick={closeModal} />
          </Header>

          <ContentContainer>
            <DottedCircleLoader size="lg" customStyles={s('mb-8')} />

            <StatusText>Uploading...</StatusText>
          </ContentContainer>
        </Container>
      </Modal>
    )

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Formik
        initialValues={{ fileType: '', file: null }}
        validationSchema={validationSchema}
        onSubmit={({ file, fileType }) => handleUploadToS3(file, fileType)}
      >
        {({ values: { file }, setFieldValue }) => {
          return (
            <Form>
              <Container {...props}>
                <Header>
                  <Heading>Upload Supporting Files</Heading>
                  <XIcon onClick={closeModal} />
                </Header>

                <SelectField
                  name="fileType"
                  options={fileTypes}
                  placeholder="Please select a file type"
                  selectMenuStyles={{ maxHeight: 400 }}
                  style={s('mb-6')}
                />

                {!file && <UploadInput name="file" style={s('mb-8')} />}

                {file && (
                  <File
                    handleDelete={(closeDeleteFileModal) => {
                      setFieldValue('file', null)
                      closeDeleteFileModal()
                    }}
                    fileName={file.name}
                    style={s('mb-8 mt-2')}
                  />
                )}

                <UploadButton type="submit">Upload</UploadButton>
              </Container>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export { UploadSupportingDoc }
