import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'
import { prop, path } from '@solta/ramda-extra'
import { normalizeError, UploadService } from '@horizon/services'

const uploadService = new UploadService()

export const uploadFile = createAsyncThunk(
  'upload/uploadFile',
  async (file, { rejectWithValue }) => {
    try {
      return await uploadService.upload(file)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const deleteFile = createAsyncThunk(
  'upload/deleteFile',
  async (key, { rejectWithValue }) => {
    try {
      return await uploadService.delete(key)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

const uploadAdapter = createEntityAdapter({
  selectId: (file) => file.key,
})

const initialState = uploadAdapter.getInitialState()

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    rehydrate: (state, { payload }) => {
      uploadAdapter.setAll(state, payload)
    },
  },
  extraReducers: {
    [uploadFile.fulfilled]: (state, { payload }) =>
      uploadAdapter.upsertOne(state, payload),
    [deleteFile.fulfilled]: (state, { payload }) =>
      uploadAdapter.removeOne(state, payload),
  },
})

const selectUpload = prop('upload')
const selectUploadFileStatus = path(['asyncState', 'upload/uploadFile'])
const selectUploadEntities = createSelector(selectUpload, prop('entities'))
const selectUploadFileStatusByKey = (key) =>
  createSelector(selectUploadFileStatus, path([key, 'status']))

const { reducer: uploadReducer } = uploadSlice

export const { rehydrate } = uploadSlice.actions
export { uploadReducer, selectUploadEntities, selectUploadFileStatusByKey }
