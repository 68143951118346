/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import {
  ContentCard,
  SectionTitle,
  SectionTitle as SectionTitleBase,
} from '@horizon/components/src/v2'
import {
  listSupportingDocuments,
  selectAllSupportingDocuments,
} from 'modules/supportingDocument'
import { SupportingDoc } from '../info/supportingDocs/SupportingDoc'
import { UploadSupportingDoc } from '../info/supportingDocs/UploadSupportingDoc'
import { ReactComponent as ChevronLeft } from '@horizon/components/src/assets/images/chevron-left.svg'
import { ReactComponent as ChainLinkBase } from '@horizon/components/src/assets/images/chain-link.svg'
import { LinkValocityModal } from './LinkValocityModal'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { DataDisplay } from '../info/components'
import {
  formatCurrency,
  formatDateString,
  buildAddress,
  capitaliseFirstLetterOfAllWords,
} from 'utils'
import { NoteSection } from '../../common/NoteSection'
import { ReactComponent as Money } from '@horizon/components/src/assets/images/money.svg'

import { ReactComponent as ChevronRight } from '@horizon/components/src/assets/images/chevron-right.svg'

import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/application'
import { getSecurityDetails, selectSearchedProperty } from 'modules/property'
import { Button, Loading } from '@horizon/components'
import { FinalAssetInput } from './FinalAssetInput'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

const LabelHeader = styled.div(s('flex justify-between mb-8'))
const SectionLabel = styled.p(
  s('m-0 mb-8 text-grey-900 text-sm font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const UploadButton = styled(Button)(
  s(
    'bg-white border-1 border-primary border-solid text-primary text-sm px-4 py-2 rounded-lg font-semibold',
    {
      cursor: 'pointer',
      letterSpacing: '0.01em',
      lineHeight: 1.5,
    }
  )
)
const PropertyNotLinkedPill = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-accent-red text-xs mb-2 mt-0',
    { width: 145 }
  )
)
const PropertyLinkedPill = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-secondary text-xs text-white mt-8 ml-2',
    { width: 115 }
  )
)

const LinkPropertyButton = styled(Button)(
  s(
    'bg-primary border-1 border-transparent rounded-lg text-white font-semibold self-end text-center py-2 px-4 mr-2 mt-6 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  )
)
const filterSecurityDocuments = (documents, securityId) => {
  const internalDocuments = documents.filter(({ source }) => source === 'internal')

  const securityDocuments = internalDocuments.filter(({ references }) =>
    references.find(({ name, value }) => name === 'security' && value === securityId)
  )

  return securityDocuments
}

const ChainLink = styled(ChainLinkBase)(s('mr-2', { marginTop: 2 }))

const getLinkedStatus = (linkedDetails) => {
  if (isNilOrEmpty(linkedDetails) || isNilOrEmpty(linkedDetails?.address)) {
    return false
  }
  return true
}

const getRentalIncome = (rentalIncomeArray = []) => {
  if (rentalIncomeArray?.length === 0) return null
  const sumOfAllIncomes = rentalIncomeArray
    .map((rentalIncome) => rentalIncome?.rentalAmount)
    .reduce((previousAmount, currentAmount) => previousAmount + currentAmount, 0)
  return sumOfAllIncomes
}

const SecurityDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id, securityId } = useParams()

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isLinkModalOpened, setIsLinkModalOpened] = useState(false)

  const supportingDocuments = useSelector(selectAllSupportingDocuments)
  const application = useSelector(selectApplicationById(id))
  const security = application?.securities?.find(
    (property) => property?.id === securityId
  )

  const { externalRef } = security?.linkedDetails || ''
  const { linkedDetails } = security || {}

  const ivalReport = useSelector(selectSearchedProperty(externalRef)) ?? {}
  const { forecastStandardDeviation } = ivalReport ?? '-'

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  useEffect(() => {
    dispatch(fetchLoanApplication(id))

    dispatch(listSupportingDocuments(id))

    dispatch(
      getSecurityDetails({
        requestId: securityId,
        propertyId: externalRef,
      })
    )
  }, [dispatch, externalRef, id, securityId])

  if (isFetchingApplication) {
    return <Loading />
  }

  const { streetNumber, streetName, streetType, unit } =
    security?.address?.standard || {}
  const { suburb, city, newZealandPostCode, countryISO } = security?.address || {}

  const fullAddress = buildAddress(
    { streetNumber, streetName, streetType },
    suburb,
    city,
    countryISO,
    unit,
    newZealandPostCode
  )

  const isLinked = getLinkedStatus(linkedDetails)
  const securityDocuments = filterSecurityDocuments(supportingDocuments, securityId)
  const customEstimateValue = application?.customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )

  const openModal = () => setIsModalOpened(true)
  const closeModal = () => setIsModalOpened(false)

  const openLinkModal = () => setIsLinkModalOpened(true)

  const references = [{ name: 'security', value: securityId }]

  const LinkedComponents = () => {
    return (
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <SectionTitle title="Results" style={s('mb-3 mt-8')} />
          <ContentCard>
            <Row>
              <Col span={24}>
                <p>
                  These results are from iVal report, to view the details please find
                  the reports section
                </p>
              </Col>

              <Col span={24}>
                <DataDisplay
                  label="Address"
                  value={linkedDetails?.address}
                  valueStyles={{ style: s('text-xl') }}
                />
              </Col>

              <Col span={11}>
                <img src={linkedDetails?.imageSrc} style={s('rounded-lg w-24 h-16')} />
              </Col>

              <Col span={13}>
                <Row gutter={[0, 0]}>
                  <Col span={12}>
                    <DataDisplay
                      label={`iVal Estimate ${formatDateString(
                        linkedDetails?.updatedAt || linkedDetails?.valuation?.lastValued
                      )}`}
                      value={formatCurrency(linkedDetails?.valuation?.estimatedValue)}
                      valueStyles={{ style: s('mb-6') }}
                    />
                  </Col>

                  <Col span={12}>
                    <DataDisplay
                      label="Forecast Standard Deviation"
                      value={forecastStandardDeviation}
                    />
                  </Col>

                  <Col span={12}>
                    <DataDisplay
                      valueStyles={{ style: s('mb-6') }}
                      label="Value High Range"
                      value={formatCurrency(
                        linkedDetails?.valuation?.estimatedUpperRange
                      )}
                    />
                  </Col>

                  <Col span={12}>
                    <DataDisplay
                      label="Value Low Range"
                      value={formatCurrency(
                        linkedDetails?.valuation?.estimatedLowerRange
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <p style={s('text-sm')}>Final Asset Value</p>
                    <FinalAssetInput
                      defaultValue={security?.linkedDetails?.valuation?.estimatedValue}
                      currentValue={customEstimateValue?.value}
                      loanApplicationId={id}
                      loanApplication={application}
                      financialId={securityId}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ContentCard>
        </Col>

        <Col span={24}>
          <SectionTitle title="Reports" style={s('mb-3 mt-8')} />

          <ContentCard style={s('py-3')}>
            <Row gutter={[0, 0]}>
              <Col span={1}>
                <Money style={s('h-2 mt-4 ml-0')} />
              </Col>

              <Col span={20}>
                <p style={s('text-lg font-semibold')}>Valuation Information</p>
              </Col>

              <Col span={1} />

              <Col span={20}>
                <p style={s('text-base font-semibold')}>iVal (AVM) Report</p>
              </Col>

              <Col span={3}>
                <div style={s('flex felx-row justify-end mt-1')}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      history.push(
                        `/loan-applications/${id}/security/${securityId}/ival-report`
                      )
                    }
                  >
                    View <ChevronRight style={s('h-1 w-1', { marginBottom: -3 })} />
                  </Button>
                </div>
              </Col>
            </Row>
          </ContentCard>
        </Col>

        <Col span={24}>
          <p>Need to access a full report?</p>
          <Button variant="outlined" disabled={true}>
            Request Full Report
          </Button>

          <p style={s('mb-0 mt-1 text-xs')}>
            *This feature is coming soon. Please use Valocity iDecision in the meantime.
          </p>
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <Row style={s(' ml-8')}>
        <BackButton
          variant="text"
          style={s('mb-8 ml-24')}
          onClick={() => history.push(`/loan-applications/${id}/security`)}
        >
          <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
        </BackButton>

        <Col span={24} style={s('ml-8')}>
          <div style={s('px-16')}>
            <Row>
              <Col span={24}>
                {isLinked ? (
                  <div style={s('flex flex-row')}>
                    <h1>{capitaliseFirstLetterOfAllWords(linkedDetails?.address)}</h1>
                    <PropertyLinkedPill>SECURITY LINKED</PropertyLinkedPill>
                  </div>
                ) : (
                  <Row gutter={[0, 0]}>
                    <Col span={20}>
                      <h1>{fullAddress}</h1>
                    </Col>

                    <Col span={4}>
                      <div style={s('flex flex-row justify-end')}>
                        <LinkPropertyButton onClick={openLinkModal}>
                          <ChainLink />
                          Link Property
                        </LinkPropertyButton>
                      </div>
                    </Col>
                    <Col span={24}>
                      <PropertyNotLinkedPill>SECURITY NOT LINKED</PropertyNotLinkedPill>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col span={24}>
                <SectionTitle title="Details from myCRM" style={s('mb-3')} />
                <ContentCard>
                  <Row>
                    <Col span={6}>
                      <DataDisplay
                        label="Estimate"
                        value={formatCurrency(security?.estimatedValue?.value)}
                      />
                    </Col>

                    <Col span={6}>
                      <DataDisplay
                        label="Property Type"
                        value={security?.propertyType?.propertyTypeName}
                      />
                    </Col>

                    <Col span={6}>
                      <DataDisplay
                        label="Primary Usage"
                        value={security?.primaryUsage}
                      />
                    </Col>

                    <Col span={6}>
                      <DataDisplay
                        label="Used as Security"
                        value={security?.isSecurity ? 'Yes' : 'No'}
                      />
                    </Col>

                    <Col span={6}>
                      <DataDisplay
                        label="Monthly Rental Income"
                        value={formatCurrency(getRentalIncome(security?.rentalIncome))}
                      />
                    </Col>

                    <Col span={6}>
                      <DataDisplay
                        label="Primary Purpose"
                        value={security?.primaryPurpose?.propertyTypeName}
                      />
                    </Col>

                    <Col span={6}>
                      <DataDisplay label="Occupancy" value={security?.occupancy} />
                    </Col>

                    <Col span={6}>
                      <DataDisplay label="Holding" value={security?.holding} />
                    </Col>
                  </Row>
                </ContentCard>
              </Col>

              <Col span={24}>{isLinked && <LinkedComponents />}</Col>

              <Col span={24}>
                <SectionTitle title="Supporting Docs" style={s('mb-3 mt-8')} />
                <ContentCard style={s('mb-3 ')}>
                  <LabelHeader>
                    <SectionLabel style={s('mb-0')}>Internal Files</SectionLabel>
                    <UploadButton onClick={openModal}>Upload</UploadButton>
                  </LabelHeader>

                  <Row gutter={[24, 96]}>
                    {securityDocuments.map((document, index) => (
                      <Col key={index} span={12}>
                        <SupportingDoc document={document} />
                      </Col>
                    ))}
                  </Row>
                </ContentCard>

                <UploadSupportingDoc
                  closeModal={closeModal}
                  isOpen={isModalOpened}
                  references={references}
                />
              </Col>
              <Col span={24}>
                <NoteSection
                  id="note-section"
                  loanApplicationId={id}
                  source={`loan-applications/security/${securityId}`}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <LinkValocityModal
        isOpen={isLinkModalOpened}
        setIsOpen={setIsLinkModalOpened}
        security={security}
        loanApplicationId={id}
      />
    </div>
  )
}

export { SecurityDetails }
