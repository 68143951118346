import React, { useState } from 'react'
import { Col, Row } from '@horizon/styled'
import { s, styled } from '@horizon/styled/v2'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { AdverseChange } from './AdverseChange'
import { formatBoolean } from 'utils/formatters'

const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const CollapseButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)
const Subtitle = styled.p(
  s('font-semi-bold my-3 text-black text-lg', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const SubtitleValue = styled.span(s('font-semibold text-primary'))
const Title = styled.h3(
  s('mb-0 text-grey-900 text-xl', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)
const ViewDetailsButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2 mt-4'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)

export const AdverseChanges = ({ individuals = [] }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      {isExpanded ? (
        <>
          {individuals.map((individual = {}, index) => {
            const {
              personName: { firstName, surname },
              responsibleLending: { anticipatedChanges, changes },
            } = individual

            const formattedanticipatedChanges =
              formatBoolean(anticipatedChanges).toUpperCase()
            const formattedName = `${firstName} ${surname}`
            const isFirstIndividual = index === 0

            return (
              <>
                <Title style={s(isFirstIndividual ? 'mt-4' : 'mt-6')}>
                  {formattedName}
                </Title>

                <Subtitle>
                  Anticipated Adverse Change(s):{' '}
                  <SubtitleValue>{formattedanticipatedChanges}</SubtitleValue>
                </Subtitle>

                <Row gutter={[12, 16]}>
                  {anticipatedChanges &&
                    changes.map((change, index) => (
                      <Col key={index} span={8}>
                        <AdverseChange change={change} />
                      </Col>
                    ))}
                </Row>
              </>
            )
          })}

          <CollapseButton onClick={() => setIsExpanded(false)} style={s('mt-8')}>
            <Row style={s('justify-center')}>
              <div>Collapse</div>
              <ChevronUpIcon width={20} height={20} />
            </Row>
          </CollapseButton>
        </>
      ) : (
        <ViewDetailsButton onClick={() => setIsExpanded(true)}>
          <Row style={s('justify-center')}>
            <div>View Details</div>
            <ChevronDownIcon width={20} height={20} />
          </Row>
        </ViewDetailsButton>
      )}
    </>
  )
}
