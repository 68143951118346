import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { mapService } from 'apiService'
import { normalizeError } from '@horizon/services'
import { isNilOrEmpty } from '@solta/ramda-extra'

export const fetchComparableSales = createAsyncThunk(
  'comparableSale/fetchComparableSales',
  async (query, { rejectWithValue }) => {
    try {
      const result = await mapService.forward(query)
      return result.features
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const comparableSaleAdapter = createEntityAdapter()

const initialState = comparableSaleAdapter.getInitialState({
  coordinates: [],
})

const comparableSaleSlice = createSlice({
  name: 'comparableSale',
  initialState,
  reducers: { clearComparableSalesLocations: () => initialState },
  extraReducers: {
    [fetchComparableSales.fulfilled]: (state, { payload }) => {
      if (isNilOrEmpty(payload?.[0])) return
      const coordinates = payload[0]?.center
      const placeName = payload[0]?.place_name
      state.coordinates = [
        ...state.coordinates,
        { coordinates, placeName, address: payload[0]?.address },
      ]
    },
  },
})

export const { clearComparableSalesLocations } = comparableSaleSlice.actions
const { reducer: comparableSaleReducer } = comparableSaleSlice
export { comparableSaleReducer }
