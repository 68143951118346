import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled, s } from '@horizon/styled/v2'
import { ReactComponent as ChevronDown } from '@horizon/components/src/assets/images/chevron-down.svg'
import { Row, Col } from '@horizon/styled'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as ChevronUp } from '@horizon/components/src/assets/images/chevron-up.svg'
import { ContentCard } from 'features/loanAssessment/info/components'
import { NoteSection } from 'features/common/NoteSection'
import { Employment } from './Employment'
import { OtherIncome } from './OtherIncome'

const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const CollapseButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)
const ViewDetailsButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)
const IncomeSubtitle = styled.p(
  s('font-semi-bold mb-3 mt-5 text-black text-lg', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const IndividualEntitySubtitle = styled.h3(
  s('m-0 text-grey-900 text-xl', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)

const orderEmployments = (employments) => {
  const previousEmployments = employments.filter(
    ({ employment: { status } }) => status === 'Previous'
  )
  const primaryEmployments = employments.filter(
    ({ employment: { status } }) => status === 'Primary'
  )
  const secondaryEmployments = employments.filter(
    ({ employment: { status } }) => status === 'Secondary'
  )

  return [...primaryEmployments, ...secondaryEmployments, ...previousEmployments]
}

const getEmployerDetails = (employments = [], relatedCompanies = []) => {
  const newEmployments = employments.map((employment) => {
    const matchingCompany =
      relatedCompanies.find(({ externalRef }) => {
        return externalRef === employment.employment.employerRef
      }) || {}

    const {
      businessName,
      contact: { address: { city, countryISO, suburb, standard } = {} } = {},
    } = matchingCompany

    const { streetName, streetNumber, unit } = standard || {}

    return {
      employment: {
        address: { city, countryISO, streetName, streetNumber, suburb, unit },
        businessName,
        ...employment.employment,
      },
      totalIncomeAmount: employment.totalIncomeAmount,
    }
  })

  return newEmployments
}

export const ViewIncomeDetails = ({
  individuals = [],
  otherIncomes = [],
  relatedCompanies,
}) => {
  const { id } = useParams()
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      {isExpanded ? (
        <>
          {isNotNilOrEmpty(individuals) ? (
            <>
              {individuals?.map((individual, index) => {
                const isFirstIndividual = index === 0
                const { employments, firstName, lastName, title } = individual
                const employmentsWithEmployerDetails = getEmployerDetails(
                  employments,
                  relatedCompanies
                )
                const orderedEmployments = orderEmployments(
                  employmentsWithEmployerDetails
                )

                return (
                  <>
                    <IndividualEntitySubtitle
                      key={index}
                      style={s(isFirstIndividual ? 'mt-6' : 'mt-8')}
                    >{`${title} ${firstName} ${lastName}`}</IndividualEntitySubtitle>

                    <IncomeSubtitle>Employment</IncomeSubtitle>

                    <Row gutter={[12, 16]}>
                      {orderedEmployments?.map((employment, index) => (
                        <Col key={index} span={8}>
                          <Employment income={employment} />
                        </Col>
                      ))}
                    </Row>
                  </>
                )
              })}
            </>
          ) : (
            <ContentCard style={s('mt-3 text-center')}>
              No Data About Employment Incomes
            </ContentCard>
          )}

          <IndividualEntitySubtitle style={s('my-8')}>
            Other Incomes
          </IndividualEntitySubtitle>

          {isNotNilOrEmpty(otherIncomes) ? (
            <>
              <Row gutter={[12, 16]}>
                {otherIncomes.map((income, index) => (
                  <Col key={index} span={8}>
                    <OtherIncome
                      key={income.id}
                      amount={income?.amount}
                      isTaxable={income?.isTaxable}
                      type={income?.domainType}
                      frequency={income?.frequency}
                      ownerships={income?.percentOwned.owners}
                      entities={income?.userPercentOwned.map(
                        ({
                          personName,
                          businessName,
                          trustName,
                          companyName,
                          _id,
                        }) => ({
                          personName,
                          businessName,
                          trustName,
                          companyName,
                          id: _id,
                        })
                      )}
                      verificationStatus={income?.incomeVerification}
                    />
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <ContentCard style={s('mb-4 text-center')}>
              No Data About Other Incomes
            </ContentCard>
          )}

          <Row style={s('mt-8')}>
            <Col span={24}>
              <NoteSection
                id="note-section"
                loanApplicationId={id}
                source="loan-applications/financial/income"
              />
            </Col>
          </Row>

          <CollapseButton onClick={() => setIsExpanded(false)} style={s('mt-8')}>
            <Row style={s('justify-center')}>
              <div>Collapse</div>
              <ChevronUpIcon width={20} height={20} />
            </Row>
          </CollapseButton>
        </>
      ) : (
        <ViewDetailsButton onClick={() => setIsExpanded(true)}>
          <Row style={s('justify-center')}>
            <div>View Details</div>
            <ChevronDownIcon width={20} height={20} />
          </Row>
        </ViewDetailsButton>
      )}
    </>
  )
}
