import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useField } from 'formik'
import { s, styled } from '@horizon/styled/v2'

const Container = styled.div()
const FileUploadArea = styled.div(
  s(
    'border-dashed border-grey-600 border-1 flex flex-column items-center justify-center mb-1 p-3 rounded-lg'
  )
)
const Text = styled.p(
  s('font-normal m-0 text-sm', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const TextButton = styled.button(
  s('bg-transparent border-0 font-semibold text-primary text-sm', {
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const UploadInput = ({ name, ...props }) => {
  const [, , { setValue }] = useField({ name })

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onload = () => {
          setValue(file)
        }
        reader.readAsArrayBuffer(file)
      })
    },
    [setValue]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Container {...getRootProps()} {...props}>
      <Text style={s('mb-1 text-grey-800')}>Select, or drop a file here</Text>

      <FileUploadArea>
        <Text style={s('font-medium mb-1 text-grey-900')}>Drag & drop here</Text>
        <Text style={s('font-medium mb-2 text-grey-900')}>or</Text>
        <TextButton type="button">
          Browse files
          <input {...getInputProps()} />
        </TextButton>
      </FileUploadArea>

      <Text style={s('text-grey-600')}>Supports .doc, .jpg, .pdf, and .png files</Text>
    </Container>
  )
}

export { UploadInput }
