import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@horizon/services'

import { financialService } from 'apiService'

/**
 * @type {import('./types').FetchApplicationThunk}
 */
export const fetchLoanFinancials = createAsyncThunk(
  'financials/fetchLoanFinancials',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      return await financialService.readByLoanId(loanApplicationId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateFinance = createAsyncThunk(
  'financials/updateFinance',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { propertyId, address, details, valuation, imageSrc, updatedAt } = payload
      return await financialService.updateFinance(id, {
        propertyId,
        address,
        details,
        valuation,
        imageSrc,
        updatedAt,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const financialAdapter = createEntityAdapter({
  selectId: (financials) => financials.financialType,
})

const initialState = financialAdapter.getInitialState({
  entities: {},
  ids: [],
  individualEntityEmployments: [],
  households: [],
})

const financialSlice = createSlice({
  name: 'financials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoanFinancials.fulfilled, (state, { payload }) => {
      if (payload) {
        financialAdapter.setAll(state, payload?.financials)
        state.individualEntityEmployments = payload?.individualEntityEmployments
        state.households = payload?.households
      }
    })
  },
})

const { reducer: financialReducer } = financialSlice

export { financialReducer }
