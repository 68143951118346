/* eslint-disable complexity */
import { pick } from '@solta/ramda-extra'

const createNewIncomeObject = (income = {}) =>
  pick(
    [
      'bonusAmount',
      'bonusFrequency',
      'carAllowanceAmount',
      'carAllowanceFrequency',
      'commissionAmount',
      'commissionFrequency',
      'grossRegularOvertimeAmount',
      'grossRegularOvertimeFrequency',
      'grossSalaryAmount',
      'grossSalaryFrequency',
      'workAllowanceAmount',
      'workAllowanceFrequency',
      'workersCompensationAmount',
      'workersCompensationFrequency',
    ],
    income
  )

const convertToYearly = (amount = 0, frequency = '') => {
  switch (frequency) {
    case 'Fortnightly':
      return amount * (365 / 14)
    case 'Monthly':
      return amount * 12
    case 'Weekly':
      return amount * (365 / 7)
    default:
      return amount
  }
}

const calculateTotalIncomeAmount = (income = {}) => {
  let totalAmount = 0

  if (income?.bonusFrequency)
    totalAmount += convertToYearly(income.bonusAmount, income.bonusFrequency)

  if (income?.carAllowanceFrequency)
    totalAmount += convertToYearly(
      income.carAllowanceAmount,
      income.carAllowanceFrequency
    )

  if (income?.commissionFrequency)
    totalAmount += convertToYearly(income.commissionAmount, income.commissionFrequency)

  if (income?.grossRegularOvertimeFrequency)
    totalAmount += convertToYearly(
      income.grossRegularOvertimeAmount,
      income.grossRegularOvertimeFrequency
    )

  if (income?.grossSalaryFrequency)
    totalAmount += convertToYearly(
      income.grossSalaryAmount,
      income.grossSalaryFrequency
    )

  if (income?.workAllowanceFrequency)
    totalAmount += convertToYearly(
      income.workAllowanceAmount,
      income.workAllowanceFrequency
    )

  if (income?.workersCompensationFrequency)
    totalAmount += convertToYearly(
      income.workersCompensationAmount,
      income.workersCompensationFrequency
    )

  return totalAmount
}

export const getFinancialSumForPAYE = (paye = {}) => {
  const { income } = paye

  const newIncome = createNewIncomeObject(income)

  const incomeAmount = calculateTotalIncomeAmount(newIncome)

  return incomeAmount
}
