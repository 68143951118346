import { prop } from '@solta/ramda-extra'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { creditReportAdapter } from './module'

export const {
  selectIsPending: selectIsStartCreditReportPending,
  selectHasFulfilled: selectHasStartCreditReportFulfilled,
  selectError: selectStartCreditReportError,
} = createAsyncThunkSelectors('creditReport/startCreditCheck')

export const {
  selectIsPending: selectIsGetCreditReportPending,
  selectHasFulfilled: selectHasGetCreditReportFulfilled,
  selectError: selectGetCreditReportError,
} = createAsyncThunkSelectors('creditReport/startCreditCheck')

export const { selectAll: selectAllCreditReports } = creditReportAdapter.getSelectors(
  prop('creditReport')
)
