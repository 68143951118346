import React from 'react'
import { s, styled } from '@horizon/styled/v2'

const Text = styled.p(
  s('text-xm', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
  })
)

const HelperText = ({ children, ...props }) => {
  return <Text {...props}>{children}</Text>
}

export { HelperText }
