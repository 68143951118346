import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { formatPhoneNumber } from 'utils/formatters'
import { TEST_IDS } from '@horizon/constants'

const Container = styled.div()
const ApplicantStatus = styled.div(
  s('inline-block mb-2 rounded-md px-2 bg-gray-200', {
    paddingTop: 2,
    paddingBottom: 2,
  }),
  ({ isPrimaryApplicant }) => isPrimaryApplicant && s('bg-secondary text-white')
)
const Name = styled.div(
  s('mb-3 text-lg font-semibold', {
    color: '#141616',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Label = styled.div('text-base font-normal', {
  color: '#515856',
  letterSpacing: '0.01em',
  lineHeight: 1.5,
})
const Value = styled.span(s('font-semibold'))

const getApplicantStatus = (applicantRole, isPrimaryApplicant) => {
  if (applicantRole === 'Guarantor') {
    return 'GUARANTOR'
  }
  if (isPrimaryApplicant) {
    return 'PRIMARY APPLICANT'
  }
  return 'APPLICANT'
}

const PersonalInfo = ({
  dob = '-',
  email = '-',
  applicantRole,
  isPrimaryApplicant = false,
  maritalStatus = '-',
  fullName = '-',
  phone = '-',
  residencyStatus = '-',
  citizenship = '-',
  index,
  ...props
}) => {
  return (
    <Container {...props}>
      <ApplicantStatus isPrimaryApplicant={isPrimaryApplicant}>
        {getApplicantStatus(applicantRole, isPrimaryApplicant)}
      </ApplicantStatus>

      <Name data-test-id={`${TEST_IDS.APPLICANT_NAME}${index}`}>{fullName}</Name>

      <Label>
        DOB: <Value data-test-id={`${TEST_IDS.APPLICANT_DOB}${index}`}>{dob}</Value>
      </Label>

      <Label>
        Email:{' '}
        <Value data-test-id={`${TEST_IDS.APPLICANT_EMAIL}${index}`}>{email}</Value>
      </Label>

      <Label>
        Marital Status:{' '}
        <Value data-test-id={`${TEST_IDS.APPLICANT_MARITAL_STATUS}${index}`}>
          {maritalStatus}
        </Value>
      </Label>

      <Label>
        Phone:{' '}
        <Value data-test-id={`${TEST_IDS.APPLICANT_PHONE_NO}${index}`}>
          {formatPhoneNumber(phone)}
        </Value>
      </Label>

      <Label>
        Residency Status:{' '}
        <Value data-test-id={`${TEST_IDS.APPLICANT_RESIDENCY_STATUS}${index}`}>
          {residencyStatus}
        </Value>
      </Label>

      <Label>
        Citizenship:{' '}
        <Value data-test-id={`${TEST_IDS.APPLICANT_CITIZENSHIP}${index}`}>
          {citizenship}
        </Value>
      </Label>
    </Container>
  )
}

export { PersonalInfo }
