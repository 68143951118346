import { createSelector } from '@reduxjs/toolkit'

import { ASYNC_STATUSES } from '@horizon/constants'
import { path, isNil, equals, isNotNilOrEmpty } from '@solta/ramda-extra'

const createAsyncThunkSelectors = (type) => {
  if (typeof type !== 'string') {
    throw new TypeError(`Expected action type to be 'string', got ${type}`)
  }

  const selectStatus = (state, requestId) => {
    return path(['asyncState', type, requestId, 'status'].filter(isNotNilOrEmpty))(
      state
    )
  }

  const selectIsIdle = (state, requestId) =>
    createSelector(selectStatus, isNil)(state, requestId)

  const selectIsPending = (state, requestId) =>
    createSelector(selectStatus, equals(ASYNC_STATUSES.PENDING))(state, requestId)

  const selectHasFulfilled = (state, requestId) =>
    createSelector(selectStatus, equals(ASYNC_STATUSES.FULFILLED))(state, requestId)

  const selectHasRejected = (state, requestId) =>
    createSelector(selectStatus, equals(ASYNC_STATUSES.REJECTED))(state, requestId)

  const selectError = (state, requestId) => {
    return path(['asyncState', requestId, type, 'error'].filter(isNotNilOrEmpty))(state)
  }

  return {
    selectStatus,
    selectIsIdle,
    selectIsPending,
    selectHasFulfilled,
    selectHasRejected,
    selectError,
  }
}

export { createAsyncThunkSelectors }
