/* eslint-disable complexity */
import React from 'react'
import { useSelector } from 'react-redux'
import { selectAllProducts } from 'modules/product'
import { append } from '@solta/ramda-extra'
import { RecommendedLoanSection } from './RecommendedLoanSection'
import { LoanAllocationSection } from './LoanAllocationSection'
import { SectionTitle } from '@horizon/components/src/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { Button } from '@horizon/components'
import { s, styled } from '@horizon/styled/v2'
import { ReactComponent as ErrorIcon } from '@horizon/components/src/assets/images/error.svg'
import { Formik as FormProvider, Form } from 'formik'
import { defaultLoanStructure } from './shared'
import {
  validationSchema,
  LoanStructuresMapper,
  RecommendedStructuresMapper,
} from './loanBuilderFormUtils'

const CreateLoanSplitButton = styled(Button)(
  s('font-semibold', { fontSize: 14, borderRadius: 6 })
)

const groupEntitiesByApplicantRole = (individuals = []) => {
  const borrowers = []
  const guarantors = []

  individuals.forEach((individual) => {
    if (individual.applicantRole === 'Borrower') borrowers.push(individual)
    else if (individual.applicantRole === 'Guarantor') guarantors.push(individual)
  })

  return {
    borrowers,
    guarantors,
  }
}

export const LoanBuilder = ({
  loanApplication,
  allocatedLoanStructures = [],
  totalRequestedLoanAmount,
  totalAllocatedLoanAmount = 0,
  isLoanBuilderReady = false,
}) => {
  const {
    requestedLoans: recommendedLoans = [],
    legalEntities = {},
    securities = [],
    customSecurityValuations = [],
  } = loanApplication

  const products = useSelector(selectAllProducts)

  const { individuals = [], trusts = [], companies = [] } = legalEntities

  const { borrowers, guarantors } = groupEntitiesByApplicantRole([
    ...individuals,
    ...trusts,
    ...companies,
  ])

  if (!isLoanBuilderReady) {
    return (
      <div style={s('w-full')}>
        <SectionTitle title="Loan Builder" style={s('ml-2 mb-3 ')} />

        <ContentCard style={s('flex text-grey-900 mb-8')}>
          <div style={s('mx-a text-center')}>
            <ErrorIcon />
            <p style={s('font-bold')}>Loan Builder Unavailable</p>
            <p style={s('m-0')}>
              We cant generate the form until all information is linked:
            </p>
            <ul style={s('text-left pl-6 m-0')}>
              <li>Connect property to Valocity, please go to step 3</li>
            </ul>
          </div>
        </ContentCard>

        <div>
          <p style={s('m-0 mb-3')}>
            <strong>Ready to allocate the loan?</strong>
          </p>

          <CreateLoanSplitButton disabled>Add Custom Loan Split</CreateLoanSplitButton>
        </div>
      </div>
    )
  }

  return (
    <div style={s('w-full')}>
      <SectionTitle title="Loan Builder" style={s('ml-2 mb-3')} />

      <FormProvider
        enableReinitialize
        initialValues={{
          loanStructures: new LoanStructuresMapper(
            allocatedLoanStructures,
            products,
            securities,
            borrowers,
            guarantors,
            customSecurityValuations
          ).map(),
          recommendedLoanStructures: new RecommendedStructuresMapper(
            recommendedLoans,
            borrowers,
            guarantors
          ).map(),
        }}
        validationSchema={() =>
          validationSchema(totalRequestedLoanAmount, totalAllocatedLoanAmount)
        }
      >
        {({ values, setFieldValue }) => (
          <Form>
            <LoanAllocationSection
              loanApplication={loanApplication}
              allBorrowers={borrowers}
              allGuarantors={guarantors}
              allProducts={products}
            />
            <RecommendedLoanSection style={s('mb-8')} />

            <div>
              <p style={s('m-0 mb-3')}>
                <strong>Ready to allocate the loan?</strong>
              </p>

              <CreateLoanSplitButton
                onClick={() => {
                  setFieldValue(
                    'loanStructures',
                    append(defaultLoanStructure, values.loanStructures)
                  )
                }}
              >
                Add Custom Loan Split
              </CreateLoanSplitButton>
            </div>
          </Form>
        )}
      </FormProvider>
    </div>
  )
}
