import React, { useState } from 'react'
import { Item } from '@react-stately/collections'
import { s, styled } from '@horizon/styled/v2'
import { Button as ButtonBase, DropdownCheckbox, Menu } from '@horizon/components'
import { ReactComponent as ResetIcon } from '@horizon/components/src/assets/images/refresh.svg'
import { isEmpty } from '@solta/ramda-extra'
import { LOAN_APPLICATION, ALPHABETICAL_SORT_ORDER } from '@horizon/constants'

const { APPLICATION_STATUS_DISPLAY_TEXT } = LOAN_APPLICATION

const sortingOptions = [
  { key: 'A-Z', label: 'A-Z' },
  { key: 'Z-A', label: 'Z-A' },
]

const Container = styled.div(s('flex justify-between bg-transparent', { gap: 10 }))

const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

function FiltersBar({ filters, updateSearchProps }) {
  const [selectedStatusKeys, setSelectedStatusKeys] = useState(new Set())

  const updateSortParam = (sortKey) => (newSortOrder) => {
    updateSearchProps({
      sorting: {
        brokerName: undefined,
        assignedTo: undefined,
        [sortKey]: ALPHABETICAL_SORT_ORDER[newSortOrder],
      },
    })
  }

  function handleStatusFilterChange(values) {
    setSelectedStatusKeys(values)

    updateSearchProps({
      filters: {
        status: Array.from(values),
      },
    })
  }

  function handleResetFilters() {
    setSelectedStatusKeys(new Set())

    updateSearchProps({
      filters: null,
      sorting: null,
    })
  }

  return (
    <Container>
      <Menu
        label="Broker Name"
        aria-label="broker name"
        onAction={updateSortParam('brokerName')}
      >
        {sortingOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </Menu>

      <Menu
        label="Assigned to"
        aria-label="application assigned to"
        onAction={updateSortParam('assignedTo')}
      >
        {sortingOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </Menu>

      <DropdownCheckbox
        label={isEmpty(filters?.status) ? 'Status' : 'Status'}
        aria-label="status"
        selectedKeys={selectedStatusKeys}
        onSelectionChange={handleStatusFilterChange}
      >
        {Object.entries(APPLICATION_STATUS_DISPLAY_TEXT).map(([key, value]) => (
          <Item key={key}>{value}</Item>
        ))}
      </DropdownCheckbox>

      <Button
        StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
        onClick={handleResetFilters}
      >
        Refresh
      </Button>
    </Container>
  )
}

export default FiltersBar
