import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { formatCurrency } from 'utils'
import { isNilOrEmpty } from '@solta/ramda-extra'

const Indicator = styled.div(s('flex flex-column items-center'))

const Label = styled.span(s('mb-3'))
const Value = styled.span(s('mt-3'))
const Column = styled.div(s('w-1 h-6 bg-grey'))
const Row = styled.div(
  s('h-1 bg-grey absolute', {
    top: '50%',
    transform: 'translateY(-50%)',
    left: 90,
    right: 90,
  })
)

function validatePatterns(patterns) {
  /**
   * Disclaimer: Currently we don't do the case where custom value < lower estimate or custom value > upper estimate.
   * As you can see in the components for lower / upper estimates, the corresponding components have hard coded translateX styling as
   * they are expected to be on the left most / right most of the range atm.
   */
  if (patterns[0]?.type && patterns[0]?.type !== 'lowerEstimate')
    throw new Error('The first item in the patterns must be lowerEstimate')

  if (
    patterns[patterns.length - 1]?.type &&
    patterns[patterns.length - 1]?.type !== 'upperEstimate'
  )
    throw new Error('The last item in the patterns must be upperEstimate')
}

// eslint-disable-next-line complexity
const getIndicatorPatterns = (
  customValue,
  lowerEstimate,
  upperEstimate,
  estimatedValue
) => {
  let patterns = [
    {
      type: 'lowerEstimate',
      value: undefined,
    },
    {
      type: 'upperEstimate',
      value: undefined,
    },
  ]

  if (isNilOrEmpty(customValue) || customValue === estimatedValue)
    patterns = [
      {
        type: 'lowerEstimate',
        value: lowerEstimate,
      },
      {
        type: 'estimatedValue',
        value: estimatedValue,
        valueStyles: s('text-secondary'),
      },
      {
        type: 'upperEstimate',
        value: upperEstimate,
      },
    ]
  else if (customValue > estimatedValue)
    patterns = [
      {
        type: 'lowerEstimate',
        value: lowerEstimate,
      },
      {
        type: 'estimatedValue',
        value: estimatedValue,
      },
      {
        type: 'customValue',
        value: customValue,
      },
      {
        type: 'upperEstimate',
        value: upperEstimate,
      },
    ]
  else if (customValue < estimatedValue)
    patterns = [
      {
        type: 'lowerEstimate',
        value: lowerEstimate,
      },
      {
        type: 'customValue',
        value: customValue,
      },
      {
        type: 'estimatedValue',
        value: estimatedValue,
      },
      {
        type: 'upperEstimate',
        value: upperEstimate,
      },
    ]

  validatePatterns(patterns)

  return patterns
}

const EstimateRange = ({ customValue, valuations }) => {
  const indicatorPatterns = getIndicatorPatterns(
    customValue?.value,
    valuations?.estimatedLowerRange,
    valuations?.estimatedUpperRange,
    valuations?.estimatedValue
  )

  return (
    <ContentCard style={s('relative flex justify-between px-24')}>
      <Row />

      {indicatorPatterns.map((p) => {
        switch (p.type) {
          case 'lowerEstimate':
            return (
              <Indicator style={{ transform: 'translateX(-50%)' }}>
                <Label>Lower Estimate</Label>

                <Column />

                <Value>{formatCurrency(p.value, 0, '$-')}</Value>
              </Indicator>
            )

          case 'customValue':
            return (
              <Indicator>
                <Label>Final Asset Value</Label>

                <Column />

                <Value style={s('text-secondary')}>
                  {formatCurrency(p.value, 0, '$-')}
                </Value>
              </Indicator>
            )

          case 'estimatedValue':
            return (
              <Indicator>
                <Label>iVal Estimate</Label>

                <Column />

                <Value style={p?.valueStyles}>{formatCurrency(p.value, 0, '$-')}</Value>
              </Indicator>
            )

          case 'upperEstimate':
            return (
              <Indicator style={{ transform: 'translateX(50%)' }}>
                <Label>Upper Estimate</Label>

                <Column />

                <Value>{formatCurrency(p.value, 0, '$-')}</Value>
              </Indicator>
            )

          default:
            return null
        }
      })}
      {/* </div> */}
    </ContentCard>
  )
}

export { EstimateRange }
