import { useEffect, useRef } from 'react'
import { and, isNotNil, noop } from '@solta/ramda-extra'

export function useInterval(callback = noop, shouldStartTimer = true, delay = 5000) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (and(isNotNil(delay), shouldStartTimer)) {
      const intervalId = setInterval(tick, delay)
      return () => {
        clearInterval(intervalId)
      }
    }
    return 0
  }, [shouldStartTimer, delay])
}
