import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useInterval } from 'hooks/useInterval'
import { retrieveFinancialsData } from 'modules/affordabilityAssessment'
import { fetchLoanApplicationEntities } from 'modules/application'
import { listSupportingDocuments } from 'modules/supportingDocument'
import { append, reject } from '@solta/ramda-extra'

const ILLION_POLLING_INTERVAL_MS = 20000

const useCompletedRetrievalDetection = (
  numOfBorrowersStartedRetrieval,
  loanApplicationId
) => {
  const dispatch = useDispatch()

  const [
    prevNumOfBorrowersStartedRetrieval,
    setPrevNumOfBorrowersStartedRetrieval,
  ] = useState()
  const [
    detectedCompletionSinceLastInterval,
    setDetectedCompletionSinceLastInterval,
  ] = useState(false)

  // Detect retrieval status changes in interval
  useInterval(
    () => {
      if (prevNumOfBorrowersStartedRetrieval > numOfBorrowersStartedRetrieval)
        setDetectedCompletionSinceLastInterval(true)

      setPrevNumOfBorrowersStartedRetrieval(numOfBorrowersStartedRetrieval)
      dispatch(fetchLoanApplicationEntities(loanApplicationId))
    },
    numOfBorrowersStartedRetrieval > 0,
    ILLION_POLLING_INTERVAL_MS
  )

  // Fetch bank statements when retrieval is detected to be completed
  useEffect(() => {
    function fetchDocumentsOnRetrievalCompleted() {
      if (detectedCompletionSinceLastInterval) {
        dispatch(listSupportingDocuments(loanApplicationId))
        setDetectedCompletionSinceLastInterval(false)
      }
    }

    fetchDocumentsOnRetrievalCompleted()
  }, [
    detectedCompletionSinceLastInterval,
    dispatch,
    loanApplicationId,
    numOfBorrowersStartedRetrieval,
    prevNumOfBorrowersStartedRetrieval,
  ])
}

const getIllionRetrievalDetails = (borrowersCompletedAssessment = []) => {
  function getBankStatementRetrievalStatus(dataRetrieval) {
    return dataRetrieval?.find(
      (retrieval) => retrieval?.type === 'bankStatementRetrieval'
    )?.status
  }

  const entityIdToRetrievalStatus = {}
  let numOfBorrowersStartedRetrieval = 0

  for (const borrower of borrowersCompletedAssessment) {
    const {
      entityId,
      affordabilityAssessment: { dataRetrieval = [] },
    } = borrower

    const bankStatementRetrievalStatus = getBankStatementRetrievalStatus(dataRetrieval)
    entityIdToRetrievalStatus[entityId] = bankStatementRetrievalStatus
    if (bankStatementRetrievalStatus === 'started') numOfBorrowersStartedRetrieval += 1
  }

  return {
    entityIdToRetrievalStatus,
    numOfBorrowersStartedRetrieval,
  }
}

export function useIllionDataRetrieval(
  loanApplicationId,
  borrowersCompletedAssessment
) {
  const dispatch = useDispatch()
  const [entitiesLinkingIllion, setEntitiesLinkingIllion] = useState([])

  const sendIllionLink = useCallback(
    async (legalEntityId) => {
      setEntitiesLinkingIllion(append(legalEntityId))
      await dispatch(retrieveFinancialsData({ loanApplicationId, legalEntityId }))
      await dispatch(fetchLoanApplicationEntities(loanApplicationId))
      setEntitiesLinkingIllion(reject((entityId) => entityId === legalEntityId))
    },
    [dispatch, loanApplicationId]
  )

  const {
    entityIdToRetrievalStatus = {},
    numOfBorrowersStartedRetrieval,
  } = getIllionRetrievalDetails(borrowersCompletedAssessment)

  useCompletedRetrievalDetection(numOfBorrowersStartedRetrieval, loanApplicationId)

  return { sendIllionLink, entitiesLinkingIllion, entityIdToRetrievalStatus }
}
