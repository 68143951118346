import React, { useState } from 'react'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'
import { ReactComponent as LinkIcon } from '@horizon/components/src/assets/images/link.svg'
import { formatDateString } from 'utils/date'
import { DataDisplay } from '../components'
import { CreditCheckFullDetails } from './CreditCheckFullDetails'

const Container = styled.div(s('bg-primary-flat-white px-3 py-5 rounded-md'))
const ViewDetailsContainer = styled.div(
  s('flex items-center mt-4', { cursor: 'pointer' })
)
const ViewDetailsText = styled.p(
  s('font-semibold m-0 text-primary text-sm', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

export const CreditCheckResult = ({ creditReport = {}, ...props }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const {
    creditEnquiries,
    creditScore: { riskOdds = '-' },
  } = creditReport

  const date = formatDateString(new Date(creditEnquiries[0]?.createDate))

  const openModal = () => setIsModalOpened(true)
  const closeModal = () => setIsModalOpened(false)

  return (
    <>
      <Container {...props}>
        <Row>
          <Col span={12}>
            <DataDisplay label="Date" value={date} />
          </Col>

          <Col span={12}>
            <DataDisplay label="Credit Score" value={riskOdds} />
          </Col>
        </Row>

        <ViewDetailsContainer onClick={openModal}>
          <ViewDetailsText>View Full Details</ViewDetailsText>
          <LinkIcon style={s('ml-1')} />
        </ViewDetailsContainer>
      </Container>

      <CreditCheckFullDetails
        closeModal={closeModal}
        creditReport={creditReport}
        isOpen={isModalOpened}
      />
    </>
  )
}
