import React, { useEffect } from 'react'
import { Row, Col } from '@horizon/styled'
import { s } from '@horizon/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl'

import {
  fetchComparableSales,
  selectAllCoordinates,
  clearComparableSalesLocations,
  selectIsFetchingFeatures,
} from 'modules/comparableSale'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as MapMarker } from '@horizon/components/src/assets/images/map-marker.svg'
import { SaleInformation } from './SaleInformation'
import { Loading } from '@horizon/components'

const ComparableSales = ({ properties }) => {
  const dispatch = useDispatch()
  const locations = useSelector(selectAllCoordinates)

  const isFetchingCoordinates = useSelector(selectIsFetchingFeatures)

  const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  })

  useEffect(() => {
    if (isNotNilOrEmpty(properties)) {
      const addresses = properties.map(
        (p) =>
          `${p?.address?.streetNumber} ${p?.address?.streetName} ${p?.address?.streetType} ${p?.address?.suburbName}`
      )
      addresses.forEach((address) => {
        dispatch(fetchComparableSales(address))
      })
    }

    return () => {
      dispatch(clearComparableSalesLocations())
    }
  }, [dispatch, properties])

  if (isFetchingCoordinates)
    return (
      <ContentCard>
        <Loading />
      </ContentCard>
    )

  return (
    <ContentCard>
      <Row>
        <Col span={24}>
          <Map
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: 500,
              width: '100%',
              zIndex: 1,
            }}
            zoom={[14]}
            center={locations[0]?.coordinates}
          >
            {locations?.map((location, index) => {
              return (
                <Marker
                  key={index}
                  style={{
                    width: '7%',
                    height: '7%',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    zIndex: 1,
                  }}
                  coordinates={location?.coordinates}
                >
                  <div style={s('relative')}>
                    <MapMarker style={s('m-0')} />
                    <h2
                      style={s('absolute m-0 text-sm', {
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                      })}
                    >
                      {location?.address}
                    </h2>
                  </div>
                </Marker>
              )
            })}
          </Map>
        </Col>
      </Row>
      <Row>
        {properties?.map((property, index) => (
          <Col span={24} key={index}>
            <SaleInformation comparableSale={property} />
          </Col>
        ))}
      </Row>
    </ContentCard>
  )
}

export { ComparableSales }
