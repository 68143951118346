export const mapToAdverseChanges = (individuals = []) => {
  const mappedIndividuals = individuals.map((individual) => {
    const {
      anticipatedChanges,
      mitigants,
      significantChanges,
    } = individual.responsibleLending

    const changes = significantChanges.map((change, index) => {
      const { description, startDate, endDate } = change
      const { description: notes, factor } = mitigants[index]

      return {
        impactEndDate: new Date(endDate),
        impactStartDate: new Date(startDate),
        mitigant: { factor, notes },
        natureOfChange: description,
      }
    })

    return {
      personName: individual.personName,
      responsibleLending: { anticipatedChanges, changes },
    }
  })

  return mappedIndividuals
}
