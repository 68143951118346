import React from 'react'
import { s, styled } from '@horizon/styled/v2'
import { Col, Row } from '@horizon/styled'

const Label = styled.p(
  s('font-normal m-0 text-base text-grey-700', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Value = styled.p(
  s('font-normal m-0 text-base text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

export const CreditCheckDataDisplay = ({
  label = '-',
  value = '-',
  valueStyles,
  ...props
}) => {
  return (
    <Row {...props}>
      <Col span={8}>
        <Label>{label}</Label>
      </Col>
      <Col span={16}>
        <Value style={valueStyles}>{value}</Value>
      </Col>
    </Row>
  )
}
